
import axios from 'axios'
import dotenv from 'dotenv'
dotenv.config();

const apiInstance = axios.create({
    baseURL: `${window.location.protocol}//${process.env.REACT_APP_BASE_URL}`
    // headers: { 'authorization': `Bearer ${sessionStorage.getItem('assistance_token')}` || '' }
});

apiInstance.interceptors.request.use(
    (config) => {
        const token = `Bearer ${sessionStorage.getItem('assistance_token')}` || '';
        if (token) {
            config.headers.authorization = token;
        }
        return config;
    }, (error) => Promise.reject(error)
);

export default apiInstance;