import React from 'react';
import { Link } from 'react-router-dom';

function Footer({ type=null }) {
    return (
        type === 'small' ? (
            <div className={"w-full flex flex-col items-start mt-4 border-t border-gray-400 pt-4"}>                    
                <div className="w-full flex items-start">
                    <Link to="/terms" className="w-full hover:text-gray-700 underline text-gray-600 py-1 text-sm">
                        Terms and Conditions
                    </Link>                                                                
                    <Link to="/contact" className="w-full hover:text-gray-700 underline text-gray-600 py-1 text-sm">
                        Contact Us
                    </Link>                                
                </div>
                <div className="w-full flex items-start">
                    <p 
                        onClick={() => window.location.replace('/about#about')}
                        className="w-full cursor-pointer hover:text-gray-700 underline text-gray-600 py-1 text-sm">
                        About Us
                    </p>                
                    <p 
                        onClick={() => window.location.replace('/about#mission')}
                        className="w-full cursor-pointer hover:text-gray-700 underline text-gray-600 py-1 text-sm">
                        Our Mission
                    </p>
                </div>
                {/* <div className="w-full flex items-start">
                    <Link 
                        to="/submit-organization"
                        className="cursor-pointer hover:text-gray-700 underline text-gray-600 py-1 text-sm">
                        Submit Organization
                    </Link>                 
                </div> */}
            </div>
        ) : (
            <div className={"w-full flex lg:flex-row flex-col items-center mt-4 border-t border-gray-400 pt-4"}>                    
                <Link to="/terms" className="hover:text-gray-700 underline text-gray-600 py-1 text-sm mx-4">
                    Terms and Conditions
                </Link>                                                                
                <Link to="/contact" className="hover:text-gray-700 underline text-gray-600 py-1 text-sm mx-4">
                    Contact Us
                </Link>                                
                <p 
                    onClick={() => window.location.replace('/about#about')}
                    className="cursor-pointer hover:text-gray-700 underline text-gray-600 py-1 text-sm mx-4">
                    About Us
                </p>                
                <p 
                    onClick={() => window.location.replace('/about#mission')}
                    className="cursor-pointer hover:text-gray-700 underline text-gray-600 py-1 text-sm mx-4">
                    Our Mission
                </p>    
                {/* <Link 
                    to="/submit-organization"
                    className="cursor-pointer hover:text-gray-700 underline text-gray-600 py-1 text-sm mx-4">
                    Submit Organization
                </Link>                             */}
            </div>
        )
    )
}

export default Footer
