import React,{ useState } from 'react'
import CrudUI from './CrudUI'
import apiInstance from '../../../api'
import { useDataLayerValue } from '../../../DataLayer'
import { message } from 'antd'

function Keywords() {

    const [{ allKeywords, allKeywordsStatus}, dispatch] = useDataLayerValue();

    const [load, setLoad] = useState(false);
    const [addModalTrigger, setAddModalTrigger] = useState(false);
    const [updateModalTrigger, setUpdateModalTrigger] = useState(false);
    const [currentItem, setCurrentItem] = useState(null); 

    /**
     * Used to create a keyword using the 
     * standard API
     * @param {*} data 
     */
    const createAKeyword = (data) => {        
        setLoad(true);
        apiInstance.post("/keyword", data)
        .then(response => {
            if(response.data.status) {
                setLoad(false);
                message.success(response.data.data);
                setAddModalTrigger(false);
                setCurrentItem(null);
                // UPDATE THE DATA LAYER WITH NEW KEYWORDS ADDED
                dispatch({
                    type: 'SET_ALL_KEYWORDS_STATUS',
                    status: !allKeywordsStatus
                });
            } else message.warning(response.data?.data || 'something went wrong');
            setLoad(false);          
        })
        .catch(e => {
            setLoad(false);
            console.log(e);
            if(e.response || e.response?.data) {                                    
                if(e.response.status === 401) {
                    alert('Your Session is expired!');
                    sessionStorage.clear();                            
                    window.location.replace("/signin");                                     
                } else {
                    if(typeof e.response?.data?.message === 'string') {
                        message.error(e.response.data.message);                      
                    } else {
                        message.warn("Something went wrong");
                    }                        
                }
            } else message.error("Internal Server Error"); 
        });        
    }

    /**
     * Used to update a keyword using the
     * standard API
     * @param {id} id
     * @param {*} data 
     */
    const updateAKeyword = (id, data) => {
        setLoad(true);
        apiInstance.put(`/keyword/${id}`, data)
        .then(response => {
            if(response.data.status) {
                setLoad(false);
                message.success(response.data.data);
                setUpdateModalTrigger(false);
                setCurrentItem(null);
                // UPDATE THE DATA LAYER WITH NEW KEYWORDS ADDED
                dispatch({
                    type: 'SET_ALL_KEYWORDS_STATUS',
                    status: !allKeywordsStatus                    
                });
            } else message.warning(response.data?.data || 'something went wrong');
            setLoad(false);            
        })
        .catch(e => {
            setLoad(false);
            console.log(e);
            if(e.response || e.response?.data) {                                    
                if(e.response.status === 401) {
                    alert('Your Session is expired!');
                    sessionStorage.clear();                            
                    window.location.replace("/signin");                                     
                } else {
                    if(typeof e.response?.data?.message === 'string') {
                        message.error(e.response.data.message);                       
                    } else {
                        message.warn("Something went wrong");
                    }                        
                }
            } else message.error("Internal Server Error"); 
        });        
    }

    /**
     * Used to soft delete a keyword using the
     * standard API
     * @param {*} id 
     */
    const deleteAKeyword = (id) => {        
        setLoad(true);
        apiInstance.put(`/keyword/delete/${id}`)
        .then(response => {
            if(response.data.status) {
                message.success(response.data.data);
                setLoad(false);                
                setCurrentItem(null);
                // UPDATE THE DATA LAYER WITH NEW KEYWORDS ADDED
                dispatch({
                    type: 'SET_ALL_KEYWORDS_STATUS',
                    status: !allKeywordsStatus                    
                });            
                return true;    
            } else message.warning(response.data?.data || 'something went wrong');
            setLoad(false);
        })
        .catch(e => {            
            setLoad(false);
            console.log(e);
            if(e.response || e.response?.data) {                                    
                if(e.response.status === 401) {
                    alert('Your Session is expired!');
                    sessionStorage.clear();                            
                    window.location.replace("/signin");                                     
                } else {
                    if(typeof e.response?.data?.message === 'string') {
                        message.error(e.response.data.message);                      
                    } else {
                        message.warn("Something went wrong");
                    }                        
                }
            } else message.error("Internal Server Error");  
            return false;
        });        
    }

    /**
     * Used to change the current status of
     * a keyword based on provided id
     * @param {*} id 
     */
    const changeStatusOfAKeyword = (data, id) => {        
        setLoad(true);
        apiInstance.put(`/keyword/status/${id}`, data)
        .then(response => {
            if(response.data.status) {
                setLoad(false);
                message.success(response.data.data);                
                setCurrentItem(null);
                // UPDATE THE DATA LAYER WITH NEW KEYWORDS ADDED
                dispatch({
                    type: 'SET_ALL_KEYWORDS_STATUS',
                    status: !allKeywordsStatus                    
                });
            } else message.warning(response.data?.data || 'something went wrong');
            setLoad(false);          
        })
        .catch(e => {
            setLoad(false);
            console.log(e);
            if(e.response || e.response?.data) {                                    
                if(e.response.status === 401) {
                    alert('Your Session is expired!');
                    sessionStorage.clear();                            
                    window.location.replace("/signin");                                     
                } else {
                    if(typeof e.response?.data?.message === 'string') {
                        message.error(e.response.data.message);                         
                    } else {
                        message.warn("Something went wrong");
                    }                        
                }
            } else message.error("Internal Server Error");  
        });        
    }

    return (
        <div className="w-full">
            <CrudUI
                title="Keyword"
                utility="Keywords"
                data={allKeywords}
                addModalTrigger={addModalTrigger}
                updateModalTrigger={updateModalTrigger} 
                load={load}                 
                setAddModalTrigger={setAddModalTrigger}
                setUpdateModalTrigger={setUpdateModalTrigger}
                createItem={createAKeyword}
                updateItem={updateAKeyword}
                deleteItem={deleteAKeyword}
                changeStatus={changeStatusOfAKeyword}
                currentItem={currentItem}
                setCurrentItem={setCurrentItem}
            />
        </div>
    )
}

export default Keywords
