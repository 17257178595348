import React, { useEffect, useState, useRef, useCallback } from 'react'
import Header from '../components/User/Header'
import { useParams, useHistory, Link } from 'react-router-dom'
import { Result, message, Typography, Skeleton, Comment, Tooltip, Popconfirm, Tag, Modal, Input, Spin } from 'antd'
import { SearchOutlined, FacebookFilled, WhatsAppOutlined, TwitterOutlined } from '@ant-design/icons'
import { useDataLayerValue } from '../DataLayer'
import apiInstance from '../api'
import FeedBox from '../components/User/FeedBox'
import { validateURL, checkAvailability, getProfileImage } from '../utils/Helper'
import CategoryHeader from '../components/User/CategoryHeader'
import CategorySlide from '../components/User/CategorySlide'
import SearchBar from '../components/User/SearchBar'
import Footer from '../components/User/Footer'
import PreLoaderList from '../components/User/PreLoaderList'
import TimeMe from 'timeme.js'
import assistanceIcon from '../assets/img/icon.png'
import EditorJSRenderer from '../components/User/EditorJSRenderer'
import moment from 'moment'
import ReplyComment from '../components/User/ReplyComment'

const { Paragraph } = Typography;

// logic for adding new comment in multi level
// search for the children in their own children array and add the comment editor to top of array
// and then after adding comment remove the top element of own children array and insert the new comment to it

function Feed() {
    const commentLimit = 500;
    let { feedkey } = useParams();
    let history = useHistory();

    // const loadIcon = <LoadingOutlined style={{ fontSize: 24, color: 'blue' }} spin />;

    const [{ categories, user, token }] = useDataLayerValue();

    const [selectedFeed, setSelectedFeed] = useState(null);    
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [rssFeed, setRssFeed] = useState(null);    
    const [mainImage, setMainImage] = useState(null);    
    const [searchText, setSearchText] = useState('');
    const [filtered, setFiltered] = useState([]);    
    const [found, setFound] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [posts, setPosts] = useState([]);
    const [feedComments, setFeedComments] = useState([]);
    const [userComment, setUserComment] = useState('');            
    const [commentLoad, setCommentLoad] = useState(false);    
    const [commentReplyLoad, setCommentReplyLoad] = useState(false); 
    const [commentInputModal, setCommentInputModal] = useState(false);
    const [currentLevel1Key, setCurrentLevel1Key] = useState(null);
    const [level1Reply, setLevel1Reply] = useState('');    
    const [level2RepliesModal, setLevel2RepliesModal] = useState(false);
    const [level2Replies, setLevel2Replies] = useState([]);

    const openCommentInputModal = (data) => {
        setCurrentLevel1Key(data);
        setCommentInputModal(true);
    }

    const handleOkInputModal = () => {
        if (level1Reply) {
            addCommentReply(currentLevel1Key?.id, 2, level1Reply);
        } else {
            message.warn('Reply cannot be empty');
        }
    }

    const handleCancelInputModal = () => {
        setLevel1Reply('');
        setCurrentLevel1Key(null);
        setCommentInputModal(false);
    }
    
    async function getFeedComments() {        
        apiInstance.get(`/feedcomments/${selectedFeed?.id}`)
                .then(comments => {
                    comments.data.data.reverse();                     
                    setFeedComments(constructFeedComments(comments.data.data, null))
                }).catch(e => {                    
                    if (e.response.status === 404) {
                        setFeedComments([]);                        
                    }
                });
    }

    useEffect(() => {
        if (selectedFeed?.feed_type === 'Internal Blog') {
            getFeedComments();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps         
    },[selectedFeed]);

    useEffect(() => {        
        async function getFeedByKey() {
            apiInstance.get(`/feed/key/${feedkey}`)
            .then(response => {
              if(response.data.status) {                              
                const newSelectedFeed = response.data.data;                    
                document.title = `${newSelectedFeed?.title} - Assistance`;
                if (checkAvailability(newSelectedFeed?.active_from, newSelectedFeed?.active_to)) {
                    const newSelectedCategory = categories.filter(c => c.id === newSelectedFeed?.category_id)[0];                    
                    setSelectedCategory(newSelectedCategory);
                } else {
                    setFound(true);
                    message.warn("Feed not available");
                }
                if(newSelectedFeed) {
                    if(parseInt(newSelectedFeed?.is_active||0) === 1) {
                        setSelectedFeed(newSelectedFeed);                                                   
                    } else {   
                        setFound(true);
                        message.warning('Feed is not available right now!');
                    }
                }

              } else {
                // window.location.replace('/fallback');
                console.log(response.data?.data||response?.status);            
              }
            })
            .catch(e => {
              console.log(e);
              if(e.response || e.response?.data) {                          
                  // message.error(e.response.data.message);
                  if(e.response.status === 401) {
                      sessionStorage.clear();
                      window.location.replace("/admin");
                  }
              } else {
                // history.push('/');
              }
            });  
          }        
          getFeedByKey();          
        // eslint-disable-next-line react-hooks/exhaustive-deps         
    },[feedkey, categories]);

    useEffect(() => {        
        TimeMe.initialize({
            currentPageName: 'feed-page'            
        });
        if(selectedFeed && selectedFeed?.feed_type === 'RSS Feed') {            
            getRSSFeed(selectedFeed.url);   
            isImageOk(selectedFeed.image);                                           
        } else if(selectedFeed?.feed_type === 'External Blog') {
            window.location.replace(selectedFeed?.url||'/');
        }        
// eslint-disable-next-line react-hooks/exhaustive-deps 
    },[selectedFeed, pageNumber]);

    
    async function getRSSFeed(url) {                
        setLoading(true);
        apiInstance.get(`/homepage/rss?page=${pageNumber}&url=${url}`)
        .then(response => {
            setLoading(false);
            if (rssFeed?.title !== response.data?.title) {
                setRssFeed(response.data); 
            }
            setPosts(prevPosts => {
                return [...prevPosts, ...response.data.items]
            });            
            setHasMore(response.data.items?.length > 0)                   
        }).catch(e => {
            setLoading(false);
            console.log(e);
            history.push('/');            
        })        
    }

    const observer = useRef();
    const lastPostElementRef = useCallback(node => {
        if (loading) return
        if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver(entries => {
                if (entries[0].isIntersecting && hasMore)  {
                    setPageNumber(prevPageNumber => prevPageNumber + 1);
                }
            })
        if (node) observer.current.observe(node);
        console.log(node);
    }, [loading, hasMore]);
    
    async function isImageOk(imageUrl) {
        if(validateURL(imageUrl) && imageUrl.indexOf('h') === 0) {            
            let img = new Image();
            img.src = imageUrl;
            img.onload = function() { setMainImage(imageUrl) }
            img.onerror = function() { setMainImage(null) }
        } else {
            setMainImage(null);
        }
    }

    async function getCommentReplies(parentId, level=1, allComments=null)  {
        apiInstance.get(`/feedcomments/child/${parentId}/feed/${selectedFeed?.id}`)
            .then(comments => {                
                comments.data.data.reverse();                                
                if(allComments) {
                    addCommentReplies(parentId, comments.data.data, allComments);
                }                
            }).catch(e => console.log(e));
    }

    const handleRepliesView = (parentId, level=1, comments) => {
        getCommentReplies(parentId, level=1, comments);
    }

    useEffect(() => {
        var links = document.getElementsByTagName('a');
        for (var i=0; i<links.length; i++) {
            links[i].target = '_blank';            
        }
    },[rssFeed, selectedFeed]);
    
    const filterFeed = (text) => {
        setSearchText(text);
        if(text) {            
            let filteredData = rssFeed.items.filter(feed => {
                return (
                    feed.title.toLowerCase().includes(text.toLowerCase()) ||
                    feed?.content.toLowerCase().includes(text.toLowerCase()) ||
                    feed?.categories?.map(i => i.toLowerCase())?.includes(text.toLowerCase()) ||
                    feed?.categories['_']?.includes(text.toLowerCase()) ||
                    feed?.categories?.map(i => i?.toLowerCase()).some(item => item.includes(text.toLowerCase()) && text !== '')
                )
            });
            setFiltered(filteredData);            
        } else {
            setFiltered([]);
        }
    }

    const logCategory = () => {
        if(TimeMe.getTimeOnCurrentPageInSeconds() > 60) {            
            let category = {
                id: selectedFeed?.category_id || null
            };
            let categoryLog = JSON.parse(localStorage.getItem('assistance-category-log'));
            if(categoryLog?.length) {
                if(!categoryLog.filter(c => c.id === selectedCategory?.id).length) {
                    localStorage.setItem('assistance-category-log', JSON.stringify([...categoryLog, category]));
                }
            } else localStorage.setItem('assistance-category-log', JSON.stringify([category]));
            TimeMe.resetRecordedPageTime('feed-page');  
        }
    }
    
    useEffect(() => {
        document.onmousemove = logCategory; 
        document.onkeypress = logCategory;
        document.onblur = logCategory; 
        // eslint-disable-next-line react-hooks/exhaustive-deps      
    },[selectedFeed]);    

    const deleteMyCommentById = (comment, level, data) => {        
        if (comment?.id) {
            apiInstance.delete(`/feedcomments/${comment.id}?level=${level}&parent=${comment?.parent_id}`)
                    .then(response => {
                        message.success(response.data?.data || 'Comment Deleted');
                        setLevel2RepliesModal(false);
                        getFeedComments();
                    }).catch(e => {
                        console.log(e);
                        if(e.response || e.response?.data) {                                    
                            if(e.response.status === 401) {                                
                                    sessionStorage.clear();
                                    message.error(typeof e.response.data.message === 'string' ? e?.response?.data?.message : 'Something went wrong');
                                    window.location.reload();
                                }                                                
                            }                        
                    });
        } else message.warning("Invalid Operation");
    }

    const softDeleteCommentById = (status, id) => {
        if (id) {
            apiInstance.put(`/feedcomments/status/${id}`, { status: status })
                    .then(response => {
                        message.success(response.data?.data || 'Comment Modified');
                        setLevel2RepliesModal(false);
                        getFeedComments();
                    }).catch(e => {
                        console.log(e);
                        if(e.response || e.response?.data) {                                    
                            if(e.response.status === 401) {                                
                                    sessionStorage.clear();
                                    message.error(typeof e.response.data.message === 'string' ? e?.response?.data?.message : 'Something went wrong');
                                    window.location.reload();
                                }                                                
                            }                        
                    });
        } else message.warning("Invalid Operation");
    }

    const handleRepliesViewModal = (id) => {        
        async function getLevel2Replies(parentId, level=2)  {
            setLevel2Replies([]);
            apiInstance.get(`/feedcomments/child/${parentId}/feed/${selectedFeed?.id}`)
                .then(comments => {                
                    comments.data.data.reverse();                                
                    setLevel2Replies(constructFeedComments(comments.data.data));
                    setLevel2RepliesModal(true);
                }).catch(e => console.log(e));
        }
        getLevel2Replies(id);
    }

    const constructFeedComments = (data) => {                
        let result = [];
        for (let key of data) {
            if (key?.is_active === 1) {                
                let tempData = {};
                tempData['parentId'] = key.parent_id;
                tempData['key'] = key.id;
                tempData['level'] = key?.level;
                tempData['id'] = key?.id;  
                tempData['replies'] = key?.replies;
                let children = [];                                   
                if (key.replies > 0 && key.level === 0) {                    
                    let childrenTag = <Tag key={key.id+'tag'} onClick={() => handleRepliesView(key?.id, 1, data)} color="grey">View {key?.replies||'0'} {key.replies>1?'replies':'reply'}</Tag>;
                    children.push(childrenTag);
                    tempData['replyChildren'] = children;
                } else if (key.replies > 0 && key.level === 1) {                    
                    let childrenTag = <Tag key={key.id+'tag'} onClick={() => handleRepliesViewModal(key?.id)} color="grey">View {key?.replies||'0'} {key.replies>1?'replies':'reply'}</Tag>;
                    children.push(childrenTag);
                    tempData['replyChildren'] = children;
                } else tempData['replyChildren'] = null;  
                if (!tempData['children']) {
                    tempData['children'] = key.children;
                }
                if (user?.id === key?.created_by || user?.role === 'Super User') {
                    tempData['actions'] = [
                        !!user && (key.level===0) && (
                            <span onClick={() => addTempCommentInsertionArea(key, data, key.level) } key="comment-list-reply-to-0">Reply</span>
                        ),
                        !!user && (key.level===1) && (
                            <span onClick={() => openCommentInputModal(key) } key="comment-list-reply-to-0">Reply</span>
                        ),
                        (user?.id === key.created_by) &&                 
                            (
                            <Popconfirm
                                onConfirm={() => deleteMyCommentById(key, key.level, feedComments)}
                                title="Are you sure?"                
                                okText={"Yes"}                                        
                                cancelText={"No"}
                            ><span >delete</span>             
                            </Popconfirm>
                            ),
                        (user?.role === 'Super User') &&                
                            (
                            <Popconfirm
                                onCancel={() => softDeleteCommentById(false, key.id)}
                                onConfirm={() => softDeleteCommentById(true ,key.id)}
                                title="Modify Status"                
                                okText={"Activate"}                                        
                                cancelText={"Block"}
                            ><span >modify</span>             
                            </Popconfirm> 
                            )                   
                    ];
                } else if (key.level===0 || key.level===1){
                    tempData['actions'] = [
                        !!user && (key.level===0) && (
                            <span onClick={() => addTempCommentInsertionArea(key, data, key.level) } key="comment-list-reply-to-0">Reply</span>
                        ),
                        !!user && (key.level===1) && (
                            <span onClick={() => openCommentInputModal(key) } key="comment-list-reply-to-0">Reply</span>
                        )];
                } else {
                    tempData['actions'] = null;
                }
                tempData['author']  = <p className="cursor-pointer" onClick={() => parseInt(key?.is_person_active)===1?history.push(`/@${key?.username}`):''}>{user?.role==='Super User'?parseInt(key?.is_person_active)===1 ? key?.username || 'Assistance User' : `${key?.username} (Deactivated User)`:parseInt(key?.is_person_active)===1 ? key?.username || 'Assistance User' : 'Deactivated User'}</p>;
                tempData['avatar'] = parseInt(key?.is_person_active)===1 ? key?.image || getProfileImage(key?.username||'Assistance') : getProfileImage('Assistance');
                tempData['content'] = (
                    <p>{key?.content||''}</p>
                )
                tempData['datetime'] = (
                    <Tooltip title={moment(key?.modified_at).format('YYYY-MM-DD HH:mm:ss')}>
                    <span>{moment(key?.modified_at).fromNow()}</span>
                    </Tooltip>
                );                                
                result.push(tempData);
            } else {
                if (key?.created_by === user?.id || user?.role === 'Super User') {
                    let tempData = {};
                    tempData['key'] = key.id;
                    tempData['level'] = key?.level;
                    tempData['id'] = key?.id;
                    tempData['replies'] = key?.replies;
                    let children = [];                   
                    if (key.replies > 0 && key.level === 0) {
                        let childrenTag = <Tag key={key.id+'tag'} onClick={() => handleRepliesView(key?.id, 1, data)} color="grey">View {key?.replies||'0'} {key?.replies>1?'replies':'reply'}</Tag>;
                        children.push(childrenTag);
                        tempData['replyChildren'] = children;
                    } else tempData['replyChildren'] = null;
                    if (key.replies > 0 && key.level === 1) {                    
                        let childrenTag = <Tag key={key.id+'tag'} onClick={() => handleRepliesViewModal(key?.id)} color="grey">View {key?.replies||'0'} {key?.replies>1?'replies':'reply'}</Tag>;
                        children.push(childrenTag);
                        tempData['replyChildren'] = children;
                    } else tempData['replyChildren'] = null; 
                    if (user?.id === key?.created_by || user?.role === 'Super User') {
                        tempData['actions'] = [
                            // <span onClick={() => addTempCommentInsertionArea(key, data) } key="comment-list-reply-to-0">Reply</span>,
                            (user?.id === key.created_by) &&                
                               (
                                <Popconfirm
                                    onConfirm={() => deleteMyCommentById(key, key.level, feedComments)}
                                    title="Are you sure?"                
                                    okText={"Yes"}                                        
                                    cancelText={"No"}
                                ><span >delete</span>             
                                </Popconfirm>
                               ),
                            (user?.role === 'Super User') &&                
                               (
                                <Popconfirm
                                    onCancel={() => softDeleteCommentById(false, key.id)}
                                    onConfirm={() => softDeleteCommentById(true ,key.id)}
                                    title="Modify Status"                
                                    okText={"Activate"}                                        
                                    cancelText={"Block"}
                                ><span >modify</span>             
                                </Popconfirm>
                               )                    
                        ];
                    }            
                    tempData['author']  = <p className="cursor-pointer" onClick={() => history.push(`/@${key?.username}`)}>{parseInt(key?.is_person_active)===1 ? key?.username || 'Assistance User' : `${key?.username || ''} (Deactivated User)`}</p>;
                    tempData['avatar'] = key?.image || getProfileImage(key?.username||'Assistance');
                    tempData['content'] = (
                        <p><Tag color="red">Blocked By Admin</Tag> {key?.content||''}</p>
                    );
                    tempData['datetime'] = (
                        <Tooltip title={moment(key?.modified_at).format('YYYY-MM-DD HH:mm:ss')}>
                        <span>{moment(key?.modified_at).fromNow()}</span>
                        </Tooltip>
                    );
                    // tempData.children = null;
                    result.push(tempData);
                }                
            }            
        }
        return result;
    }

    const addComment = (parentId=null, level=0) => {        
    
        if (userComment?.trim()) {
        if (selectedFeed) {
            let payload = {
                content: userComment.trim(),
                feedId: selectedFeed?.id,
                parentId: parentId,
                level: level                 
            }
            // console.log(payload);
            setCommentLoad(true);
            apiInstance.post("/feedcomments", payload)
                .then(response => {
                    setCommentLoad(false);
                    message.success(response.data.data);
                    setUserComment('');
                    getFeedComments();
                }).catch(e => {
                    setCommentLoad(false);
                    console.log(e);
                    if(e.response || e.response?.data) {                                    
                        if(e.response.status === 401) {                    
                            message.warning('Session Expired');
                            sessionStorage.clear();                        
                            window.location.replace("/admin");                                                                 
                        } else message.warn(typeof e.response?.data?.message === 'string' ? e.response.data.message : 'Something went wrong');
                    } else message.error("Internal Server Error"); 
                });
        } else message.warning('No feed available');
        } else message.warning('Comment is empty');
    }

    const addCommentReply = (parentId, level, comment) => {
        if (comment?.trim()) {
            if (selectedFeed) {
                let payload = {
                    content: comment.trim(),
                    feedId: selectedFeed?.id,
                    parentId: parentId,
                    level: level                 
                }
                setCommentReplyLoad(true);
                apiInstance.post("/feedcomments", payload)
                    .then(response => {
                        setLevel1Reply('');
                        setCommentReplyLoad(false);
                        setCommentInputModal(false);
                        message.success(response.data.data);                                                
                        getFeedComments();
                    }).catch(e => {
                        setLevel1Reply('');
                        setCommentReplyLoad(false); 
                        setCommentInputModal(false);                       
                        console.log(e);
                        if(e.response || e.response?.data) {                                    
                            if(e.response.status === 401) {                    
                                message.warning('Session Expired');
                                sessionStorage.clear();                        
                                window.location.replace("/admin");                                                                 
                            } else message.warn(typeof e.response?.data?.message === 'string' ? e.response.data.message : 'Something went wrong');
                        } else message.error("Internal Server Error"); 
                    });
            } else message.warning('No feed available');
        } else {
            message.warning('No Reply Comment Found!');
        }
    }
    
    const FeedComment = ({ children, item }) => (
        <Comment    
            key={item.id}
            actions={item?.actions||null}
            author={item.author}
            avatar={item.avatar}
            content={item.content}
            datetime={item.datetime}
        >  
        {/* {
            children?.map(level1Item => (
                <Comment        
                    key={level1Item.id}
                    actions={level1Item.actions}
                    author={level1Item.author}
                    avatar={level1Item.avatar}
                    content={level1Item.content}
                    datetime={level1Item.datetime}                                                                
                ></Comment>
        } */}
        {/* {
                children?.map(level1Item => (
                    <Comment        
                        key={level1Item.id}
                        actions={level1Item.actions}
                        author={level1Item.author}
                        avatar={level1Item.avatar}
                        content={level1Item.content}
                        datetime={level1Item.datetime}                                                                
                    >
                        {
                            level1Item?.children.map(level2Item => (
                                <Comment        
                                    key={level2Item.id}
                                    actions={level2Item.actions}
                                    author={level2Item.author}
                                    avatar={level2Item.avatar}
                                    content={level2Item.content}
                                    datetime={level2Item.datetime}                                                                
                                >
                                    {
                                        level2Item?.children.map(level3Item => (
                                            <Comment        
                                                key={level3Item.id}
                                                actions={level3Item.actions}
                                                author={level3Item.author}
                                                avatar={level3Item.avatar}
                                                content={level3Item.content}
                                                datetime={level3Item.datetime}                                                                
                                            ></Comment>
                                        ))
                                    }           
                                </Comment>
                            ))
                        }                       
                    </Comment>
                ))
            }     */}
            {
                children
            }                         
        </Comment>
    )         

    const addTempCommentInsertionArea = (comment=null, data, level) => {                                           
        let comments = constructFeedComments(data);                      
            let cIdx = data.findIndex(c => c.id === comment.id);              
            if (comment && cIdx > -1) {                          
                    if (comments[cIdx]?.children) {                                  
                        (comments[cIdx].children).push(<Comment key={comment.id+data.length} avatar={user?.image} content={<ReplyComment level={level+1} parentId={comment.id} addComment={addCommentReply} load={commentReplyLoad} />}></Comment>);                                                
                    } else {
                        comments[cIdx].children=[];            
                        (comments[cIdx].children).push(<Comment key={comment.id+data.length} avatar={user?.image} content={<ReplyComment level={level+1} parentId={comment.id} addComment={addCommentReply} load={commentReplyLoad} />}></Comment>);                                                
                    }                                    
                setFeedComments(comments);
            }                
    }

    const addCommentReplies = (commentId, data, allComments) => {                         
        let cIdx = allComments.findIndex(c => c.id === commentId);
        allComments = constructFeedComments(allComments);        
        if (commentId && cIdx !== -1) {            
            allComments[cIdx].children = [];         
            data = constructFeedComments(data);
            // eslint-disable-next-line
            data.map(comment => {            
                allComments[cIdx].children.push(<FeedComment key={comment.id} children={comment.replyChildren} item={comment} />)
            })                       
            setFeedComments(allComments);                        
        }
    }

    const handleLevel2Cancel = () => {
        setLevel1Reply('');
        setLevel2RepliesModal(false);
        setLevel2Replies([]);
    }

    return (
        <div>             
            <Header />
            <CategoryHeader
                id={selectedCategory?.id}
                title={selectedCategory?.title}
                image={selectedCategory?.image||null}
            />
            {
                found ? (
                    <Result
                        status="warning"
                        title="Feed Not Found."
                        extra={
                            <Link to="/" key="console" className="primary-bg rounded-md text-white px-8 mx-2 py-2 hover:text-gray-400 my-2">
                                Back to Home
                            </Link>
                        }
                    />  
                ) : (
                <div className="px-6 lg:px-24 w-full flex flex-col items-start">
            
                    <div className="flex items-center w-full py-4">
                        {
                            selectedFeed?.feed_type === 'Internal Blog' ? (
                                <div className="w-full flex flex-col items-start">
                                    <h1 className="font-bold text-xl lg:text-3xl text-left py-1">
                                        {selectedFeed?.title}
                                    </h1>
                                    <div className="flex lg:flex-row flex-col items-start lg:items-center">
                                                <p className="text-gray-500 lg:text-md text-xs text-left">
                                                    {moment(selectedFeed?.modified_at).format('lll')}
                                                </p>
                                                <div className="mr-3 lg:mx-3 py-1 mb-2 flex items-center">
                                                    <a rel="noopener noreferrer" target="_blank" href={`https://www.facebook.com/sharer.php?u=${window.location.href}`} className="text-lg mr-2 lg:mx-2"><FacebookFilled /></a>
                                                    <a rel="noopener noreferrer" target="_blank" href={"http://twitter.com/share?&url="+window.location.href+"&hashtags=assistance,assistanceorg,helpingothersmatter"} className="text-lg mx-2"><TwitterOutlined /></a>                                                
                                                    <a rel="noopener noreferrer" href={"https://api.whatsapp.com://send?text=Hey there! look out here: "+window.location.href} data-action="share/whatsapp/share" className="text-lg lg:mx-2"><WhatsAppOutlined /></a>                              
                                                </div> 
                                    </div>                                
                                </div>
                            ) : (

                                !!selectedFeed ? (
                                    <div className="lg:w-1/2 flex lg:flex-row flex-col items-start">                                               
                                            <img 
                                                alt="feed"
                                                className="object-contain w-16 h-16 mx-4"                                
                                                src={mainImage||assistanceIcon}
                                            />                        
                                        <div className={mainImage ? 'flex flex-col items-start mx-4' : 'flex flex-col items-start'}>
                                            <a 
                                                rel="noopener noreferrer"
                                                target="_blank"
                                                href={rssFeed?.link}
                                                className="text-xl text-left font-bold primary-color"
                                            >{selectedFeed?.title||'N/A'}                             
                                            </a> 
                                            {
                                                !!selectedFeed?.description && (
                                                    <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: 'more' }} 
                                                        className="text-md text-gray-600 "
                                                    >{selectedFeed?.description||'N/A'}
                                                    </Paragraph>
                                                )
                                            }
                                        </div>                        
                                    </div> 
                                ): (
                                    <>
                                        <Skeleton loading={true} active className="border-b mb-4">
                                            <h4>Assistace.org Sample Title</h4>                                    
                                        </Skeleton>                            
                                    </>
                                )                                                                             
                            )
                        }                                       
                    </div>

                <div className="w-full flex lg:flex-row flex-col items-start">

                    <div className="lg:w-3/4 flex flex-col items-start">
                        {
                            selectedFeed?.feed_type !== 'Internal Blog' && (
                                <div 
                                    className="lg:w-1/2 flex items-center justify-between mb-3 rounded-lg shadow p-1 border">           
                                    <input
                                        placeholder="Search lastest posts..."
                                        className="w-full p-1 rounded-full bg-none primarycolor text-gray-700 mx-3 focus:outline-none"
                                        onChange={e => filterFeed(e.target.value)}
                                        value={searchText}
                                        type="text"
                                    />
                                    <SearchOutlined                     
                                        className="p-2 mr-2 text-lg cursor-pointer" 
                                    />
                                </div>   
                            )
                        }                    
                            {
                                selectedFeed ?

                                selectedFeed?.feed_type === 'Internal Blog' ? (

                                    <EditorJSRenderer
                                        data={JSON.parse(selectedFeed.content).blocks}  
                                        image={selectedFeed.image}                                  
                                    />

                                ) : 
                                    posts?
                                    ((searchText && !filtered.length) ? filtered : posts)?.length ?
                                    (
                                        ((filtered && filtered.length) ? filtered : posts).map((item, i) => (
                                            (posts.length === i + 1) ? (
                                                <FeedBox
                                                    ref={lastPostElementRef}
                                                    mainImage={selectedFeed?.image}
                                                    key={i}
                                                    data={item}                                                                                
                                                    time 
                                                    author
                                                    category                                        
                                                />                                    
                                            ) : (
                                                <FeedBox
                                                    mainImage={selectedFeed?.image}
                                                    key={i}
                                                    data={item}                                                                                
                                                    time 
                                                    author
                                                    category                                        
                                                />                                    
                                            )
                                        ))
                                    )
                                        : <p
                                            className="tex-gray-500 text-sm text-left"
                                        >No Posts Found
                                        </p> 
                                    : 
                                    <PreLoaderList />                                                       
                                : null
                            }                             
                            {
                                loading && (
                                    <p className="text-gray-800 py-10 text-lg font-medium">Loading...</p>
                                )
                            } 
                            {
                                 selectedFeed?.feed_type === 'Internal Blog' && (                                                   
                                    <div className="flex lg:flex-row flex-col items-start lg:items-center">                                                    
                                                    <p className="text-md font-medium">Share: </p>
                                                    <div className="mr-3 lg:mx-3 py-1 mb-2 flex items-center">
                                                        <a rel="noopener noreferrer" target="_blank" href={`https://www.facebook.com/sharer.php?u=${window.location.href}`} className="text-lg mr-2"><FacebookFilled /></a>
                                                        <a rel="noopener noreferrer" target="_blank" href={"http://twitter.com/share?&url="+window.location.href+"&hashtags=assistance,assistanceorg,helpingothersmatter"} className="text-lg mx-2"><TwitterOutlined /></a>                                                
                                                        <a rel="noopener noreferrer" href={"https://api.whatsapp.com://send?text=Hey there! look out here: "+window.location.href} data-action="share/whatsapp/share" className="text-lg lg:mx-2"><WhatsAppOutlined /></a>                              
                                                    </div>                                     
                                    </div>
                                )
                            }
                            {
                                selectedFeed?.feed_type === 'Internal Blog' && !selectedFeed?.is_comment_locked && (
                                    <div className="w-full flex flex-col items-start my-4">
                                        <div className="flex items-start">
                                            <p className="text-gray-800 py-2 text-lg font-medium">Comments</p>
                                            {
                                                !token && (
                                                    <Link   
                                                    to="/signin"                                             
                                                    className="mx-4 text-white rounded-lg text-md block primary-bg text-center
                                                        px-4 py-2 cursor-pointer focus:outline-none hover:text-gray-400"
                                                    >Sign In to comment
                                                    </Link>
                                                )
                                            }
                                        </div>
                                        {
                                            !!token && (
                                                <Comment
                                                    avatar={user?.image || getProfileImage(user?.username || 'Assistance')}
                                                    content={<>
                                                        <div className="w-full flex flex-row items-start">
                                                            <textarea
                                                                maxLength={commentLimit}
                                                                value={userComment}
                                                                onChange={e => setUserComment(e.target.value)}
                                                                rows="2" 
                                                                style={{ resize: "none" ,overflow: "auto" }}                                               
                                                                placeholder="Add your comment here"
                                                                className="w-full border p-2 border-gray-500 rounded-lg mr-2 focus:outline-none"
                                                            ></textarea>
                                                            <button       
                                                                onClick={() => addComment()}                                                                                                                                                                     
                                                                className="text-white rounded-lg text-md block primary-bg text-center
                                                                        px-4 py-2 cursor-pointer mb-4 focus:outline-none"
                                                            >{commentLoad?'Please Wait...':'Add'}                
                                                            </button>
                                                        </div>
                                                        <p className="text-gray-600 py-1 text-right text-md mb-2">{`${commentLimit - userComment.length} characters left`}</p>                                                                              
                                                        </>}
                                                />
                                            )
                                        }                                        
                                        {
                                            !!feedComments.length ? (
                                                feedComments.map(comment => (
                                                    <FeedComment
                                                        key={comment.id}
                                                        children={comment.children||comment.replyChildren}
                                                        item={comment}
                                                    />
                                                ))
                                            ) : <p className="text-md text-gray-700 text-center py-3">No Comments</p>
                                        }
                                        <Modal
                                            style={{ top: 20 }}
                                            title="Add Your Reply"
                                            visible={commentInputModal}
                                            okText={"Post"}                                            
                                            onOk={() => handleOkInputModal()}
                                            onCancel={() => handleCancelInputModal()}
                                        >
                                            <Input 
                                                value={level1Reply}
                                                onChange={e => setLevel1Reply(e.target.value)}
                                                required                        
                                                type="text" 
                                                placeholder="Your Reply"
                                                className="w-full p-2 my-2 rounded-lg text-black text-md"
                                            />      
                                        </Modal>  
                                        <Modal
                                            style={{ top: 20 }}
                                            width={600}
                                            title="Comment Replies"
                                            visible={level2RepliesModal}
                                            footer={null}
                                            onCancel={() => handleLevel2Cancel()}                                            
                                        >
                                            {
                                                level2Replies.length ? 
                                                (
                                                    level2Replies.map(comment => (
                                                        <FeedComment
                                                            key={comment.id}
                                                            children={comment.children||comment.replyChildren}
                                                            item={comment}
                                                        />
                                                    ))
                                                ) : ( <div className="w=full flex items-center justify-center"><Spin loading={true} size="small" /></div>)
                                            }
                                        </Modal>                                 
                                    </div>
                                )
                            }                
                    </div>                    
                    <div className="lg:ml-4 lg:w-1/3 w-full mb-4 sticky top-0">
                        <SearchBar 
                            placeholderText="What you need assistance for ?"
                            data={[]}
                        />  
                        <CategorySlide />
                        <CategorySlide
                            currentFeed={selectedFeed}
                            boxTitle="Similar Articles"
                        />
                       <div className="w-full">
                        <Footer 
                                type="small"
                        />
                       </div>
                    </div>                
                </div>

                </div>  
                )
            }
        </div>
    )
}

export default Feed
