import React from 'react'
import Header from '../components/User/Header'
import Footer from '../components/User/Footer'
import { Link } from 'react-router-dom'
import masterImage from '../assets/img/general-assistance1.jpg'

function About() {    

    document.title = 'About - Assistance';    

    return (
        <>
            <Header />    
            <div id="about" className="px-4 lg:px-24 w-full h-full py-8 flex flex-col items-center lg:items-start">
                <div className="w-full">
                    <h1 className="text-left font-extrabold text-3xl py-3 lg:px-12">About Us</h1>                
                    <Link 
                        to="/"
                        className="flex items-center">                        
                         <img                
                            alt="logo"    
                            className="object-contain lg:ml-12 my-2"
                            style={{ maxHeight: 400 }}
                            src={masterImage}
                        /> 
                    </Link>
                    <h2 className="text-left font-extrabold text-2xl lg:text-3xl lg:px-12">Welcome to <span className="primary-color">assistance.org</span></h2>                
                    <p className="text-gray-700 py-3 text-justify lg:px-12 text-lg">
                        We are so happy you are here!
                    </p>
                    <p className="text-gray-700 py-1 text-justify lg:px-12 text-lg">
                        Everyone needs a helping hand once in a while. Assistance.org connects people who are in need of assistance from all walks of life. Join today to be part of this network of helping hands!
                    </p>
                </div>
                <div id={'mission'} 
                    className="w-full py-8"                    
                >                                                              
                    <h1 className="text-left font-extrabold text-3xl py-3 lg:px-12">Mission</h1>                
                    <p className="text-gray-700 py-3 text-justify lg:px-12 text-lg">
                        Provide a safe and secure platform for local communities where people connect to help each other by sharing information or providing assistance.
                    </p>                    
                </div>
                <div id={'how'} 
                    className="w-full py-2"                    
                >                                                              
                    <h1 className="text-left font-extrabold text-3xl py-3 lg:px-12">What is assistance for ?</h1>                
                    <p className="text-gray-700 py-3 text-justify lg:px-12 text-lg">
                     1. Use the search area to search your topic of interest related to assisting people under different categories. The articles shown are a combination of internal articles owned by assistance.org and links to external sources.
                    </p>   
                    <p className="text-gray-700 py-3 text-justify lg:px-12 text-lg">
                    2. Go to the Blog section and select a category to see all articles and links for the selected category. We are adding more links and articles every day.
                    </p>                    
                    <p className="text-gray-700 py-3 text-justify lg:px-12 text-lg">
                    3. Go to the Organization section to search for all nearby organizations where you can find more help related to your needs.  These lists include mostly non-profit organizations.
                    </p>
                </div>
                <div className="w-full lg:px-12">
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default About
