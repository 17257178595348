import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function InternalBlogItem({ id, image, category, url, title, content, isIcon }) {

    const [contents, setContents] = useState('');

    useEffect(() => {
        if (content) {
            let contentJSON = JSON.parse(content);
            let contentString = contentJSON?.blocks[0]?.data?.text?.slice(0, 210) + "..." || "";
            setContents(contentString);
        }
    }, [content]);

    return (
        <div 
            onClick={() => window.open(url)}
            key={id} 
            className="hover:shadow-xs w-full flex flex-row items-start p-3 cursor-pointer hover:bg-gray-200 hover:text-black">
            <div className={`${isIcon ? 'internal__blog__item_icon' : 'internal__blog__item'}`} style={{ backgroundImage: `url('${image}')` }}>
                    {/* <img
                        alt="result-logo"
                        style={{ maxHeight: 70 }}
                        className="mx-4 rounded-xs contain"
                        src={image}
                    /> */}
            </div>
            <div className={`internal__blog__item__left ml-4 flex flex-col items-start`}>
                <Link
                    className="w-full text-base font-medium hover:underline hover:text-black cursor-pointer mb-1"
                    to={url}
                >{title}
                </Link>
                <div className="w-full flex flex-row items-center mb-1">                   
                    <Link
                        className="text-sm text-gray-700 hover:underline cursor-pointer hover:text-black"
                        to={`/article/${category}`}
                    >{category}
                    </Link>
                </div>
                <p className="w-full text-left text-sm text-gray-800"
                >{contents}
                </p>
            </div>
        </div>
    )
}

export default InternalBlogItem
