import React from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

function Maps({ data }) {
   
    return (
        <MapContainer center={[data[0]?.latitude, data[0]?.longitude]} zoom={13}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                    {
                        data.map(item => (
                            (!!item?.latitude) && (
                                <Marker position={[item?.latitude, item?.longitude]}>
                                    <Popup>
                                        <strong>{item?.title || 'Organization'}</strong><br/>
                                        {item?.line_1}, {item?.city}, {item?.state}, {item?.country}
                                    </Popup>
                                </Marker>
                            )
                        ))
                    }
        </MapContainer>
    )
}

export default Maps
