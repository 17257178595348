import React, { useState, useEffect } from 'react'
import Header from '../components/User/Header'
import Footer from '../components/User/Footer'
import { Input, message, Result, Checkbox, Select } from 'antd'
import { SmileOutlined } from '@ant-design/icons';
import '../stylesheets/GuestOrg.css'
import { validateEmail, validateURL } from '../utils/Helper'
import { Link, useHistory } from 'react-router-dom'
import apiInstance from '../api/'
import orgLogo from '../assets/img/buildingLogo.svg'
import { useDataLayerValue } from '../DataLayer'
import axios from 'axios'

const  { Option } = Select;

function GuestOrg() {
    let history = useHistory();

    document.title = 'Organization Information - Assistance';

    const [{ user, categories, allAddressTypes }] = useDataLayerValue();

    const [buttonLabel, setButtonLabel] = useState('Submit');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [url, setUrl] = useState('');
    const [email, setEmail] = useState('');
    const [orgType, setOrgType] = useState('type');
    const [phone, setPhone] = useState('');
    const [contact, setContact] = useState('');    
    const [owned, setOwned] = useState(false);
    const [ipAddress, setIpAddress] = useState('');
    const [done, setDone] = useState(false);
    const [otherCategory, setOtherCategory] = useState('');

    // address variables
    const [addressType, setAddressType] = useState(null);
    const [line1, setLine1] = useState('');
    const [line2, setLine2] = useState('');
    const [city, setCity] = useState('');
    const [states, setStates] = useState('');
    const [country, setCountry] = useState('');
    const [zip, setZip] = useState('');

    const isAddressNeeded = line1 !== '' || city !== '' || states !== '' || country !== '' || zip !== '';
    const isAddressCheck = line1 !== '' && city !== '' && states !== '' && country !== '' && zip !== '';

    useEffect(() => {
        if (allAddressTypes?.length) {
            let addressTypeId = allAddressTypes.filter(a => a.name === 'Organization')[0];
            setAddressType(addressTypeId?.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[allAddressTypes]);

    useEffect(() => {
        async function getClientIP() {
            if (!sessionStorage.getItem('current-ip-address-assistance-user')) {
                const IP = await axios.get("https://api.ipify.org/?format=json");                
                setIpAddress(IP.data?.ip||'');
                sessionStorage.setItem('current-ip-address-assistance-user', IP.data?.ip||'');
            } else setIpAddress(sessionStorage.getItem('current-ip-address-assistance-user'));
        }
        getClientIP();
    },[]);

    const submitMessage = (e) => {    
                   
        e.preventDefault();  
        let payload = {
            name,
            url_key: name.toLowerCase().replace(' ','-'),
            description,
            url,
            email,
            orgType,
            phone,
            contact_name: contact,
            userip: ipAddress,
            owned,
            username: user?.username || null,
            userid: user?.id || null,
            addressType,
            line1,
            line2,
            city,
            states,
            country,
            zip,
            otherCategory
        };             

        if (orgType === 'type' || !orgType) {
            message.warn("Organization Type not selected");
        } else if (!validateURL(url))  {
            message.warn("Invalid URL provided");
        } else if (orgType && name && url) {
            if (email && !validateEmail(email))  {
                message.warn("Invalid Email Address");
            } else { 
                if (isAddressCheck) {
                    message.warning("Please complete the address information");
                } else if (owned && !email) {
                    message.warn("Email Address is required");
                } else {
                    setButtonLabel("Please Wait..."); 
                    apiInstance
                    .post("/organization/secure/create", payload)
                                .then(response => {
                                    setButtonLabel("Submit"); 
                                    if(response.data.data) {                                
                                        setName('');
                                        setDescription('');
                                        setUrl('');
                                        setEmail('');                                
                                        setOrgType('');
                                        setPhone('');
                                        setContact('');
                                        message.success('Organization Submited');
                                        setDone(true);                                
                                    } else message.warning(response.data?.data || 'something went wrong');
                                }).catch(e => {
                                    setButtonLabel("Submit"); 
                                    console.log(e);
                                    if(e.response || e.response?.data) {                                    
                                        if(e.response.status === 401) {
                                            alert('Your Session is expired!');
                                            sessionStorage.clear();                            
                                            window.location.replace("/signin");                                     
                                        } else {
                                            if(typeof e.response?.data?.message === 'string') {
                                                message.error(e.response.data.message);                                      
                                            } else {
                                                message.warn("Something went wrong");
                                            }                        
                                        }
                                    } else message.error("Internal Server Error");  
                                });
                            }
                    }
        } else message.warn("Inputs are missing!");
    }

    const cancelSubmission = () => {
                                history.push("/");
                                setName('');
                                setDescription('');
                                setUrl('');
                                setEmail('');                                
                                setOrgType('type');
                                setPhone('');
                                setContact('');
                                setOwned(false);
    }

    return (
        <>
            <Header />
            <div className="w-full flex flex-col items-center justify-center"> 
                <div className="p-8 w-full flex flex-col items-center">
                    <img
                        alt="organization"
                        className="h-12 lg:h-20 contain"
                        src={orgLogo}
                    />
                    <h1 className="font-extrabold text-lg lg:text-2xl text-left mt-3">Organization You Know</h1>
                    <p className="text-sm lg:text-md text-left py-1 text-gray-700">
                        Submit information about an organization you already know that could help someone.
                    </p>
                </div>           
               {
                !done ?             
                ( <form className="w-full px-4 lg:px-24 py-4 contact">  
                    <div className="flex lg:flex-row flex-col items-center w-full">
                        <div className="contact__inputGroup flex flex-col items-start py-2 w-full">
                            <p>Name <span className="text-red-500">*</span></p>
                            <Input 
                                value={name}
                                onChange={e => setName(e.target.value)}
                                required                                                
                                type="text" 
                                placeholder="Organization Name"
                                className="p-3 my-2 rounded-lg text-black"
                            />
                        </div>
                        <div className="contact__inputGroup flex flex-col items-start py-2 w-full">
                            <p>Description</p>
                            <Input 
                                value={description}
                                onChange={e => setDescription(e.target.value)}
                                required                                                
                                type="text" 
                                placeholder="Short Description"
                                className="p-3 my-2 rounded-lg text-black"
                            />
                        </div>
                        <div className="contact__inputGroup flex flex-col items-start py-2 w-full">
                            <p>Website URL <span className="text-red-500">*</span></p>
                            <Input 
                                value={url}
                                onChange={e => setUrl(e.target.value)}
                                required                                                
                                type="url" 
                                placeholder="Website URL"
                                className="p-3 my-2 rounded-lg text-black"
                            />
                        </div> 
                    </div>                  
                    <div className="flex lg:flex-row flex-col items-center w-full">
                        <div className="contact__inputGroup flex flex-col items-start py-2 w-full">
                            <p>Type <span className="text-red-500">*</span></p>
                            <Select            
                            size="large"        
                            className="w-full my-2 rounded-lg text-black"
                            value={orgType}                        
                            onChange={e => setOrgType(e)}
                            >
                                <Option value="type" disabled="true">Select Organization Type</Option>
                                {
                                    categories?.map((category, idx) => (
                                        <Option key={idx} value={category?.id}>{category?.title}</Option>
                                    ))
                                }
                            </Select> 
                            {
                                categories?.filter(item => item?.id === orgType)[0]?.title === 'Others' ? (
                                    <Input 
                                        value={otherCategory}
                                        onChange={e => setOtherCategory(e.target.value)}
                                        required                                                
                                        type="text" 
                                        placeholder="Enter the category"
                                        className="p-3 mt-1 rounded-lg text-black"
                                    /> 
                                ) : null
                            }
                        </div>
                        <div className="contact__inputGroup flex flex-col items-start py-2 w-full">
                        <p>Email Address <span className={`${owned ? '' : 'hidden'} text-red-500`}>*</span></p>
                        <Input 
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            required                                                
                            type="email" 
                            placeholder="Organization Email"
                            className="p-3 my-2 rounded-lg text-black"
                        />
                    </div> 
                    <div className="contact__inputGroup flex flex-col items-start py-2 w-full">
                            <p>Contact Name</p>
                            <Input 
                                value={contact}
                                onChange={e => setContact(e.target.value)}
                                required                                                
                                type="text" 
                                placeholder="Contact"
                                className="p-3 my-2 rounded-lg text-black"
                            />
                    </div>
                    </div>           
                   
                    <div className="flex lg:flex-row flex-col items-center w-full">
                         
                        <div className="contact__inputGroup flex flex-col items-start py-2 w-full">
                            <p>Phone</p>
                            <Input 
                                value={phone}
                                onChange={e => setPhone(e.target.value)}
                                required       
                                maxLength="13"                                         
                                type="number" 
                                placeholder="Organization Contact Number"
                                className="p-3 my-2 rounded-lg text-black"
                            />
                        </div> 

                        <div className="contact__inputGroup flex flex-col items-start py-2 w-full">
                            <p>Line 1 <span className={`${isAddressNeeded ? '' : 'hidden'} text-red-500`}>*</span></p>
                            <Input 
                                value={line1}
                                onChange={e => setLine1(e.target.value)}
                                required                                                
                                type="text" 
                                placeholder="Line 1"
                                className="p-3 my-2 rounded-lg text-black"
                            />
                        </div>

                        <div className="contact__inputGroup flex flex-col items-start py-2 w-full">
                            <p>Line 2 </p>
                            <Input 
                                value={line2}
                                onChange={e => setLine2(e.target.value)}
                                required                                                
                                type="text" 
                                placeholder="Line 2"
                                className="p-3 my-2 rounded-lg text-black"
                            />
                        </div>
                        
                    </div>

                    <div className="flex lg:flex-row flex-col items-center w-full">
                        
                        <div className="contact__inputGroup flex flex-col items-start py-2 w-full">
                            <p>City <span className={`${isAddressNeeded ? '' : 'hidden'} text-red-500`}>*</span></p>
                            <Input 
                                value={city}
                                onChange={e => setCity(e.target.value)}
                                required                                                
                                type="text" 
                                placeholder="City"
                                className="p-3 my-2 rounded-lg text-black"
                            />
                        </div> 

                        <div className="contact__inputGroup flex flex-col items-start py-2 w-full">
                            <p>State <span className={`${isAddressNeeded ? '' : 'hidden'} text-red-500`}>*</span></p>
                            <Input 
                                value={states}
                                onChange={e => setStates(e.target.value)}
                                required                                                
                                type="text" 
                                placeholder="State"
                                className="p-3 my-2 rounded-lg text-black"
                            />
                        </div> 

                        <div className="contact__inputGroup flex flex-col items-start py-2 w-full">
                            <p>Country <span className={`${isAddressNeeded ? '' : 'hidden'} text-red-500`}>*</span></p>
                            <Input 
                                value={country}
                                onChange={e => setCountry(e.target.value)}
                                required                                                
                                type="text" 
                                placeholder="Country"
                                className="p-3 my-2 rounded-lg text-black"
                            />
                        </div> 
                    </div>

                    <div className="flex lg:flex-row flex-col items-center w-full">
                        
                        <div className="contact__inputGroup flex flex-col items-start py-2 w-full lg:w-1/2">
                            <p>Zip Code <span className={`${isAddressNeeded ? '' : 'hidden'} text-red-500`}>*</span></p>
                            <Input 
                                value={zip}
                                onChange={e => setZip(e.target.value)}
                                required                                                
                                type="number" 
                                placeholder="Zip Code"
                                className="p-3 my-2 rounded-lg text-black"
                            />
                        </div> 
                        <div className="contact__inputGroup flex flex-col items-start py-2 w-full">                        
                        <Checkbox          
                            className="my-2 text-md text-gray-800"                  
                            checked={owned}
                            onChange={e => setOwned(e.target.checked)}
                        >Do you own the organization?
                        </Checkbox>
                    </div> 
                    </div>
                    
                                       
                    <div className="flex flex-row items-center">
                        <button  
                            disabled={buttonLabel==='Submitting'}
                            onClick={submitMessage}                         
                            type="submit"             
                            className={`primary-bg rounded-md text-white px-8 mx-2 py-2 hover:text-gray-400 my-2 ${buttonLabel==='Submitting'?'cursor-not-allowed':''}`}
                        >
                        {buttonLabel}
                        </button>                   
                        <button  
                            onClick={() => cancelSubmission()}
                            className="bg-white rounded-md primary-color hover:bg-gray-100 border px-8 mx-2 py-2 hover:text-gray-800 my-2"
                        >
                        Cancel
                        </button>                   
                    </div>
                </form> ) : (
                    <Result 
                        icon={<SmileOutlined />}
                        title="Thank you for your valuable information!"
                        subTitle={<p className="text-black text-lg">Submitted organization will be reviewed and made it visible to public. <br/>{(owned && email) ? 'A link is sent to the email address registerted with this organization, visit the link to verify the organization' : ''}</p>}
                        extra={[
                            <Link to="/" key="console" className="primary-bg rounded-md text-white px-8 mx-2 py-2 hover:text-gray-400 my-2">
                                Back to Home
                            </Link>,
                          ]}
                    />
                )}               
            </div>
            <div className="w-full lg:px-12 px-8 py-10">
                <Footer type="full" />
            </div>
        </>
    )
}

export default GuestOrg
