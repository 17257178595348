import React, { useEffect, useState } from 'react'
import Header from '../components/User/Header'
import { Input, message } from 'antd'
import { useParams, useHistory } from 'react-router-dom'
import '../stylesheets/Login.css'
import apiInstance from '../api'

function GuestRoute() {

    let { userId, token } = useParams();
    let history = useHistory();

    const [buttonLabel, setButtonLabel] = useState('Reset Password');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorText, setErrorText] = useState(null);


    useEffect(() => {
        if (password !== confirmPassword) {
            setErrorText('Password do not match');
        } else setErrorText(null);
    },[password, confirmPassword]);

    const handleResetPassword = (e) => {
        e.preventDefault();   
        setButtonLabel('Please Wait...');
        let payload = {
            password,
            userId,
            token
        };
        apiInstance
            .post('/auth/reset/password', payload)
            .then(response => {
                if (response.data.status) {
                    message.success(response.data.message);
                    sessionStorage.setItem('assistance_current_route_helper','/');
                    history.push('/signin');               
                } else {
                    message.error(response.data?.message || 'Something went wrong!');
                }
                setButtonLabel('Reset Password');
                setPassword('');
                setConfirmPassword('');
                setErrorText(null);
            })
            .catch(e => {
                message.error(e.response.data?.message || 'Something went wrong!');
                setButtonLabel('Reset Password');
                setPassword('');
                setConfirmPassword('');
                setErrorText(null);
                console.log(e);
            });
    }

    return (
        <>
            <Header />             
            <div className="w-full flex flex-row items-center justify-center">
                <div 
                    className="lg:w-1/3 md:w-1/2 bg-white border rounded-lg px-2 py-4 my-8
                                flex flex-col items-center shadow-md"
                >
                    <h1 className="text-lg text-black py-3">
                        Create New Password
                    </h1>
                    <form className="w-full login">
                        <Input.Password 
                            required   
                            value={password}                     
                            onChange={e => setPassword(e.target.value)}
                            type="password" 
                            placeholder="New Password"
                            className="w-full p-2 my-2 rounded-lg text-black text-md"
                        />                           
                        <Input.Password 
                            required  
                            value={confirmPassword}                      
                            onChange={e => setConfirmPassword(e.target.value)}
                            type="password" 
                            placeholder="Confirm New Password"
                            className="w-full p-2 my-2 rounded-lg text-black text-md"
                        />     
                        <p className="w-full text-sm text-red-500 text-left py-2"
                        >{errorText}</p>                                      
                        <button 
                            onClick={handleResetPassword}                                                     
                            htmlType="submit"                                      
                            className="login__button w-full text-white font-medium rounded-lg text-md block primary-bg text-center
                                    p-3 my-2 cursor-pointer mb-4 focus:outline-none disabled:cursor-none"
                        >{buttonLabel}
                        </button>
                    </form>                    
                </div>
            </div>           
        </>
    )
}

export default GuestRoute