import React, { useState, useEffect } from 'react'
import { Modal, Input, message, Form, Typography, Button, Switch } from 'antd'
import apiInstance from '../../api'
import { useDataLayerValue } from '../../DataLayer'

const { Text } = Typography;

function AddressModal({ modalTitle, okLabel, trigger, callTrigger = ct => ct, data=null, type, id=null, sector="org" }) {

    const [{ allAddressTypes }] = useDataLayerValue();

    const [addressType, setAddressType] = useState('');
    const [defaults, setDefault] = useState(false);  
    const [line1, setLine1] = useState('');
    const [line2, setLine2] = useState('');
    const [city, setCity] = useState('');
    const [states, setStates] = useState('');
    const [country, setCountry] = useState('');
    const [zip, setZip] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');  
    const [load, setLoad] = useState(false);
    const [location, setLocation] = useState('');
    const [addressId, setId] = useState(null);
    
    const [oldLine1, setOldLine1] = useState('');
    const [oldLine2, setOldLine2] = useState('');
    const [oldCity, setOldCity] = useState('');
    const [oldStates, setOldStates] = useState('');
    const [oldCountry, setOldCountry] = useState('');
    const [oldZip, setOldZip] = useState('');
    const [oldLocation, setOldLocation] = useState('');

    const [defaultLoading, setDefaultLoading] = useState(false);

    useEffect(() => {
        if (location!=='' && location) {
            if (location?.split(',')) {
                let locationArr = location.split(',');
                setLatitude(locationArr[0]?.trim());
                setLongitude(locationArr[1]?.trim());
            }
        }
    },[location]);

    useEffect(() => {
        if (data) {
            setId(data?.id);
            setLine1(data?.line_1||'');
            setLine2(data?.line_2||'');
            setCity(data?.city||'');
            setStates(data?.state||'');
            setCountry(data?.country||'');
            setZip(data?.zipcode||'');
            setDefault(data?.default_type===1?true:false);
            if (data?.latitude && data?.longitude) {
                setLocation(`${data?.latitude||''}, ${data?.longitude||''}`);
            }
            setOldLine1(data?.line_1||'');
            setOldLine2(data?.line_2||'');
            setOldCity(data?.city||'');
            setOldStates(data?.state||'');
            setOldCountry(data?.country||'');
            setOldZip(data?.zipcode||'');
            if (data?.latitude && data?.longitude) {
            setOldLocation(`${data?.latitude||''}, ${data?.longitude||''}`);
            }
        }
    },[data]);
    
    useEffect(() => {
        let addressTypeId = allAddressTypes.filter(a => a.name === (sector==="org"?'Organization':'Person'))[0];
        setAddressType(addressTypeId?.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const handleOk = (e) => {
        if (type === 'create') {
            let payload = {};
            if (sector === "org") {
                payload = {
                    addressType,
                    orgId: id,
                    line1,
                    line2,
                    city,
                    state: states,
                    country,
                    zip,
                    latitude,
                    longitude,
                    default_type: defaults           
                }
            } else {
                payload = {
                    addressType,
                    personId: id,
                    line1,
                    line2,
                    city,
                    state: states,
                    country,
                    zip,
                    latitude,
                    longitude,
                    default_type: 1            
                }
            }    
            
            apiInstance
                .post("/address/", payload)
                .then(response => {
                    if(response.data.data) {
                        setLoad(false);
                        callTrigger(false);
                        message.success('Address Created');                    
                    } else message.warning(response.data?.data || 'something went wrong');
                }).catch(e => {
                    console.log(e);
                    if(e.response || e.response?.data) {                                    
                        if(e.response.status === 401) {
                            alert('Your Session is expired!');
                            sessionStorage.clear();                            
                            window.location.replace("/signin");                                     
                        } else {
                            if(typeof e.response?.data?.message === 'string') {
                                message.error(e.response.data.message);                                      
                            } else {
                                message.warn("Something went wrong");
                            }                        
                        }
                    } else message.error("Internal Server Error");  
                });
        } else {
            let payload = {};
        if (sector === "org") {
            payload = {
                addressType,
                orgId: id,
                line1,
                line2,
                city,
                state: states,
                country,
                zip,
                latitude,
                longitude            
            }
        } else {
            payload = {
                addressType,
                personId: id,
                line1,
                line2,
                city,
                state: states,
                country,
                zip,
                latitude,
                longitude            
            }
        }    
        
        apiInstance
            .put(`/address/${data?.id}`, payload)
            .then(response => {
                if(response.data.data) {
                    setLoad(false);
                    callTrigger(false);
                    message.success('Address Updated');                    
                } else message.warning(response.data?.data || 'something went wrong');
            }).catch(e => {
                console.log(e);
                if(e.response || e.response?.data) {                                    
                    if(e.response.status === 401) {
                        alert('Your Session is expired!');
                        sessionStorage.clear();                            
                        window.location.replace("/signin");                                     
                    } else {
                        if(typeof e.response?.data?.message === 'string') {
                            message.error(e.response.data.message);                                      
                        } else {
                            message.warn("Something went wrong");
                        }                        
                    }
                } else message.error("Internal Server Error");  
            });
        }
    }    

    const handleCancel = () => {
        callTrigger('cancel');
    }    

    const openLocationHelper = () => {
        let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
                        width=0,height=0,left=-1000,top=-1000`;
        let googleMapsUrl = `https://www.google.com/maps/place/${line1}, ${city} ${states} - ${zip}, ${country}`;
        window.open(googleMapsUrl, "Location Helper", params);
    }

    const handleAddressDefault = (flag) => {
        if(id && addressId) {
            setDefault(flag);
            setDefaultLoading(true);
            apiInstance
            .put(`/address/organization/default/${addressId}`, { org_id: id, status: flag })
            .then(response => {
                setDefaultLoading(false);
                if(response.data.status) {                             
                    message.success(response.data.data);
                } else message.warning(response.data?.data || 'something went wrong');
            })
            .catch(e => {
                setDefaultLoading(false);
                console.log(e);
                if(e.response || e.response?.data) {                
                    message.error(e.response.data.message);
                    if(e.response.status === 401) {
                        sessionStorage.clear();
                        window.location.replace("/signin");
                    }
                } else message.error("Internal Server Error");
            });
        } else message.warning("Org ID not found");
    }

    return (
        <Modal        
            style={{ top: 20 }}
            maskClosable={false}                     
            destroyOnClose={true}
            title={modalTitle}
            visible={trigger}
            onOk={handleOk} 
            onCancel={handleCancel}           
            okText={okLabel}
            okButtonProps={{
                loading: load,
                disabled: (
                    type==='update'?
                            ((oldLine1 === line1) && (oldLine2 === line2) && (oldCity === city) && (oldStates === states) && (oldCountry === country) && (oldZip === zip) && (oldLocation === location)):
                            type === 'create' ?(!city || !states || !country || !zip):false)                            
            }}
        >

                <Text>Address Type: {sector==="org"?"Organization":"Person"}{defaults?'(Default)':''}</Text>
                {
                    sector === "org" && (
                        <div className="w-full flex items-center my-2">
                            <p className="text-left mr-2">
                                Set Default
                            </p>
                            <Switch
                                loading={defaultLoading}
                                defaultChecked={defaults}
                                size="small"
                                checked={defaults}
                                onChange={(e) => handleAddressDefault(e)}
                            />
                        </div>
                    )
                }
                <Form.Item
                 className="w-full flex flex-col mb-2"
                 label="Line 1">
                    <Input  
                        className="w-full"                                                          
                        onChange={e => setLine1(e.target.value)}
                        value={line1}                
                        placeholder="Address Line 1"
                        type="text"                        
                    />
                </Form.Item> 
                <Form.Item
                    className="w-full flex flex-col mb-2"
                 label="Line 2">
                    <Input  
                       className="w-full"                                                           
                        onChange={e => setLine2(e.target.value)}
                        value={line2}                
                        placeholder="Address Line 2"
                        type="text"                        
                    />
                </Form.Item> 
                <Form.Item 
                className="w-full flex flex-col mb-2"
                label="City">
                <Input             
                   className="w-full"                                                    
                    onChange={e => setCity(e.target.value)}
                    value={city}                
                    placeholder="City"
                    type="text"                    
                /></Form.Item>                             
                <Form.Item 
                className="w-full flex flex-col mb-2"
                label="State">
                <Input                 
                   className="w-full"                        
                    onChange={e => setStates(e.target.value)}
                    value={states}                
                    placeholder="State"
                    type="text"                    
                /></Form.Item>
                <Form.Item 
                className="w-full flex flex-col mb-2"
                label="Country">
                <Input             
                   className="w-full"                            
                    onChange={e => setCountry(e.target.value)}
                    value={country}                
                    placeholder="Country"
                    type="text"                    
                /></Form.Item>
                <Form.Item 
                className="w-full flex flex-col mb-2"
                label="Zip Code">
                <Input           
                   className="w-full"                                                  
                    onChange={e => setZip(e.target.value)}
                    value={zip}                
                    placeholder="Zip Code"
                    type="number"                    
                /></Form.Item>
                {
                    sector === "org" && (
                    <>
                    <hr></hr>                
                    <Button
                        disabled={!line1 || !city || !states || !country || !zip}
                        onClick={() => openLocationHelper()}
                        type="primary"
                        size="small"
                    >Get Coordinates                    
                    </Button>
                    <Form.Item 
                   className="w-full flex flex-col mb-2"
                    label="Latitude">
                    <Input     
                       className="w-full"                                                        
                        onChange={e => setLocation(e.target.value)}
                        value={location}                
                        placeholder="Latitude, Lonigtude"
                        type="text"                    
                    /></Form.Item>       
                    </>
                )            
                }
        </Modal>   
    )
}

export default AddressModal
