import React from 'react'
import { Link } from 'react-router-dom'

function SearchResultItem({ id, image, category, url, title, description, map=false, mapData=null }) {

    return (
        <div 
            onClick={() => window.location.replace(url)}
            key={id} 
            className="hover:shadow-xs w-full flex flex-row items-start justify-between p-3 cursor-pointer hover:bg-gray-200 hover:text-black">
            <div className={`${map&&'w-1/2'} flex flex-col items-start`}>
                <Link
                    className="w-full text-base font-medium hover:underline hover:text-black cursor-pointer mb-1"
                    to={url}
                >{title}
                </Link>
                <div className="w-full flex flex-row items-center mb-1">
                    <img
                        alt="result-logo"
                        className="h-4 rounded-xs contain"
                        src={image}
                    />
                    <Link
                        className="ml-2 text-sm text-gray-700 hover:underline cursor-pointer hover:text-black"
                        to={`/article/${category}`}
                    >{category}
                    </Link>
                </div>
                <p className="w-full text-left text-sm text-gray-800"
                >{description}
                </p>
            </div>
            {
                map && !!mapData && (
                    <iframe
                        className="w-1/2 h-32 lg:mt-2 ml-1"
                        title="embed map"
                        style={{ border: 0 }}
                        loading="lazy"
                        allowFullScreen={true}
                        src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_EMBED_MAP_API}
                            &q=${mapData?.line_1.replace(' ', '+')},${mapData?.city},${mapData?.state}+${mapData?.country}`}>
                    </iframe> 
                )
            } 
        </div>
    )
}

export default SearchResultItem
