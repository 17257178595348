import React,{ useEffect, useState } from 'react'
import { Modal, Tabs, Checkbox, Divider, Spin, message } from 'antd'
import { useDataLayerValue } from '../../../DataLayer'
import { buildPermissionName, constructJSONMap } from '../../../utils/Helper'
import apiInstance from '../../../api'

const { TabPane } = Tabs;
const CheckboxGroup = Checkbox.Group;

function RPModal({ selectedRole=null, modalTrigger, callTrigger = ct => ct }) {

    const [{ allPermissions }] = useDataLayerValue();
    
    const [existingPermissions, setExistingPermissions] = useState([]);
    const [assignedPermissions, setAssignedPermissions] = useState([]);
    const [checkedPermissions, setCheckedPermissions] = useState([]);    
    const [progress, setProgress] = useState(true);
    const [checkAll, setCheckAll] = useState([]);
    const [permissionCategories, setPermissionCategories] = useState([]);
    const [permissionsToAdd, setPermissionsToAdd] = useState([]);
    const [permissionsToRemove, setPermissionsToRemove] = useState([]);
    const [load, setLoad] = useState(false);    


    useEffect(() => {        
        let categories = buildPermissionCategories(allPermissions);        
        if(allPermissions.length) setExistingPermissions(categories);        
// eslint-disable-next-line react-hooks/exhaustive-deps
    },[allPermissions]);
    
    useEffect(() => {
        if(selectedRole) {
            setProgress(true);
            apiInstance.get(`/permission/role/${selectedRole.id}`)
            .then(response => {
                if(response.data.status) {                    
                    setProgress(false);
                    if(response.data?.data.length) {
                        setAssignedPermissions(buildPlainOptions(response.data.data, "id"));
                        setCheckedPermissions(buildCheckedOptions(response.data.data));
                    } else {                                                                
                            let initial = [];
                            Array.from(permissionCategories).forEach((data, index) => {            
                                initial[index] = [];                                                                                   
                            });                                                      
                            setCheckedPermissions(initial)                                                                               
                    }                    
                } else message.warning(response.data.data);                 
            })
            .catch(e => {                
                console.log(e);
                setProgress(false);
                if(e.response || e.response?.data) {                
                    message.error(e.response.data.message);
                    if(e.response.status === 401) {
                        sessionStorage.clear();
                        window.location.replace("/admin");
                    }
                } else message.error("Internal Server Error");
            });     
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedRole]);    

    /**
     * Used to build the category with their permissions using the
     * available permissions from the DATA LAYER VALUE
     */
    const buildPermissionCategories = () => {                

        let result = []; // FINAL ARRAY TO BE RETURNED
        
        // BUILD CATEGORY ARRAY
        let permissionCategories = new Set();

        for(var entity of allPermissions) {
            let entityName = entity.name.split(':')[0];
            permissionCategories.add(entityName);
        }        

        setPermissionCategories(permissionCategories);

        for(let category of permissionCategories) {
            let innerJSON = {};
            innerJSON.title = category.charAt(0).toUpperCase() + category.slice(1);
            innerJSON.permissions = allPermissions.filter(permission => permission.name.split(':')[0] === category);
            result.push(innerJSON);
        }
               
        return result;

    }

    /**
     * Used to build the array out of json array from a particular key 
     * in a separate array
     * @param [{}] dataArray 
     */
    const buildPlainOptions = (dataArray, key) => {
        let result = [];
        if(dataArray.length) {
            // eslint-disable-next-line
            dataArray.map(item => {
                if(key === 'id') result.push(item[key]);
                else result.push(buildPermissionName(item[key]));
            });
        } 
        return result;
    }

    /**
     * Used to build the array out of json array 
     * in a separate nested array
     * @param [{}] dataArray 
     */
    const buildCheckedOptions = (dataArray) => {
        let result = [];                           

        if(dataArray.length) {
            for(let category of permissionCategories) {
                result.push(buildPlainOptions(dataArray.filter(p => p.permission.split(':')[0] === category), "permission"));
             }
        } else {
            // NO ASSIGNED PERMISSIONS            
            for(let i=0; i<permissionCategories.length; i++){
                result.push([]);
            }
        }

        setProgress(false);

        return result;
    }

    const handleCancel = () => {                    
        setAssignedPermissions([]);  
        setCheckedPermissions([]); 
        setCheckAll([]);
        callTrigger(false);
    }

    const handleOk = () => {

        if(permissionsToAdd.length || permissionsToRemove.length) {
            setLoad(true);
            if(permissionsToAdd.length && permissionsToRemove.length) {
                // NEED TO REMOVE AND THEN ASSIGN PERMISSIONS
                let addPermissionsJSON = {
                    data: JSON.stringify(constructJSONMap(permissionsToAdd, selectedRole.id, "pId", "rId"))
                }
    
                let removePermissionsJSON = {
                    data: JSON.stringify(constructJSONMap(permissionsToRemove, selectedRole.id, "pId", "rId"))
                }

                // REMOVING PERMISSIONS REQUIRED
                apiInstance.put("/permission/remove", removePermissionsJSON)
                .then(preResult => {
                    if(preResult.data.status) {                        
                        apiInstance.post("/permission/assign", addPermissionsJSON)
                        .then(response => {
                            if(response.data.status) {
                                message.success('Role Permissions Modified');
                                handleCancel();    
                            } else message.warning(response.data?.data || 'something went wrong');
                        }).catch(e => {
                            console.log(e);
                            if(e.response || e.response?.data) {                
                                message.error(e.response.data.message);
                                if(e.response.status === 401) {
                                    sessionStorage.clear();
                                    window.location.replace("/admin");
                                }
                            } else message.error("Internal Server Error");
                        });      
                    } else message.warning(preResult.data?.data || 'something went wrong');
                }).catch(e => {
                    console.log(e);
                    if(e.response || e.response?.data) {                
                        message.error(e.response.data.message);
                        if(e.response.status === 401) {
                            sessionStorage.clear();
                            window.location.replace("/admin");
                        }
                    } else message.error("Internal Server Error");
                });

                setLoad(false);
                
            } else if(permissionsToAdd.length) {
                setLoad(true);
                // NEED TO ADD PERMISSIONS
                let addPermissionsJSON = {
                    data: JSON.stringify(constructJSONMap(permissionsToAdd, selectedRole.id, "pId", "rId"))
                }
                
                apiInstance.post("/permission/assign", addPermissionsJSON)
                .then(response => {
                    if(response.data.status) {
                        message.success(response.data.data);
                        handleCancel();    
                    } else message.warning(response.data?.data || 'something went wrong');
                }).catch(e => {
                    console.log(e);
                    if(e.response || e.response?.data) {                
                        message.error(e.response.data.message);
                        if(e.response.status === 401) {
                            sessionStorage.clear();
                            window.location.replace("/admin");
                        }
                    } else message.error("Internal Server Error");
                });      
                setLoad(false);
            } else if(permissionsToRemove.length) {
                setLoad(true);
                // NEED TO REMOVE PERMISSIONS
                let removePermissionsJSON = {
                    data: JSON.stringify(constructJSONMap(permissionsToRemove, selectedRole.id, "pId", "rId"))
                }
                apiInstance.put("/permission/remove", removePermissionsJSON)
                .then(response => {
                    if(response.data.status) {
                        message.success(response.data.data);
                        handleCancel();
                    } else message.warning(response.data?.data || 'something went wrong');
                }).catch(e => {
                    console.log(e);
                    if(e.response || e.response?.data) {                
                        message.error(e.response.data.message);
                        if(e.response.status === 401) {
                            sessionStorage.clear();
                            window.location.replace("/admin");
                        }
                    } else message.error("Internal Server Error");
                });
                setLoad(false);
            }

        } else  message.warning('No changes detected!');

        // callTrigger(false)
    }  
    
    useEffect(() => {
        handlePermissionsChanges();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[checkedPermissions]);

    const handlePermissionsChanges = () => {
        let existingPermissions = assignedPermissions;
        let correctedPermissions = [];
        let innerCorrectedArray = [];
        // eslint-disable-next-line
        checkedPermissions.map(permission => {
        //     if(permission.length) correctedPermissions.push(allPermissions.filter(cp => buildPermissionName(cp.name) === permission)[0].id);
            if(permission.length) {
                // eslint-disable-next-line
                permission.map(p => innerCorrectedArray.push(p));
            }            
        });  
        // eslint-disable-next-line
        innerCorrectedArray.map(ic => {
            correctedPermissions.push(allPermissions.filter(ap => buildPermissionName(ap.name) === ic)[0].id);
        })        
        setPermissionsToAdd(correctedPermissions.filter(permission => !existingPermissions.includes(permission)));
        setPermissionsToRemove(existingPermissions.filter(permission => !correctedPermissions.includes(permission)));        
    }

    const onCheckAllChange = (e, permissions, index) => {        
        
        const newCheckPermissions = checkedPermissions.map((permission, idx) => {
            if(idx !== index) return permission;
            if(e.target.checked) {                
                return [
                    ...buildPlainOptions(permissions, "name")
                ]
            } else {
                return [];
            }
        });

        setCheckedPermissions(newCheckPermissions);        
        checkAll[index] = e.target.checked;
        setCheckAll(checkAll);             
    }

    const onCheckChange = (checkedList, index) => { 
    
            const newCheckPermissions = checkedPermissions.map((permission, idx) => {                
                if(idx !== index) return permission;
                return [
                    ...checkedList
                ]
            });                                        
            setCheckedPermissions(newCheckPermissions);        

        checkAll[index] = checkedList.length === existingPermissions[index].permissions.length;
        setCheckAll(checkAll);        
    }

    return (
        <Modal   
            width={800}
            maskClosable={false}         
            visible={modalTrigger}
            destroyOnClose={true}
            title={`Assign Permissions for ${selectedRole?.name||''}`}
            okText="Save"
            onOk={handleOk}
            onCancel={handleCancel} 
            okButtonProps={{
                loading: load
            }}                                   
        > 
            <Tabs tabPosition="left" style={{ height: 300}}>
                {
                    !progress && existingPermissions.length
                    ?
                        existingPermissions.map((permission, index) => (
                            <TabPane key={index} tab={permission.title}>
                                <div className="site-checkbox-all-wrapper">
                                    <Checkbox                                                   
                                        onChange={e => onCheckAllChange(e, permission.permissions, index)}
                                        checked={checkedPermissions[index]?.length === existingPermissions[index]?.permissions.length}                                                                            
                                    >Select all                                        
                                    </Checkbox>
                                </div>
                                <Divider />
                                <CheckboxGroup                                    
                                    defaultValue={checkedPermissions[index]}   
                                    value={checkedPermissions[index]}                                 
                                    options={buildPlainOptions(permission.permissions, "name")}  
                                    onChange={e => onCheckChange(e, index)}                                                                      
                                />
                            </TabPane>
                        ))
                    : <Spin />
                }                
            </Tabs>
        </Modal>
    )
}

export default RPModal
