import React from 'react'
import { Result } from 'antd'
import { Link } from 'react-router-dom'
import Header from '../components/User/Header'

function NotFound() {    
    return (
        <>
        <Header />
        <Result
            status="404"
            title="Page Not Found!"
            subTitle="The page you are looking for doesn't exists."
            extra={
                <Link
                to="/"
                className="primary-bg rounded-md text-white px-4 py-2 hover:text-gray-400"
                >Back Home
                </Link>
            }
        />
        </>
    )
}

export default NotFound
