import React, { useState, useEffect } from 'react'
import '../stylesheets/FeedbackFloater.css'
import dislikeIcon from '../assets/img/dislike.svg'
import likeIcon from '../assets/img/like.svg'
import { Input, message } from 'antd'
import apiInstance from '../api'
import { validateEmail } from '../utils/Helper'
import axios from 'axios'
import { useDataLayerValue } from '../DataLayer'

const { TextArea } = Input;

function FeedbackFloater() {

    const [{ user }] = useDataLayerValue();
        
    const [toggle, setToggle] = useState(false);
    const [opinion, setOpinion] = useState('');
    const [feedback, setFeedback] = useState('');
    const [email, setEmail] = useState('');
    const [buttonLabel, setButtonLabel] = useState('Submit');
    const [ipAddress, setIpAddress] = useState('');

    const handleToggle = (e) => {
        setToggle(e);
    }

    useEffect(() => {
        async function getClientIP() {
            if (!sessionStorage.getItem('current-ip-address-assistance-user')) {
                const IP = await axios.get("https://api.ipify.org/?format=json");                
                setIpAddress(IP.data?.ip||'');
                sessionStorage.setItem('current-ip-address-assistance-user', IP.data?.ip||'');
            }            
        }
        getClientIP();
    },[]);

    const handleOpinion = (e) => setOpinion(e);

    const handleSubmit = () => {
        let payload = {
            email,
            feedback,
            opinion,
            url: window.location.href,
            ip: ipAddress || sessionStorage.getItem('current-ip-address-assistance-user'),
            userid: user?.id || ''
        }
        setButtonLabel('Submitting');        
        if(!validateEmail(email)) message.warning('Email is not valid');
        if(feedback && email && validateEmail(email) && opinion) {
            
            apiInstance.post("/homepage/feedback", payload)
            .then(response => {                
                message.success("Thanks for your feedback");
                setToggle(false);
                setEmail('');                
                setFeedback('');
                setButtonLabel('Submit');                
            }).catch(e => {                
                console.log(e);          
                message.warning("Something went wrong!");                                      
                setEmail('');                
                setFeedback('');                
                setButtonLabel('Submit');            
            });            
        } else {            
            setButtonLabel('Submit');
            message.info('Input is badly formatted');            
        }
    }

    return (
        <>
            {/* FEEDBACK CHIP */}
            {
                !toggle && (
                    <div onClick={() => handleToggle(true)} className="w-32 hover:bg-gray-900 bg-gray-700 text-white feedback rounded-t-md cursor-pointer">
                        <p className="text-white text-center p-1">Feedback +</p>
                    </div>
                )
            }            
            {/* FEEDBACK CHIP */}
            {/* FEEDBACK MODAL */}
            {
                toggle && (                
                    <div className="lg:mb-0 mx-4 lg:mx-0 lg:w-1/4 bg-gray-700 feedback__modal flex flex-col items-start rounded-t-md sticky lg:fixed bottom-0 right-0">
                        <div className="w-full flex flex-row items-start justify-between py-1 px-3 border-b border-gray-600">
                            <h1 className="p-2 text-white text-md text-bold text-center p-1">Give your Feedback</h1>
                            <span onClick={() => handleToggle(false)} className="p-2 text-bold cursor-pointer text-white">&#x2715;</span>
                        </div>
                        <div className="w-full flex flex-col items-center">
                            <p className="py-2 w-full text-gray-200 text-center">We would like your feedback to improve our website.</p>
                            <div className="py-4 flex flex-row items-center justify-center">
                                <img
                                    onClick={() => handleOpinion('Like')}
                                    alt="like"
                                    className={`p-2 h-12 contain mx-4 border feedback__emotion rounded-md cursor-pointer ${opinion==='Like'?'border-gray-400':'border-gray-700'}`}
                                    src={likeIcon}
                                />
                                <img
                                    onClick={() => handleOpinion('Dislike')}
                                    alt="dislike"
                                    className={`p-2 h-12 contain mx-4 border feedback__emotion rounded-md cursor-pointer ${opinion==='Dislike'?'border-gray-400':'border-gray-700'}`}
                                    src={dislikeIcon}
                                />
                            </div>                        
                            <p className="p-2 text-white text-md text-bold text-center p-1">Please leave your feedback below:</p>
                            <Input
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                required                                                                                
                                placeholder="Email Address"
                                className="feedback__input p-2 my-2 text-md rounded-lg text-black"                                
                            />                            
                            <TextArea 
                                value={feedback}
                                onChange={e => setFeedback(e.target.value)}
                                required                                                                                
                                placeholder="Your Comment"
                                className="feedback__input p-2 my-2 text-md rounded-lg text-black"
                                rows="3"
                            ></TextArea>
                            <button  
                                onClick={() => handleSubmit()}                                                     
                                className={`feedback__btn bg-gray-600 rounded-md text-white hover:bg-gray-800 px-8 mx-2 py-2 my-2 focus:outline-none`}
                            >{buttonLabel}                            
                            </button>        
                        </div>
                    </div>                
                )
            }
            {/* FEEDBACK MODAL */}
        </>
    )
}

export default FeedbackFloater