import { Button } from 'antd';
import React, { useEffect } from 'react'
import { useDataLayerValue } from '../DataLayer'
import { useHistory } from 'react-router-dom'
import Header from '../components/User/Header'

function Home() {
    let history = useHistory();
    
    const [{ user, token }, dispatch] = useDataLayerValue();

    const logOut = () => {
        // LOGIC FOR LOGGING OUT !!!
        sessionStorage.clear();
        dispatch({
            type: 'LOG_OUT'
        });
        history.push("/admin");
    }

    useEffect(() => {

        document.title = "Home - Assistance";                   
        
        if(!token) history.push("/admin");                        
// eslint-disable-next-line react-hooks/exhaustive-deps
    },[token]);   

    return (
        <div>
            <Header />
            <div className="w-full h-full flex flex-col items-center justify-center py-20">            
                <p className="w-full text-3xl font-bold text-center primary-color">{user?.username||'Username'}</p>
                <p className="text-md text-white rounded-lg primary-bg px-6 py-2 my-2">{user?.role||'Role'}</p>                
                <Button className="my-4" onClick={logOut} type="dark">Log Out</Button>          
            </div>                                    
        </div>
    )
}

export default Home
