import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Skeleton, Tree } from 'antd'
import { useDataLayerValue } from '../../DataLayer'
import { generateTreeData } from '../../utils/Helper'
import apiInstance from '../../api'

function CategorySlide({ boxTitle='Articles', data=null, currentFeed=null }) {

    let history = useHistory();
    const [{ categories, userSelectedCategories, currentSelectedCategory }, dispatch] = useDataLayerValue();  

    const [parentCategories, setParentCategories] = useState([]);
    const [treeData, setTreeData] = useState([]);
    const [viewToggle, setViewToggle] = useState(false);    
    const [similarTree, setSimilarTree] = useState([]);

    async function getFeedByKeywords(keywords, filteredCategories) {        
        if (keywords) {
            apiInstance.get(`/feed/f/similar?keywords=${keywords}`,)
            .then(response => {
              if(response.data.status) {                                          
                const feedsSimilar = response.data.data;                            
                const similarCategories = filteredCategories?.filter(category =>
                    feedsSimilar?.map(feed => feed?.category_id === category.id)
                    );
                const visibleCategories = filteredCategories?.slice(0, 6)?.map(vc => vc.id);                
                const finalSimilarCategories = similarCategories?.filter(sc => !visibleCategories?.includes(sc.id));        
                const treeData = generateTreeData(finalSimilarCategories);                  
                setSimilarTree(treeData);            
              } else {                    
                console.log(response.data?.data||response?.status);            
              }
            })
            .catch(e => {        
              console.log(e);          
            });  
        }
      } 


    useEffect(() => {
        if(!data) {
            // categories
            const newParentCategories = categories.filter(c => (c.parent_id === null) && (parseInt(c.is_active) === 1));
            setParentCategories(newParentCategories);
        } else {
            // subcategories
            setParentCategories(data?.filter(item => (parseInt(item?.is_active||0) === 1))|| []);
        }
    },[categories, data]);

    useEffect(() => {        

        const filteredCategories = categories.filter(category => category?.is_active === 1);
        const treeData = generateTreeData(filteredCategories);                  
        setTreeData(treeData);        

    },[categories]);  

    useEffect(() => {
        // const currentFeedKeywords = currentFeed?.keywords?.split(','); 
        const filteredCategories = categories.filter(category => category?.is_active === 1);       
        getFeedByKeywords(currentFeed?.keywords, filteredCategories);
    },[currentFeed, categories]);

    /**
     * Used to get the current selected id 
     * of a category and into the state
     * @param {id} id 
     */
    const onSelect = (id)  => {    
        if (id && id!==undefined && id?.length) {
            let newSelectedCategories = new Set(userSelectedCategories);
        newSelectedCategories.add(id[0]);
        if(boxTitle !== 'Similar Articles') {
            dispatch({
                type: 'SET_USER_CATEGORY',
                category: id[0]
            });
            dispatch({
                type: 'SET_USER_CATEGORIES',
                categories: newSelectedCategories
            });
        }
        let item = id[0];
        let categoryTitle = categories.filter(category => category.id === item)[0]?.title;
        history.push(`/article/${categoryTitle}`);
        }
    };

    const handleExpand = (id, flag) => {               
        let newSelectedCategories = new Set(userSelectedCategories);
        if (flag.expanded) {
            newSelectedCategories.add(flag?.node?.id); 
        } else {
            newSelectedCategories.delete(flag?.node?.id); 
        }
        if(boxTitle !== 'Similar Articles') {            
            dispatch({
                type: 'SET_USER_CATEGORIES',
                categories: newSelectedCategories
            });
        }
    }    

    return (
        <div className="w-full flex flex-col items-center p-6 bg-white border shadow lg:mt-5">
            <h5 className="text-lg font-bold text-left w-full font-bold mb-3 primary-color"
            >{boxTitle}                
            </h5>   
            <div className={`w-full flex flex-col items-center ${viewToggle?'overflow-x-hidden overflow-y-auto categorySlide':''}`}>
                {
                    !!parentCategories.length ? (
                        (boxTitle === 'Similar Articles') ? (
                            (!!similarTree.length) && (
                                <Tree                                                                  
                                    className="w-full py-2 overflow-none"                                              
                                    style={{ fontSize: 15 }}
                                    draggable={false}
                                    blockNode                                
                                    onSelect={onSelect}                                
                                    treeData={similarTree.slice(0,5)}
                                    height={400}
                                />
                            )
                        ) : (
                            <Tree                                  
                                selectedKeys={[currentSelectedCategory]}
                                className="w-full py-2 overflow-none"                                              
                                style={{ fontSize: 15 }}
                                draggable={false}
                                blockNode                                
                                onExpand={handleExpand}
                                expandedKeys={userSelectedCategories}
                                onSelect={onSelect}                                
                                treeData={treeData.slice(0, viewToggle?parentCategories.length:5)}
                                height={400}
                            />
                        )
                    )                        
                    : (
                        <>
                            <Skeleton loading={true} active className="border-b mb-4">
                                <h4>Assistace.org Sample Title</h4>                                    
                            </Skeleton>                            
                        </>
                    )                    
                }                  
            </div>
            {
                (boxTitle === 'Articles'||boxTitle==='Articles') && !viewToggle && parentCategories.length > 5 && (
                    <p                                      
                        onClick={() => setViewToggle(!viewToggle)}          
                        className="primary-color cursor-pointer text-center w-full mt-4 font-semibold"
                    >View More               
                    </p>
                )
            }
        </div>
    )
}

export default CategorySlide
