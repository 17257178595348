import React, { useEffect, useState, useRef } from 'react'
import Header from '../components/User/Header'
import '../stylesheets/SearchOrganization.css'
import orgLogo from '../assets/img/buildingLogo.svg'
import { Input, Select } from 'antd';
import { validateURL } from '../utils/Helper';
import apiInstance from '../api/';
import { message, Pagination } from 'antd';
import SearchResultItem from '../components/User/SearchResultItem';
import { useDataLayerValue } from '../DataLayer';
import Footer from '../components/User/Footer';

const { Option } = Select;

function SearchOrganization() {

    const [{ categories }] = useDataLayerValue();

    const [availableCountries, setAvailableCountries] = useState([]);
    const [availableStates, setAvailableStates] = useState([]);
    const [avaialbleCities, setAvailableCities] = useState([]);

    const [selectedCity, setSelectedCity] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [name, setName] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');

    const [result, setResult] = useState([]);
    const [preload, setPreload] = useState(false);
    const [page, setPage] = useState(1);
    const [found, setFound] = useState(0);
    const [roll, setRoll] = useState(1);

    const [load, setLoad] = useState(false);

    const catergoryOption = useRef();

    useEffect(() => {
        document.title = "Search for Organizations - Assistance";
        sessionStorage.setItem('assistance_current_registration_helper_flag', null);
    },[]);

    useEffect(() => {
        async function getAvailableLocations() {
            apiInstance
                .get("/organization/locations")
                .then(response =>{
                    setAvailableCountries(Array.from(new Set(response.data.data?.map(item => item.country?.toUpperCase()))));
                    setAvailableStates(Array.from(new Set(response.data.data?.map(item => item.state?.toUpperCase()))));
                    setAvailableCities(Array.from(new Set(response.data.data?.map(item => item.city?.toUpperCase()))))
                }).catch(e => console.log(e));
        }
        getAvailableLocations();
    },[]);

    async function searchOrgs(category='') {
        setLoad(true);
        setPreload(false);
        apiInstance
        .get(`/organization/search?name=${name?.trim()}&category=${category || selectedCategory}&city=${selectedCity?.trim() || ''}&state=${selectedState?.trim() || ''}&country=${selectedCountry?.trim() || ''}&zip=${zipCode || ''}&page=${page}`)
        .then(response => {
            setLoad(false);
            setPreload(true);
            setFound(response.data.data.found);
            setResult(response.data.data.data);
        }).catch(err => {
            setLoad(false);
            setPreload(true);
            console.log(err)
        });
    }

    const handleReset = () => {
        setResult([]);
        setSelectedState('');
        setSelectedCountry('');
        setSelectedCategory('');
        setZipCode('');
        setName('');
        setSelectedCity('');
        setLoad(false);
        setPreload(false);
    }

    const handleSearch = (e) => {
        e.preventDefault();
        if (name || selectedCategory || selectedCity || selectedState || selectedCountry || zipCode) {
            searchOrgs();
        } else message.warning("Search Input cannot be empty");
    }

    useEffect(() => {
        if (selectedCity || selectedState || selectedCountry) {
            searchOrgs();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    const handleSearchEvent = (e) => {
        if (e.key === 'Enter' && (selectedCountry || selectedCity || selectedState || selectedCategory || name)) {
            searchOrgs();
        }
    }

    const handleOptionScrolling = (direction) => {
        let newRolls = roll;
        if (direction === 'left') {
            catergoryOption.current.scrollTo({left:catergoryOption?.current?.offsetLeft+(300*newRolls), right: 0, behavior:'smooth'});
        } else if (direction === 'right') {
            catergoryOption.current.scrollTo({left:catergoryOption?.current?.offsetLeft-(300*newRolls), right: 0, behavior:'smooth'});
        }
        newRolls++;
        setRoll(newRolls);
    }

    return (
        <div className="mb-8">
            <Header />
            <div className="w-full flex flex-col items-center primary-bg pb-2">
                <div className="w-full px-4 lg:mt-4 flex flex-col lg:flex-row items-center justify-between px-4 lg:px-24">
                    <div className="flex flex-row items-center lg:py-0 py-2">
                        <img
                            alt="organization"
                            className="h-12 contain"
                            src={orgLogo}
                        />
                        <h1 className="font-extrabold text-lg lg:text-2xl text-center lg:text-left mx-3 text-white">Search Organizations</h1>
                    </div>
                    {/* <button                                                       
                        className="mx-4 font-bold rounded-lg text-sm lg:text-lg block sec-btn text-center
                                    py-2 px-12 cursor-pointer focus:outline-none"
                    >Submit an Organization            
                    </button>   */}
                </div> 
                <div className="w-auto flex lg:flex-row flex-col items-center justify-between mt-2">
                    <Input
                        onKeyPress={e => handleSearchEvent(e)}
                        type="text"
                        style={{ width: 300 }}
                        size="large"
                        className="mx-4 lg:my-0 my-2"
                        onChange={e => setName(e.target.value)}
                        placeholder="Name"
                        value={name}
                    />
                    <Select
                        value={selectedCategory?selectedCategory:null}
                        onKeyPress={e => handleSearchEvent(e)}
                        allowClear
                        className="mx-4"
                        size="large"
                        showSearch
                        style={{ width: 250 }}
                        placeholder="Select Category"
                        optionFilterProp="children"
                        onChange={e => setSelectedCategory(e)}
                        filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {
                            categories?.map((category, idx) => (
                                <Option key={idx} value={category.title}>{category.title}</Option>
                            ))
                        }
                    </Select>
                </div>
                <div className="w-auto flex lg:flex-row flex-col items-center justify-between mt-4">
                    <Select
                        value={selectedCity?selectedCity:null}
                        onKeyPress={e => handleSearchEvent(e)}
                        allowClear
                        className="mx-4"
                        size="large"
                        showSearch
                        style={{ width: 250, marginBottom: 10 }}
                        placeholder="City"
                        optionFilterProp="children"
                        onChange={e => setSelectedCity(e)}
                        filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {
                            avaialbleCities?.map((city, idx) => (
                                <Option key={idx} value={city}>{city}</Option>
                            ))
                        }
                    </Select>
                    <Select
                        value={selectedState?selectedState:null}
                        onKeyPress={e => handleSearchEvent(e)}
                        allowClear
                        className="mx-4"
                        size="large"
                        showSearch
                        style={{ width: 250, marginBottom: 10 }}
                        placeholder="State"
                        optionFilterProp="children"
                        onChange={e => setSelectedState(e)}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                    >
                        {
                            availableStates?.map((states, idx) => (
                                <Option key={idx} value={states}>{states}</Option>
                            ))
                        }
                    </Select>
                    <Select
                        value={selectedCountry?selectedCountry:null}
                        onKeyPress={e => handleSearchEvent(e)}
                        allowClear
                        className="mx-4"
                        size="large"
                        showSearch
                        style={{ width: 250, marginBottom: 10 }}
                        placeholder="Country"
                        optionFilterProp="children"
                        onChange={e => setSelectedCountry(e)}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                    >
                        {
                            availableCountries?.map((country, idx) => (
                                <Option key={idx} value={country}>{country}</Option>
                            ))
                        }
                    </Select>
                    <Input
                        onKeyPress={e => handleSearchEvent(e)}
                        type="number"
                        style={{ width: 200, marginBottom: 10 }}
                        size="large"
                        className="mx-4"
                        onChange={e => setZipCode(e.target.value)}
                        placeholder="Zip Code"
                    />
                    <button  
                        style={{ marginBottom: 10 }} 
                        onClick={(e) => handleSearch(e)}                                                        
                        className="mx-4 font-bold rounded-lg text-lg block sec-btn text-center
                                    py-2 px-12 cursor-pointer focus:outline-none"
                    >{!load?'Search':'Searching'}                   
                    </button>  
                    <button  
                        style={{ marginBottom: 10 }} 
                        onClick={() => handleReset()}                                                        
                        className="mx-4 font-bold rounded-lg text-lg block bg-red-600 text-white text-center hover:bg-red-500
                                    py-2 px-12 cursor-pointer focus:outline-none"
                    >Reset              
                    </button>  
                </div>
            </div>
            {
                result.length === 0 && (
                    <div className="mx-auto w-full lg:w-10/12 flex flex-row items-center justify-center lg:px-0 px-3">
                        <div
                            onClick={() => handleOptionScrolling('right')}
                            className="border rounded-sm bg-white px-4 py-6 border-gray-600 hidden lg:flex items-center justify-center cursor-pointer hover:bg-gray-100">
                            <p className="text-black font-bold text-lg">&#60;</p>
                        </div>
                        <div
                            ref={catergoryOption} 
                            className="flex flex-row items-center overflow-x-auto py-4 px-3 showCategories__org" style={{ whiteSpace: 'nowrap' }}>
                            {
                                categories?.map((item, idx) => (
                                    <p
                                        onClick={() => {
                                            setSelectedCategory(item?.title);
                                            searchOrgs(item?.title);
                                        }}
                                        key={idx} 
                                        className="mx-2 primary-bg text-white text-sm lg:text-lg rounded-md px-2 lg:px-10 py-2 lg:py-6 cursor-pointer hover:bg-gray-700">
                                        {item?.title}
                                    </p>
                                ))
                            }
                        </div>
                        <div
                            onClick={() => handleOptionScrolling('left')}
                            className="border rounded-sm bg-white px-4 py-6 border-gray-600 hidden lg:flex items-center justify-center cursor-pointer hover:bg-gray-100">
                            <p className="text-black font-bold text-lg">></p>
                        </div>
                    </div>
                )
            }
            <div className={`w-full ${result.length ? 'flex' : 'hidden'} items-center lg:justify-start justify-center py-2 border-b border-gray-400 mb-3 px-8 lg:px-20`}>
                        <h3 className="text-sm lg:text-md text-left text-gray-700 py-2 mr-3"
                        >{found} {(found > 1) ?'Results': 'Result'}  Found              
                        </h3> 
            </div>
            {
                (!result.length && preload) ?
                (
                    <h3 className="px-20 text-xl text-center w-full text-gray-600 py-10"
                    >No Organizations Found
                    </h3>
                ) : null
            }
            <div className="py-10 px-2 w-full lg:w-1/2 mx-auto relative flex flex-col items-center mb-4" style={{minHeight: '400px'}}>
                {
                    result?.map((item, idx) => (
                        <SearchResultItem
                            map={true}
                            id={item?.id}
                            title={item?.name}
                            category={categories?.filter(category => category.id === item?.orgtype)[0]?.title || 'Category'}
                            description={item?.description || 'description'}
                            image={validateURL(item?.logo) ? item?.logo : orgLogo}
                            url={`/organization/${item?.url_key}`}
                            mapData={item?.city?item:null}
                        />  
                    ))
                }
                 {
                            found > 10 && (
                                <Pagination 
                                    className="mt-2"
                                    current={page} 
                                    onChange={(e) => setPage(e)} 
                                    total={found} 
                                /> 
                            )
                        }     
            </div>
            <Footer />
        </div>
    )
}

export default SearchOrganization
