import React, { useEffect, useState } from 'react'
import Header from '../components/User/Header'
import { useParams, useHistory } from 'react-router-dom'
import { useDataLayerValue } from '../DataLayer'
import { Skeleton } from 'antd'
import { checkAvailability } from '../utils/Helper'
import ListMenu from '../components/User/ListMenu'
import CategoryHeader from '../components/User/CategoryHeader'
import CategorySlide from '../components/User/CategorySlide'
import SearchBar from '../components/User/SearchBar'
import Footer from '../components/User/Footer'
import apiInstance from '../api'

function Category() {
    let { id } = useParams();    
    let history = useHistory();

    const [{ categories }] = useDataLayerValue();

    const [sCategory, setsCategory] = useState(null);
    const [subCategories, setSubCategories] = useState([]);
    const [feedsFromCategory, setFeedsFromCategory] = useState([]);
    const [categoryLoad, setCategoryLoad] = useState(false);

    async function getFeedByCategory() {
        setCategoryLoad(true);
        if (sCategory?.id) {
            apiInstance.get(`/feed/category/${sCategory?.id}`)
            .then(response => {
              if(response.data.status) {                              
                setCategoryLoad(false);
                const newFilteredFeeds = response.data.data;                
                // sorting due to updated at descending order
                const sortedFeeds = newFilteredFeeds.sort((itemA, itemB) => {
                    return new Date(itemB.modified_at) - new Date(itemA.modified_at);
                });
                setFeedsFromCategory(sortedFeeds);                
              } else {
                setCategoryLoad(false);
                // window.location.replace('/fallback');
                console.log(response.data?.data||response?.status);            
              }
            })
            .catch(e => {
            setCategoryLoad(false);
              console.log(e);              
            });  
        }        
      } 

    useEffect(() => {

        let filterData = categories.filter(c => c.title === id);
        
        const newSelectedCategory = filterData.length ? filterData[0] : null;
        setsCategory(newSelectedCategory); 
        
        document.title = `${newSelectedCategory?.title} - Assistance`;

        const newSubCategories = categories.filter(c => c.parent_id === parseInt(id));
        setSubCategories(newSubCategories);   
        
        getFeedByCategory();

        // const newFeedsFromCategory = feeds.filter(f => f.category_id === categories.filter(item => item.title === id)[0]?.id);
        // setFeedsFromCategory(newFeedsFromCategory);

        if (newSelectedCategory && !feedsFromCategory.length && newSubCategories.length) {
            history.push(`/article/${newSubCategories[0]?.title}`);
        }
        
  // eslint-disable-next-line react-hooks/exhaustive-deps
    },[categories, sCategory, id]);

    return (
        <div className="w-full h-full bg-white">            
            <Header />
            <CategoryHeader 
                id={categories.filter(item => item.title === id)[0]?.id}
                title={id}
                image={sCategory?.image||categories.filter(c => c?.id === sCategory?.parent_id && c?.is_active === 1)[0]?.image || null}  
            />  
            <div className="w-full flex lg:flex-row flex-col items-start justify-between lg:px-16">
               {
                   categoryLoad ?
                   (
                        <Skeleton loading={true} active className="border-b mb-4">
                            <h1>Assistace.org Sample Title</h1>                                    
                        </Skeleton>  
                   ) : (
                    <div className="w-full px-4 mt-3 mb-8 grid grid-cols-1 lg:grid-cols-2 gap-6 py-5">
                    {
                         feedsFromCategory?.length ? (feedsFromCategory?.filter(item => (parseInt(item?.is_active||0) === 1) && (parseInt(item?.approval||0) === 1) && (checkAvailability(item?.active_from, item?.active_to))) || []).map(feed => (
                            <ListMenu
                                urlKey={feed.url_key}
                                id={feed.id}
                                key={feed.id}
                                title={feed.title}
                                image={feed.feed_type==='Internal Blog'?null:feed.image}
                                description={feed.description}
                                feedType={feed.feed_type}
                                url={feed.feed_type==='Internal Blog'?feed.url_key:feed.url}
                            />
                         ))  : <p className="m-3 text-lg text-gray-700 py-10">No Feeds Available</p>
                    }
                    </div>
                   )                   
               }
                <div className="lg:w-1/3 w-full flex-col items-center h-full lg:sticky lg:top-0 lg:flex md:flex px-4 lg:px-0 lg:mx-4 py-6">
                    <SearchBar 
                        placeholderText="What you need assistance for ?"                        
                    />                    
                    {
                    subCategories.length ?
                    (                        
                            <CategorySlide
                                boxTitle={'Sub Categories'}
                                data={subCategories}
                            />                     
                    ) : <CategorySlide />
                    }
                    <Footer
                        type="small"
                    />
                </div>                
            </div>
            {/* {
                subCategories.length ? 
                (<div className="w-full flex items-center justify-center py-6">
                    <SubMenus
                        title="Sub Categories"
                        data={subCategories}
                    />
                </div>) : null
            }
            {
                feedsFromCategory.length ? (
                    <div className="w-full flex items-center justify-center py-6">
                        <ListMenu 
                            title="Feeds"
                            data={feedsFromCategory}
                        />
                    </div>
                )
                : null
            } */}
        </div>
    )
}

export default Category
