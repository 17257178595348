import React, { useState, useEffect } from 'react'
import { Button, Col, Input, Row, message, Spin } from 'antd'
import apiInstance from '../../api'
import { authorizeCheck } from '../../utils/Helper'
import { useDataLayerValue } from '../../DataLayer'

function SiteSettings() {
    const [email, setEmail] = useState('');
    const [load, setLoad] = useState(false);
    const [spin, setSpin] = useState(true);

    const [{ permissions }] = useDataLayerValue();

    useEffect(() => {
        setSpin(true);
        apiInstance
            .get("/sitesettings")
                .then(response => {
                    setSpin(false);
                    if(response.data.status) {
                        setEmail(response.data.data.filter(item => item.title === 'contact-email')[0].value);
                    } else message.warning('Something went wrong');
                })
                .catch(e => {
                    setSpin(false);
                    if(e.response || e.response.data) {                
                        message.error(e.response.data.message);
                        if(e.response.status === 401) {
                            sessionStorage.clear();
                            window.location.replace("/admin");
                        }
                    } else message.error("Internal Server Error");
                });
                
    },[]);

    const saveEmail = () => {
        let payload = {
            title: 'contact-email',
            value: email
        };        
        setLoad(true);
        apiInstance.put(`/sitesettings/contact/`, payload)
                        .then(response => {                            
                            message.success('Site Settings Updated');
                            setLoad(false);
                        })
                        .catch(e => {
                            setLoad(false);
                            if(e.response || e.response.data) {                
                                message.error(e.response.data.message);
                                if(e.response.status === 401) {
                                    sessionStorage.clear();
                                    window.location.replace("/admin");
                                }
                            } else message.error("Internal Server Error");
                        });
    }

    return (
        <div className="w-full h-full flex flex-col items-center px-4">
            <h1
                className="w-full text-left text-lg font-semibold mb-4"
            >Site Settings
            </h1> 
            <Row className="w-full">                
                    <Col span={8} className="w-full">
                        <Spin spinning={spin}>
                        <p
                            className="py-2 mb-6 border-b text-md font-medium"
                        >Contact Email Address
                        </p>
                        <Input                                                   
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            type="email"
                            required
                            placeholder="Email Address"
                            className="mb-3"
                        /><br/>
                        {
                            authorizeCheck(permissions, 'sitesettings:update') && (
                                <Button
                                    onClick={() => saveEmail()}
                                    loading={load}
                                    type="primary"                        
                                >Save                    
                                </Button>
                            )
                        }                        
                        </Spin>
                    </Col>                
            </Row>            
        </div>
    )
}

export default SiteSettings
