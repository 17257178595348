export const initialState = {
    currentSelectedCategory: null,
    userSelectedCategories: [],
    searchTrigger: false,
    categories: [],    
    categoriesStatus: false,
    feeds: [],
    feedsStatus: false,
    selectedCategory: null,
    allRoles: [],
    allRolesStatus: false,
    allPermissions: [],
    allPermissionsStatus: false,
    allKeywords: [],
    allKeywordsStatus: false,
    allFeedTypes: [],
    allFeedTypesStatus: false,
    allAddressTypes: [],
    allAddressTypesStatus: false,
    user: sessionStorage.getItem('assistance_user') ? JSON.parse(sessionStorage.getItem('assistance_user')) || null : null,    
    token: sessionStorage.getItem('assistance_token') || null,    
    permissions: sessionStorage.getItem('assistance_permissions') || []    
}

const reducer = (state, action) => {
    // console.log(action);
    switch(action.type) {  
        case 'SET_USER_CATEGORY':
            return {
                ...state,
                currentSelectedCategory: action.category
            }
        case 'SET_USER_CATEGORIES':
            return {
                ...state,
                userSelectedCategories: action.categories ? Array.from(action.categories) : []
            }
        case 'LOG_OUT':
            return {
                ...state,
                user: null,
                token: null,
                permissions: []
            }
        case 'SET_CATEGORIES':
            return {
                ...state,
                categories: action.categories
            }            
        case 'SET_CATEGORIES_STATUS':
            return {
                ...state,
                categoriesStatus: action.status
            }            
        case 'UPDATE_SELECTED_CATEGORY':            
            return { 
                ...state,
                selectedCategory: action.item 
            }
        case 'UPDATE_USER':
            return {
                ...state,
                user: JSON.parse(sessionStorage.getItem('assistance_user'))
            }            
        case 'SET_ALL_ROLES': 
            return { 
                ...state,
                allRoles: [...action.roles]
            }            
        case 'SET_ALL_ROLES_STATUS':
            return {
                ...state,
                allRolesStatus: action.status
            }            
        case 'SET_ALL_PERMISSIONS': 
            return { 
                ...state,
                allPermissions: [...action.permissions]
            }            
        case 'SET_ALL_PERMISSIONS_STATUS':
            return {
                ...state,
                allPermissionsStatus: action.status
            }            
        case 'SET_ALL_KEYWORDS': 
            return { 
                ...state,
                allKeywords: [...action.keywords]
            }            
        case 'SET_ALL_KEYWORDS_STATUS':
            return {
                ...state,
                allKeywordsStatus: action.status
            }            
        case 'SET_ALL_FEED_TYPES': 
            return { 
                ...state,
                allFeedTypes: [...action.feedTypes]
            }            
        case 'SET_ALL_FEED_TYPES_STATUS':
            return {
                ...state,
                allFeedTypesStatus: action.status
            } 
        case 'SET_ALL_ADDRESS_TYPES': 
            return { 
                ...state,
                allAddressTypes: [...action.addressTypes]
            }            
        case 'SET_ALL_ADDRESS_TYPES_STATUS':
            return {
                ...state,
                allAddressTypesStatus: action.status
            }            
        case 'SET_FEEDS': 
            return { 
                ...state,
                feeds: action.feeds
            }            
        case 'SET_FEEDS_STATUS':
            return {
                ...state,
                feedsStatus: action.status
            }            
        case 'SET_SEARCH_TRIGGER':
                return {
                    ...state,
                    searchTrigger: action.status
                }                
        default:
            return state;
    }
}

export default reducer;