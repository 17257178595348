import React from 'react'
import '../../stylesheets/EditorJSRenderer.css'

function EditorJSRenderer({ data, image=null }) {  
    
    const splitATag = (data) => {
        let doc = new DOMParser().parseFromString(data, "text/html");
        let aTag = doc.getElementsByTagName('a')[0];
        return {
            url: aTag?.href,
            text: aTag?.innerHTML
        }
    }
    const headingRender = (content, i) => {    
        return <h1 key={i} className={`${content.data.level>3? 'text-2xl' : 'text-3xl'} text-left font-bold py-2 w-full`} dangerouslySetInnerHTML={{ __html: content.data.text }}></h1>
    }
    
    const paragraphRender = (content, i) => {
        return <p dangerouslySetInnerHTML={{__html: content.data.text }} key={i} className="lg:w-full text-lg text-justify py-1"></p>
    }
    
    const delimiterRender = (i) => {
        return <div key={i} className="py-6"><hr className="opacity-75"/></div>
    }
    
    // const imageRender = (content, i) => {
    //     const imageSource = externalURLFitIn(content.data.file.url);
    //     // const imageSource = content.data.file.url;
    //     return <div key={i} className="flex flex-col items-center py-1 px-3"> 
    //                 <img src={imageSource} className={`${content.data.stretched?'w-full h-full':'h-auto w-auto'} ${content.data.withBorder?'border':'border-none'}`}/>
    //                 {content.data.caption?<p className="text-xs font-bold text-gray-800 text-center py-1" dangerouslySetInnerHTML={{__html:content.data.caption}}></p>: ''}
    //             </div>
    // }
    
    const listTypeRender = (type, content) => {
        if(type === 'unordered'){
            return <ul className="lg:w-full list-disc">
                    {
                        content.map((i,j) => { 
                            if (splitATag(i)?.text) {
                                return <li key={j} className="text-md lg:text-lg editorjs__title py-1">
                                    <a target="_blank" rel="noopener noreferrer" href={`${window.location.protocol}//${process.env.REACT_APP_CLIENT_URI}/url?a=${splitATag(i)?.url || null}`}>{splitATag(i)?.text}</a>
                                </li>
                            } else return <li key={j} className="text-md lg:text-lg truncate py-1" dangerouslySetInnerHTML={{__html: i}}></li>                                                                           
                        })
                    }
                </ul>            
        } else if(type === 'ordered') {
            return <ul className="lg:w-full list-decimal">
                    {
                        content.map((i,j) => {
                            if (splitATag(i)?.text) {
                                return <li key={j} className="text-md lg:text-lg editorjs__title py-1" >
                                    <a target="_blank" rel="noopener noreferrer" href={`${window.location.protocol}//${process.env.REACT_APP_CLIENT_URI}/url?a=${splitATag(i)?.url || null}`}>{splitATag(i)?.text}</a>
                                </li>
                            } else return <li key={j} className="text-md lg:text-lg truncate py-1" dangerouslySetInnerHTML={{__html: i}}></li>                                                                           
                        })
                    }
                </ul>            
        }
    }
    
    const listRender = (content, i) => {
        return <div key={i} className="lg:px-8 lg:w-full h-auto flex items-center py-2">
                    {
                        listTypeRender(content.style, content.items)
                    }
               </div>
    }
    
    const qouteRender = (content, i) => {
        return <div key={i} className="lg:w-full h-auto py-4 flex flex-col items-center">
                    <blockquote className={`font-bold italic text-lg text-'${content.alignment}' mx-2`} dangerouslySetInnerHTML={{__html: content.text}}>                
                    </blockquote>
                    {content.caption!==''?<p className="text-sm text-center" dangerouslySetInnerHTML={{__html: content.caption}}></p>:null}
               </div>
    }            

    return (
        <div className="editorjsRenderer lg:w-full h-full">
            <div className="lg:w-auto flex flex-col items-start lg:items-center py-1 my-12 mb-3 px-3"> 
                    <img 
                        alt="blog" 
                        src={`${process.env.REACT_APP_SERVER_URL}${image}`} 
                        className={'lg:w-4/5 object-contain'} 
                        style={{
                            maxHeight: 500
                        }}
                    />
                    {/* {content.data.caption?<p className="text-xs font-bold text-gray-800 text-center py-1" dangerouslySetInnerHTML={{__html:content.data.caption}}></p>: ''} */}
            </div>
            <div>
                {
                    // eslint-disable-next-line
                    data.map((content, index) => {
                        if(content.type === 'header') {
                            content.data.text = content.data.text.replace('amp;','');
                            return headingRender(content, index)
                        }
                        if(content.type === 'paragraph') {
                            content.data.text = content.data.text.replace('amp;','');
                            return paragraphRender(content, index)
                        }
                        if(content.type === 'delimiter') {
                            return delimiterRender(index)                            
                        }                        
                        if(content.type === 'list') {
                            return listRender(content.data, index)
                        }
                        if(content.type === 'quote') {
                            return qouteRender(content.data, index)
                        }                        
                    })
                }
            </div>               
                <hr/>
            </div>
    )
}

export default EditorJSRenderer
