import React,{ useState } from 'react'

function ReplyComment({ level=1, parentId, addComment = a => a, load }) {

    const [commentReply, setCommentReply] = useState('');
    const commentLimit = 500;

    const handleCommentReply = (e) => {
        e.preventDefault();        
        setCommentReply(e.target.value)
    }    

    return (
        <div key={level+parentId+load}>
        <div
            key={parentId+level+'0'} 
            className="w-full flex flex-row items-start">
            <textarea          
                key={'editor1'}                              
                maxLength={commentLimit}      
                onChange={e => handleCommentReply(e)}    
                value={commentReply}                 
                rows="2"                                     
                style={{ resize: "none" ,overflow: "auto" }}                                               
                placeholder="Add your reply comment here"
                className="w-full border p-2 border-gray-500 rounded-lg mr-2 focus:outline-none"
            ></textarea>
            <button   
                key={parentId+level+'editor-button'} 
                onClick={() => addComment(parentId, level, commentReply)}                                                                                                                                                                     
                className="text-white rounded-lg text-md block primary-bg text-center
                px-4 py-2 cursor-pointer mb-4 focus:outline-none"
            >{load ? 'Please Wait':'Add'}                
            </button>
        </div>
        <p 
            key={parentId+level+'editor-label'} 
            className="text-gray-600 py-1 text-right text-md mb-2">{`${commentLimit - commentReply.length} characters left`}</p>                                                                              
        </div>
    )
}

export default ReplyComment
