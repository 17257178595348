import React,{ useState } from 'react'
import CrudUI from './CrudUI'
import apiInstance from '../../../api'
import { useDataLayerValue } from '../../../DataLayer'
import { message } from 'antd'

function Roles() {

    const [{ allRoles, allRolesStatus }, dispatch] = useDataLayerValue();  

    const [load, setLoad] = useState(false);
    const [addModalTrigger, setAddModalTrigger] = useState(false);
    const [updateModalTrigger, setUpdateModalTrigger] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);    

    /**
     * Used to create a role using the 
     * standard API
     * @param {*} data 
     */
    const createARole = (data) => {        
        setLoad(true);
        apiInstance.post("/role", data)
        .then(response => {
            if(response.data.status) {
                setLoad(false);
                message.success(response.data.data);
                setAddModalTrigger(false);
                setCurrentItem(null);
                // UPDATE THE DATA LAYER WITH NEW ROLES ADDED
                dispatch({
                    type: 'SET_ALL_ROLES_STATUS',
                    status: !allRolesStatus
                });
            } else message.warning(response.data.data);  
            setLoad(false);          
        })
        .catch(e => {
            setLoad(false);
            console.log(e);
            if(e.response || e.response?.data) {                                    
                if(e.response.status === 401) {
                    alert('Your Session is expired!');
                    sessionStorage.clear();                            
                    window.location.replace("/signin");                                     
                } else {
                    if(typeof e.response?.data?.message === 'string') {
                        message.error(e.response.data.message);
                    } else {
                        message.warn("Something went wrong");
                    }                        
                }
            } else message.error("Internal Server Error");  
        });        
    }

    /**
     * Used to update a role using the
     * standard API
     * @param {id} id
     * @param {*} data 
     */
    const updateARole = (id, data) => {        
        setLoad(true);
        apiInstance.put(`/role/${id}`, data)
        .then(response => {
            if(response.data.status) {
                setLoad(false);
                message.success(response.data.data);
                setUpdateModalTrigger(false);
                setCurrentItem(null);
                // UPDATE THE DATA LAYER WITH NEW ROLES ADDED
                dispatch({
                    type: 'SET_ALL_ROLES_STATUS',
                    status: !allRolesStatus                    
                });
            } else message.warning(response.data.data);
            setLoad(false);            
        })
        .catch(e => {
            setLoad(false);
            console.log(e);
            if(e.response || e.response?.data) {                                    
                if(e.response.status === 401) {
                    alert('Your Session is expired!');
                    sessionStorage.clear();                            
                    window.location.replace("/signin");                                     
                } else {
                    if(typeof e.response?.data?.message === 'string') {
                        message.error(e.response.data.message);  
                    } else {
                        message.warn("Something went wrong");
                    }                        
                }
            } else message.error("Internal Server Error");  
        });        
    }

    /**
     * Used to soft delete a role using the
     * standard API
     * @param {*} id 
     */
    const deleteARole = (id) => {        
        setLoad(true);
        apiInstance.put(`/role/delete/${id}`)
        .then(response => {
            if(response.data.status) {
                message.success(response.data.data);
                setLoad(false);                
                setCurrentItem(null);
                // UPDATE THE DATA LAYER WITH NEW ROLES ADDED
                dispatch({
                    type: 'SET_ALL_ROLES_STATUS',
                    status: !allRolesStatus                    
                });            
                return true;    
            } else message.warning(response.data.data);
            setLoad(false);
        })
        .catch(e => {            
            setLoad(false);
            console.log(e);
            if(e.response || e.response?.data) {                                    
                if(e.response.status === 401) {
                    alert('Your Session is expired!');
                    sessionStorage.clear();                            
                    window.location.replace("/signin");                                     
                } else {
                    if(typeof e.response?.data?.message === 'string') {
                        message.error(e.response.data.message);
                    } else {
                        message.warn("Something went wrong");
                    }                        
                }
            } else message.error("Internal Server Error"); 
            return false;
        });        
    }

    /**
     * Used to change the current status of
     * a role based on provided id
     * @param {*} id 
     */
    const changeStatusOfARole = (data, id) => {        
        setLoad(true);
        apiInstance.put(`/role/status/${id}`, data)
        .then(response => {
            if(response.data.status) {
                setLoad(false);
                message.success(response.data.data);                
                setCurrentItem(null);
                // UPDATE THE DATA LAYER WITH NEW ROLES ADDED
                dispatch({
                    type: 'SET_ALL_ROLES_STATUS',
                    status: !allRolesStatus                    
                });
            } else message.warning(response.data.data);  
            setLoad(false);          
        })
        .catch(e => {
            setLoad(false);
            console.log(e);
            if(e.response || e.response?.data) {                                    
                if(e.response.status === 401) {
                    alert('Your Session is expired!');
                    sessionStorage.clear();                            
                    window.location.replace("/signin");                                     
                } else {
                    if(typeof e.response?.data?.message === 'string') {
                        message.error(e.response.data.message);
                    } else {
                        message.warn("Something went wrong");
                    }                        
                }
            } else message.error("Internal Server Error");  
        });        
    }
    
    return (
        <div className="w-full">
            <CrudUI
                title="Role"
                utility="Roles"
                data={allRoles}
                addModalTrigger={addModalTrigger}
                updateModalTrigger={updateModalTrigger} 
                load={load}                 
                setAddModalTrigger={setAddModalTrigger}
                setUpdateModalTrigger={setUpdateModalTrigger}
                createItem={createARole}
                updateItem={updateARole}
                deleteItem={deleteARole}
                changeStatus={changeStatusOfARole}
                currentItem={currentItem}
                setCurrentItem={setCurrentItem}
            />            
        </div>
    )
}

export default Roles
