import React, { useEffect, useState } from 'react'
import apiInstance from '../../api'
import { Table } from 'antd'
import moment from 'moment'

function ServerLogs() {

    const [logs, setLogs] = useState([]);
    const [load, setLoad] = useState(true);

    useEffect(() => {
        async function getLogs() {
            setLoad(true);
            apiInstance.get('/errorlogs/')
                .then(response => {                    
                    setLoad(false);
                    setLogs(response.data.data);
                })
                .catch(e => {
                    setLoad(false);
                    console.log(e);
                });
        }
        getLogs();
    },[]);

    // Table Columns Defined
    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id'            
        },
        {
            title: 'Message',
            dataIndex: 'message',
            key: 'message',                        
        },
        {
            title: 'Route',
            dataIndex: 'route',
            key: 'id'            
        },        
        {
            title: 'Triggered',
            dataIndex: 'created_at',
            key: 'created_at',
            render: created_at => {
                return moment(created_at).format('lll');
            }
        }        
    ];

    return (
        <div className="w-full h-full flex flex-col items-center px-4">
            <h1
                className="w-full text-left text-lg font-semibold"
            >Logs
            </h1>
            <div className="w-full py-2 m-2">
                <Table      
                    pagination={{
                        pageSize: 6
                    }}               
                    loading={load}
                    className="w-full py-2"
                    dataSource={logs}
                    columns={columns}
                />
            </div>
        </div>
    )
}

export default ServerLogs
