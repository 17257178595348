import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useDataLayerValue } from '../../DataLayer'
import { DownOutlined } from '@ant-design/icons'
import logo from '../../assets/img/logo.png'
import icon from '../../assets/img/icon.png'
import { getProfileImage } from '../../utils/Helper'
import { Menu, Dropdown } from 'antd'

function Header() {    
    const [{ token, user }, dispatch] = useDataLayerValue();
    let location = useLocation();    

    const logOut = () => {             
        sessionStorage.clear();
        dispatch({
            type: 'LOG_OUT'
        });                
        window.location.reload();
    }    

    useEffect(() => {
        if (location?.pathname !== '/reset/password/' || location?.pathname !== '/reset-password' || location?.pathname !== '/signin' || location?.pathname !== '/get-started' || location?.pathname !== '/admin') {
            sessionStorage.setItem('assistance_current_route_helper', location.pathname||'/');   
        }
    },[location]);

    const mainMenu = (
        <Menu>
          {
              user?.role !== 'User' && (
                    <Menu.Item className="px-8">
                    <Link to="/dashboard">
                        Home
                    </Link>
                </Menu.Item> 
              )
          }       
          <Menu.Item className="px-8">          
            <Link 
                to={`/@${user?.username.replace(' ','-')}`}                                
                className="cursor-pointer"
            >Settings
            </Link>
          </Menu.Item>          
          <Menu.Item className="px-8">          
            <p                 
                onClick={logOut}
                className="cursor-pointer"
            >Log Out
            </p>
          </Menu.Item>          
        </Menu>
    );

    const isActive = (path) => path === location.pathname;

    return (
        <div className="w-full flex lg:flex-row flex-col items-center justify-between px-10 lg:px-24 py-3 bg-white shadow-sm border-b border-gray-300 sticky top-0 z-10">
            <div className="w-full flex flex-row items-start justify-between">
            <Link 
                to="/"
                className="hidden lg:flex items-center">
                <img   
                    alt="logo"                 
                    className="h-10 object-contain mx-1"
                    src={logo}
                />                
            </Link>
            <div className="lg:hidden flex justify-between">
                <Link 
                    to="/"
                    className="flex items-center">
                    <img   
                        alt="logo"                 
                        className="h-10 object-contain mx-1"
                        src={icon}
                    />       
                    <h1 className="lg:hidden text-xl font-bold text-center mx-2 primary-color py-1">assistance</h1>         
                </Link>
                {
                    !!token && (
                        <div className="flex items-center">                            
                            <Dropdown overlay={mainMenu}>                                
                                <p className="ant-dropdown-link cursor-pointer flex items-center" onClick={e => e.preventDefault()}>
                                    <img
                                        alt="profile"
                                        className="h-8 rounded-full mx-2"
                                        src={user?.image || getProfileImage(user?.username || 'Assistance')}
                                    /><DownOutlined />
                                </p>                            
                            </Dropdown>          
                        </div>
                    )
                }
            </div>            
            <div className="flex items-center">
                <Link
                    className={`hidden lg:block px-4 primary-color py-2 font-medium text-md cursor-pointer`}
                    to="/"
                >{isActive('/')?<mark>Home</mark>:'Home'}         
                </Link>
                <Link
                    className={`hidden lg:block px-4 primary-color py-2 font-medium text-md cursor-pointer`}
                    to="/search-blogs"
                >{isActive('/search-blogs')?<mark>Blogs</mark>:'Blogs'}         
                </Link>    
                <Link
                    className={`hidden lg:block px-4 primary-color py-2 font-medium text-md cursor-pointer`}
                    to="/search-organizations"
                >{isActive('/search-organizations')?<mark>Organization</mark>:'Organization'}         
                </Link>                                
                {
                    !token && (
                        <Link
                            to="/signin"
                            className="px-5 hover:text-gray-600 py-2 text-white primary-bg rounded-md cursor-pointer lg:mx-2"                    
                        >Sign In                    
                        </Link>
                    )
                }
                {
                    !!token && (
                        <div className="hidden lg:flex items-center">
                            <img
                                alt="profile"
                                className="h-8 rounded-full mx-2"
                                src={user?.image || getProfileImage(user?.username || 'Assistance')}
                            />
                            <Dropdown overlay={mainMenu}>
                                <p className="ant-dropdown-link cursor-pointer" onClick={e => e.preventDefault()}>
                                    {user?.username||'dev'} <DownOutlined />
                                </p>
                            </Dropdown>          
                        </div>
                    )
                }
            </div>            
            </div>
            <div className="lg:hidden py-1 flex items-center">
                <Link
                    className={`block px-4 primary-color py-2 font-medium text-md cursor-pointer`}
                    to="/search-blogs"
                >{isActive('/search-blogs')?<mark>Blogs</mark>:'Blogs'}         
                </Link>    
                <Link
                    className={`block px-4 primary-color py-2 font-medium text-md cursor-pointer`}
                    to="/search-organizations"
                >{isActive('/search-organizations')?<mark>Organization</mark>:'Organization'}         
                </Link>
            </div>
        </div>
    )
}

export default Header
