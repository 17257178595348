import React from 'react'
import { Row } from 'antd'
import Category from './Category'
import RSSFeed from './RSSFeed'

function Home() {   

    return (
        <div className="w-full h-full flex flex-col items-center px-4">
            <h1
                className="w-full text-left text-lg font-semibold mb-4"
            >Manage RSS Feed                
            </h1>
            <Row className="w-full h-full">                
                <div className="w-2/5 h-full flex flex-col">
                    <h1
                        className="w-full text-left text-md font-semibold mb-4"
                    >CATEGORY
                    </h1>
                    <Category />
                </div>   
                <div className="w-3/5 h-full flex flex-col px-4 border-l border-gray-400">
                    <h1
                        className="w-full text-left text-md font-semibold mb-4"
                    >Feeds
                    </h1>
                    <RSSFeed />
                </div>   
            </Row>           
        </div>
    )
}

export default Home
    