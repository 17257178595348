import React, { useState, useEffect, useRef } from 'react'
import { Modal, Button, TreeSelect, Breadcrumb, Input, Select, Row, 
            DatePicker, Steps, message, Divider, Table, Spin,
            Image, Tooltip, Col } from 'antd'
import { generateTreeData, constructBreadCrumb, validateURL } from '../../utils/Helper'
import moment from 'moment'
import { useDataLayerValue } from '../../DataLayer'
import '../../stylesheets/FeedModal.css'
import apiInstance from '../../api'
import assistanceIcon from '../../assets/img/icon.png'
// image upload utilities
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginFileEncode from 'filepond-plugin-file-encode'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginImageCrop from 'filepond-plugin-image-crop'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import '../../stylesheets/FilePond.css'
import '../../stylesheets/FilePondPreview.css'
// editor js utilities
// import EditorJs from 'react-editor-js'
import EditorJs from '@editorjs/editorjs'
import { EDITOR_JS_TOOLS } from './BlogTools'
const { Step } = Steps;
const { Option } = Select;

registerPlugin(FilePondPluginFileEncode, FilePondPluginImagePreview, FilePondPluginImageCrop, FilePondPluginFileValidateSize);

function FeedModal({ type, modalTitle, okLabel, trigger, callTrigger = ct => ct, data=null, load, progress=0 }) {
    const [currentSelectedCategory, setCurrentSelectedCategory] = useState(null);
    const [treeData, setTreeData] = useState([]);    
    const [url, setUrl] = useState('');
    const [id, setId] = useState(null);
    const [title, setTitle] = useState('');
    const [image, setImage] = useState(null);
    const [description, setDescription] = useState('');
    const [files, setFiles] = useState([]);    
    const [blogData, setBlogData] = useState([]);
    const [urlKey, setUrlKey] = useState(null);
    // eslint-disable-next-line
    const [errorText, setErrorText] = useState(null);
    const [keywords, setKeywords] = useState([]);
    const [activeFrom, setActiveFrom] = useState(moment());
    const [activeTo, setActiveTo] = useState(null);
    const [current, setCurrent] = useState(0);    
    const [feedType, setFeedType] = useState('RSS Feed');
    const [keywordStatus, setKeywordStatus] = useState(false);
    const [keywordsSelect, setKeywordsSelect] = useState([]);
    const [keywordsToAdd, setKeywordsToAdd] = useState([]);
    const [keywordsToRemove, setKeywordsToRemove] = useState([]);
    // eslint-disable-next-line
    const [keywordsToCreate, setKeywordsToCreate] = useState([]);
    const [nextValidate, setNextValidate] = useState(true);    

    let editorJSRef = useRef(null);

    useEffect(() => {
        if (current === 1)  {
            editorJSRef.current = new EditorJs({
                holder: 'blogeditorjs',
                tools: EDITOR_JS_TOOLS,
                data: blogData,
                onChange: () => handleEditorChange(),                
            });       
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[current]);

    const handleEditorChange = () => {
        editorJSRef.current.save().then((output) => {
            setBlogData(output);
        }).catch(e => message.warning(e));
    }

    const [{ categories, selectedCategory, allKeywords, allFeedTypes, allKeywordsStatus}, dispatch] = useDataLayerValue();
    
    const allowedToCreate = feedType === 'Internal Blog' ? (urlKey && blogData?.blocks?.length && currentSelectedCategory && activeFrom && feedType) : (url && currentSelectedCategory && activeFrom && feedType && urlKey);

    const allowedToSave = () => {    
        if(data?.urlKey !== urlKey || data?.content !== JSON.stringify(blogData) || data?.title !== title || data?.url !== url || data?.description !== description || data?.category_id !== currentSelectedCategory || allFeedTypes.filter(ft => ft.id === data?.feed_type_id)[0].name !== feedType || data?.active_from !== moment(activeFrom).toISOString() || data?.active_to !== moment(activeTo).toISOString() || data?.image !== image || keywordsToAdd.length || keywordsToCreate.length || keywordsToRemove.length) return false        
        else return true
    }
   
    useEffect(() => {               
        // on component loads generates the required tree structure using the
        // given data and stores into the state
        const treeData = generateTreeData(categories);        

        setTreeData(treeData);                                     
// eslint-disable-next-line react-hooks/exhaustive-deps
    },[categories]);    

    useEffect(() => {
        setCurrentSelectedCategory(selectedCategory);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedCategory]);

    useEffect(() => {
        if(data) {
            let blocks = JSON.parse(data.content);
            setId(data?.id || null);
            setTitle(data?.title||'');
            setUrl(data?.url||'');
            setBlogData(blocks);            
            setDescription(data?.description||'');
            setCurrentSelectedCategory(data?.category_id||'');
            setUrlKey(data?.url_key||'');
            setFeedType(allFeedTypes.filter(ft => ft.id === data?.feed_type_id)[0].name);            
            setActiveFrom(moment(data?.active_from));
            setActiveTo(data.active_to?moment(data.active_to):null);
            setImage(data?.image||null);
            if(data?.image) {
                setFiles([{
                    source: `${window.location.protocol}://${process.env.REACT_APP_SERVER_URL}${data.image}`,                           
                }]);                
            }           
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[data]);

    useEffect(() => {               
        if(data && current === 2) {
            getKeywordsForAFeed(data.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[current, data]);

    async function getKeywordsForAFeed(id) {
        setKeywords([]);
        setKeywordStatus(true);
        apiInstance.get(`/feed/keyword/${id}`)
        .then(response => 
        {
            if(response.data.status) {
                setKeywords(response.data.data); 
                setKeywordsSelect(Array.from(response.data.data.map(k => k.keywords)));
                setKeywordStatus(false);                
            } else message.warning(response.data?.data || 'something went wrong');
        })
        .catch(e => {
            console.log(e);
            setKeywordStatus(false);                     
            if(e.response || e.response?.data) {                
                message.error(e.response.data.message);
                if(e.response.status === 401) {
                    sessionStorage.clear();
                    window.location.replace("/admin");
                }
            } else message.error("Internal Server Error");
        });
    }

    useEffect(() => {

        if(url && validateURL(url) && !data?.title) {
            setKeywordStatus(true);
            apiInstance.get(`/homepage/rss?url=${url}`)
            .then(response => {                
                let RSSFeed = response.data;
                setTitle(RSSFeed?.title||'');
                if (RSSFeed?.title) {
                    let urlKeyGenerated = RSSFeed?.title.toLowerCase().replace(/ /g, '-');
                    setUrlKey(urlKeyGenerated);
                }
                setDescription(RSSFeed?.description||'');
                setImage(RSSFeed?.image?.url || RSSFeed?.image);
                setKeywordStatus(false);
            })
            .catch(e => {
                console.log(e);                
                setKeywordStatus(false);
            });
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    },[url]);    

    /**
     * Handling the positive action 
     * button of the modal
     */
    const handleOk = e => {        
        let formObj = new FormData();                
        const check = allowedToSave();   
        formObj.append("id", data?.id);
        formObj.append("content", blogData ? JSON.stringify(blogData) : null);
        formObj.append("title", title);
        formObj.append("url", url||null);
        formObj.append("image", image||null);
        formObj.append("description",description);
        formObj.append("categoryId", currentSelectedCategory);
        formObj.append("feedTypeId", allFeedTypes.filter(ft => ft.name === feedType)[0].id);
        formObj.append("activeFrom", moment(activeFrom).toISOString());
        formObj.append("activeTo", activeTo ? moment(activeTo).toISOString() : null);
        formObj.append("keywordsToAdd", JSON.stringify(keywordsToAdd||[]));
        formObj.append("keywordsToRemove", JSON.stringify(keywordsToRemove||[]));
        formObj.append("keywordsToCreate", JSON.stringify(keywordsToCreate||[]));
        formObj.append("urlKey", urlKey);
        if(files.length) {
            formObj.append("file", files[0]);
        }        

        if(type === 'update') {            
            for(let key in data) {                
                if(key === 'active_from') {                                                                            
                    if(data[key] === formObj.get('activeFrom')) formObj.delete('activeFrom');
                } 
                else if(key === 'active_to') {
                    if(data[key] === formObj.get('activeTo')) formObj.delete('activeTo')
                }
                else if(key === 'url_key') {
                    if(data[key] === formObj.get('urlKey')) formObj.delete('urlKey')
                }
                else if(key === 'category_id') {
                    if(data[key] === parseInt(formObj.get('categoryId'))) formObj.delete('categoryId')
                }
                else if(key === 'feed_type_id') {
                    if(data[key] === parseInt(formObj.get('feedTypeId'))) formObj.delete('feedTypeId')
                }
                else if(key === 'content') {
                    if(data[key] === formObj.get('content')) formObj.delete('content')
                }
                else if(key === 'image') {                    
                    if(data[key] === formObj.get('image')) formObj.delete('image')
                }
                else {
                    if(key!=='image' && data[key] === formObj.get(key)) formObj.delete(key);
                }            
            }
            formObj.append("id", data?.id);
        }                
        if(check) {
            message.warning('No Changes Made');            
        } else {                   
            setBlogData([]);
            callTrigger(false, type, formObj, data?.id);
            setTitle('');
            setUrl('');
            setImage(null);
            setDescription('');
            setBlogData([]);
            setUrlKey('');
            setCurrentSelectedCategory(null);
            setFeedType(null);
            setActiveFrom(null);
            setActiveTo(null);
            setKeywordsToAdd([]);
            setKeywordsToRemove([]);
        }        
    }  

    /**
     * Handling the negative action 
     * button of the modal
     */
    const handleCancel = e => {
        setUrl('');
        setDescription('');
        setCurrentSelectedCategory(null);
        setActiveFrom(null);
        setBlogData([]);
        setUrlKey('');
        setActiveTo(null);
        callTrigger(false);
    }  

    /**
     * Used to set the selected category of current context
     * based on the user selection
     */
    const onChange = value => {
        setCurrentSelectedCategory(value);
    }    

    /**
     * Used to restrict the active period
     * within and from today
     * @params {date} current
     */
    const disabledDate = current => {        
        return current && current < moment().startOf('day');
    }

    const disabledDates = current => {        
        return current.valueOf() < moment(activeFrom)
    }

    const handleURLChange = (e) => {        
        setUrl(e.target.value);        
    }

    const handleTitleChange = (e) => { 
        let title = e.target.value;
        setTitle(title);
        let urlKeyGenerated = title.toLowerCase().replace(/ /g, '-');
        setUrlKey(urlKeyGenerated);
    }

    const renderGeneral = () => {
        return (
            <Spin spinning={keywordStatus}>
                <Row className="w-full flex items-center justify-center">
                    <Col>
                        <p className="text-xs py-1 text-gray-600 text-center mb-1"
                        >Category *
                        </p>   
                        <TreeSelect                
                            className="mb-2 mx-2"
                            style={{ width:300 }}
                            value={currentSelectedCategory}
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            placeholder="Select Parent Category *"
                            allowClear                
                            treeData={treeData}
                            onChange={onChange}                
                        ></TreeSelect>
                    </Col>
                    <Col>
                        <p className="text-xs py-1 text-gray-600 text-center mb-1"
                        >Feed Type *
                        </p>   
                        <Select         
                            value={feedType}           
                            onSelect={setFeedType}
                            style={{ width: 200 }}
                            placeholder="Select Feed Type *"                    
                            notFoundContent="No Feed Types Found"
                            className="mb-2"
                        >        
                        {
                            allFeedTypes.length &&
                            allFeedTypes.map(feedType => (
                                <Option key={feedType.name}>{feedType.name}</Option>
                            ))                
                        }        
                        </Select>
                    </Col>
                </Row>                
                <Breadcrumb 
                    separator=">"
                    className="mb-3"
                >
                    {
                        constructBreadCrumb(categories, currentSelectedCategory||selectedCategory)
                        .reverse()
                        .map((category, index) => (
                        <Breadcrumb.Item key={index}>{category}</Breadcrumb.Item>
                        ))
                    }
                </Breadcrumb>                                                        
                    {
                        feedType !== 'Internal Blog' && (
                            <Input       
                                disabled={feedType==='Internal Blog'}                                 
                                style={{ width: 450 }}                                       
                                onChange={e => handleURLChange(e)}
                                value={url}                
                                placeholder="URL Here (Required)"
                                type="text"
                                className="mb-3"
                            />
                        )
                    }                
                <Input                                                                        
                    readOnly={type==='create'&&!url&&feedType!=='Internal Blog'}
                    style={{ width: 450 }}                                       
                    onChange={handleTitleChange}
                    value={title}                
                    placeholder="Title (Required)"
                    title={type==='create'?'Read Only':''}
                    type="text"
                    className="mb-3"
                />                
                <Input
                    style={{ width: 450 }}                 
                    onChange={e => setDescription(e.target.value)}
                    value={description}                
                    type="text"
                    placeholder={feedType==='Internal Blog'?"Short Description (Required)":"Short Description"}
                    className="mb-3"
                />
                <Input
                    style={{ width: 450 }}                 
                    onChange={e => setUrlKey(e.target.value)}
                    value={urlKey}                
                    type="text"
                    placeholder="URL Key Here (Required)"
                    className="mb-3"
                />
                <p className="my-2 text-xs text-red-500 text-center">{errorText}</p>
            </Spin>
        );
    }

    const renderInformation = () => {
        return (
            <Spin wrapperClassName="w-full" spinning={keywordStatus}>
            <div className={feedType==='Internal Blog' ? 'w-full flex items-start': "w-full flex items-center justify-center"}>                
                {
                    feedType !== 'Internal Blog' && (
                        <Image
                            className="mx-6"
                            width={200} 
                            height={200}
                            src={image||assistanceIcon}                   
                        />
                    )
                }
                <div className="w-full flex items-start">
                    <div className="w-1/2 mx-3 flex flex-col items-center">
                        <p className="text-xs py-1 text-gray-600 text-left mb-1"
                    >Active Period
                    </p>  
                    <Row>
                            <DatePicker
                                defaultValue={activeFrom}
                                placeholder="Active From"
                                onChange={e => setActiveFrom(e)}                            
                                className="mb-3 mr-2"
                                disabledDate={disabledDate} 
                            />
                            {
                                activeTo ?
                                <DatePicker               
                                    defaultValue={activeTo}             
                                    placeholder="Active To"
                                    onChange={e => setActiveTo(e)}                            
                                    className="mb-3"
                                    disabledDate={disabledDates} 
                                    disabled={!activeFrom}
                                /> :
                                <DatePicker                            
                                    placeholder="Active To"
                                    onChange={e => setActiveTo(e)}                            
                                    className="mb-3"
                                    disabledDate={disabledDates} 
                                    disabled={!activeFrom}
                                />
                            }
                        </Row>                                             
                        </div>
                        {
                            feedType === 'Internal Blog' && (
                                <div className="w-1/2 mx-3 flex flex-col items-center">
                                    <p className="text-xs py-1 text-gray-600 text-center mb-1"
                                    >Blog Images
                                    </p>  
                                    <Row className="w-full">
                                                {/*  BLOG IMAGES UPLOAD */}
                                                <FilePond                                                                           
                                                    files={files}          
                                                    allowFileSizeValidation={true}
                                                    maxFileSize="1MB"
                                                    imageCropAspectRatio="14:9"
                                                    allowMultiple={false}
                                                    allowImageCrop={true}
                                                    maxFiles={1}
                                                    onupdatefiles={(fileItems) => {
                                                        if(fileItems.length) {
                                                            setFiles(fileItems.map(item => item.file))
                                                        } else {
                                                            setFiles([])
                                                        }                                                        
                                                    }}       
                                                    onremovefile={(fileItems) => {
                                                        console.log(fileItems)
                                                    }}                                           
                                                />
                                    </Row>                                             
                                </div>
                            )
                        }                        
                </div>                                        
            
            </div>
            {/* EDITOR JS */}
            {
                feedType === 'Internal Blog' && (
                    <div className="text-left w-full bg-white mt-2 shadow p-1">            
                        <div id={'blogeditorjs'}></div>
                    </div>  
                )
            }            
            <p className="my-2 text-xs text-red-500 text-center">{errorText}</p>
            </Spin>
        );
    }

    const getIdByName = (data, type) => {
        let result = [];
        // eslint-disable-next-line
        allKeywords.map(({ name, id }) => {
           if(type) {
            // eslint-disable-next-line
            data.map(i => {
                if(i === name) result.push(id.toString());
               });
            } else {
                // eslint-disable-next-line
                data.map((i) => {
                    if(i.keywords === name) result.push(id.toString());
                   });
            }
        });
        return result;
    }

    const handleKeywordChange = (data) => {         
        setKeywordsSelect(data);
        let temp = allKeywords.map(({name})=>name);               
        let newKeywords = data.filter(keyword => !temp.includes(keyword));
        if(newKeywords.length) {
            setKeywordStatus(true);
            // CREATE NEW KEYWORD            
                let keywordData = { name: newKeywords[newKeywords.length-1]};            
                apiInstance.post("/keyword" , keywordData).then(response => {
                    if(response.data.status) {                    
                        let newKeywordId = [response.data.data.insertId.toString()];                
                        setKeywordsToAdd([...newKeywordId,...keywordsToAdd]);                                        
                        dispatch({
                            type: 'SET_ALL_KEYWORDS_STATUS',
                            status: !allKeywordsStatus
                        });
                        setKeywordStatus(false);
                    } else message.warning(response.data?.data || 'Something went wrong');
                    setKeywordStatus(false);
                })
                .catch(e => {
                    console.log(e);
                    setKeywordStatus(false);                     
                    if(e.response || e.response?.data) {                
                        message.error(e.response.data.message);
                        if(e.response.status === 401) {
                            sessionStorage.clear();
                            window.location.replace("/admin");
                        }
                    } else message.error("Internal Server Error");
                });
                            
        }
        
        let exisitingKeywords = getIdByName(keywords, false);
        let correctedKeywords = getIdByName(data, true);
                
        setKeywordsToAdd(correctedKeywords.filter(keyword => !exisitingKeywords.includes(keyword)));                
        setKeywordsToRemove(exisitingKeywords.filter(keyword => !correctedKeywords.includes(keyword)));             
    }

    const viewColumns1 = [
        {
            title: 'Title',
            dataIndex: 'title'            
        },
        {
            title: 'URL',
            dataIndex: 'url'
        },
        {
            title: 'Description',
            dataIndex: 'description',
            ellipsis: {
                showTitle: false
            },
            render: description => (
                <Tooltip placement="topLeft" title={description}>
                    {description}
                </Tooltip>
            )
        }
                   
    ];

    const viewColumns3 = [           
        {
            title: 'Category',
            dataIndex: 'category'
        },
        {
            title: 'Feed Type',
            dataIndex: 'feedType'
        },
        {
            title: 'Active Period',
            dataIndex: 'active_period'
        },        
        {
            title: 'Status',
            dataIndex: 'is_active'            
        },
        {
            title: 'Approval',
            dataIndex: 'approval'
        }     
    ];

    const renderKeywords = () => {
        return (
            <>
              <Spin spinning={keywordStatus} className="flex items-center justify-center">
                <p className="mb-3 text-md text-gray-600"
                >Select one or multiple keywords that match the feed content, this will help us to get the content to right ones
                </p>
                <Row>
                    {
                        !keywordStatus && 
                        <Select                        
                            allowClear
                            style={{ width: 400 }}
                            mode="tags"  
                            notFoundContent="No Keywords Create one"                                              
                            placeholder="Add Keywords"
                            value={keywordsSelect}
                            onChange={handleKeywordChange}
                            className="mb-3"
                        >        
                            {
                                allKeywords.length &&
                                allKeywords.map(keyword => (
                                    <Option key={keyword.name}>{keyword.name}</Option>
                                ))                
                            }        
                        </Select>  
                    }                                       
                </Row>                
              </Spin>
            </>
        )
    }
    
    const renderPublish = () => {
        const data1 = [
            {
                key: '1',
                title: title || 'N/A',
                url: url || 'N/A',
                description: description || 'N/A'                
            }
        ];        
        const data3 = [
            {
                key: '3',
                category: categories.filter(c => c.id === currentSelectedCategory)[0]?.title || 'N/A',
                feedType: feedType || 'N/A',
                active_period: activeFrom && activeTo ? 
                `${moment(activeFrom).format('ll')} to
                ${moment(activeTo).format('ll')}`: `From ${moment(activeFrom).format('ll')}`,                
                is_active: type==='create'?'Active': data?.is_active ? 'Active' : 'In Active',
                approval: data?.approval ? 'Approved' : 'Pending',
            }
        ];
        return(
            <>     
                <Table                     
                    bordered
                    pagination={false}
                    dataSource={data1}
                    columns={viewColumns1}
                    size="middle"
                /> 
                <Divider />               
                <div className="flex items-center justify-evenly">
                    <Image
                        className="mx-6"
                        width={100} 
                        height={100}
                        src={feedType==='Internal Blog'?assistanceIcon:image||assistanceIcon}                        
                    />
                    <Table 
                        bordered
                        pagination={false}
                        dataSource={data3}
                        columns={viewColumns3}
                        size="middle"
                    />
                </div>
            </>
        )
    }   

    const nextStep = () => {
        const newCurrent = current + 1;
        setCurrent(newCurrent);        
    }

    const prevStep = () => {
        const newCurrent = current - 1;
        setCurrent(newCurrent);
    }    

    const steps = [
        {
          title: 'General',
          content: renderGeneral(),
        },
        {
          title: 'Information',
          content: renderInformation(),
        },
        {
            title: 'Keywords',
            content: renderKeywords(),
        },
        {
          title: 'Summary',
          content: renderPublish(),
        }
    ];  
    
    useEffect(() => {

        if (current === 0) {
            if (feedType === 'Internal Blog') {                
                if (currentSelectedCategory && feedType && title && description && urlKey) {
                    setErrorText('');
                    setNextValidate(false);
                } else {
                    setErrorText(`${urlKey?'':'urlkey'} ${title?'':'title'} ${description?'':'description'} ${currentSelectedCategory?'':'category'} ${feedType?'':'feed type'} field is missing`);
                    setNextValidate(true);
                }
            } else {
                if (currentSelectedCategory && feedType && validateURL(url) && urlKey) {
                    setErrorText('');
                    setNextValidate(false);
                } else {
                    setErrorText(`${urlKey?'':'urlkey'} ${url?'':'url'} ${currentSelectedCategory?'':'category'} ${feedType?'':'feed type'} field is missing`);
                    setNextValidate(true);
                }              
            }
        } else if (current === 1) {
            if (activeFrom) {
                setErrorText('');
                setNextValidate(false);
            }
            else {
                setErrorText(`${activeFrom?'':'active period'} field is missing`);
                setNextValidate(true);
            }
        } else {            
                setErrorText('');
                setNextValidate(false);            
        }

    },[url, currentSelectedCategory, activeFrom, feedType, current, title, description, urlKey]);

    async function checkUrlKey() {
        apiInstance.get(`/feed/urlkey/check?key=${urlKey}&id=${id}`)
        .then(response => {
          if(response.data.status) {                                          
            if(!response.data.data) {
                setNextValidate(true);
                setErrorText('url key not available');
            } else {
                setNextValidate(false);
                setErrorText('');
            }
          } else {            
            // window.location.replace('/fallback');
            console.log(response.data?.data||response?.status);            
          }
        })
        .catch(e => {        
          console.log(e);
          if(e.response || e.response?.data) {                          
              // message.error(e.response.data.message);
              if(e.response.status === 401) {
                  sessionStorage.clear();
                  window.location.replace("/admin");
              }
          } else {
            // history.push('/');
          }
        });  
    }

    useEffect(() => {
        if (urlKey) {

            // debounce fx
            const timer = setTimeout(() =>{
                checkUrlKey();
            }, 2000);  
            return () => clearTimeout(timer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[urlKey]);

    return (        
        <Modal                          
            onCancel={handleCancel}
            maskClosable={false}                      
            width={feedType==='Internal Blog'?1000:850}                                
            style={{ top: 20 }}
            destroyOnClose={true}
            title={modalTitle}
            visible={trigger}            
            okButtonProps={{
                loading: load                
            }}           
            footer={null}
        >            
            <>
                <Steps current={current}
                    size="small"
                >
                    {
                        steps.map(item => (                           
                            <Step key={item.title} title={item.title} />
                        ))
                    }
                </Steps>
                <div className={(current===steps.length-1)?'steps-content-end':'steps-content'}>{steps[current].content}</div>
                <div className="steps-action">
                    {current < steps.length - 1 && (
                        <Button type="primary"
                            disabled={nextValidate} 
                            onClick={() => nextStep()}                            
                        >
                        Next
                        </Button>
                    )}
                    {current === steps.length - 1 && (
                        <Button type="primary" onClick={() => handleOk()}
                            disabled={!allowedToCreate}>
                        {type === 'create' ? 'Create' : 'Save'}
                        </Button>
                    )}                    
                    {current > 0 && (
                        <Button style={{ margin: '0 8px' }} onClick={() => prevStep()}>
                        Previous
                        </Button>
                    )}
                    {
                        <Button type="dashed" style={{ margin: '0 8px' }} onClick={() => handleCancel()}
                        >{type==='update'?'Close':'Cancel'}                            
                        </Button>
                    }
                </div>            
            </>

        </Modal>
    )
}

export default FeedModal
