import React from 'react'
// import { FireFilled } from '@ant-design/icons'
import TopFeedBox from './TopFeedBox'
import { useDataLayerValue } from '../../DataLayer'
import { breakDownURL, validateURL } from '../../utils/Helper'

function TopFeed({ data }) {
    const [{ categories }] = useDataLayerValue();       

    return (
        <div className="w-full px-4 lg:px-10 mb-2">            
            <div className="w-full grid lg:grid-cols-3 grid-cols-1 gap-4 mb-4 py-2">
                {
                    data && data.length ?
                    data.map((feed, i) => (
                            <TopFeedBox 
                                urlKey={feed?.url_key}                               
                                key={i+2}
                                id={feed.id}
                                position={i+1}
                                title={feed?.title}
                                category={categories.filter(c => c.id === feed?.category_id)[0].title}
                                created={feed?.modified_at}
                                domain={validateURL(feed?.url) ? breakDownURL(feed?.url)?.shortUrl||null : 'assistance.org'}
                                image={feed?.image}
                            />     
                    ))
                    : null
                }
            </div>
            <hr />
        </div>
    )
}

export default TopFeed
