import React,{ useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import Header from '../components/User/Header'
import { Tabs, Typography, Skeleton, message, Modal, Form, Input, Tag, Card, Popconfirm } from 'antd'
import { ExclamationCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { validateEmail, getProfileImage, addKeyToData } from '../utils/Helper'
import moment from 'moment'
import apiInstance from '../api'
import { useDataLayerValue } from '../DataLayer'
import AddressModal from '../components/Admin/AddressModal'

const { Text } = Typography;
const { TabPane } = Tabs;
const { confirm } = Modal;

function Profile({ navigation }) {

    let { username } = useParams();    
    
    const [{ user, token, allAddressTypesStatus }, dispatch] = useDataLayerValue();

    const [userData, setUserData] = useState(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');  
    const [dataLoad, setDataLoad] = useState(false);
    const [closeLoad, setCloseLoad] = useState(false);
    const [agree, setAgree] = useState(false);
    const [form] = Form.useForm();
    const [passwordLoad, setPasswordLoad] = useState(false);
    const [address, setAddress] = useState([]);
    const [addAddressModal, setAddAddressModal] = useState(false);
    const [updateAddressModal, setUpdateAddressModal] = useState(false);
    const [currentAddress, setCurrentAddress] = useState(null);

    useEffect(() => {        
        async function getAllAddressTypes() {            
            apiInstance.get("/addresstype")
            .then(response => {        
                if(response.data.status) {
                // ADDING AN EXTRA KEY TO THE RESPONSE DATA FOR REACT UNIQUE PROP
                addKeyToData(response.data.data, "id");                
                // DISPATCH TO DATA LAYER !!!
                dispatch({
                    type: "SET_ALL_ADDRESS_TYPES",
                    addressTypes: response.data.data
                });
    
            } else message.warning(response.data?.data || 'something went wrong');            
            }).catch(e => {                
                console.log(e);                
            });
        }

        getAllAddressTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[allAddressTypesStatus]);

    useEffect(() => {
        getPersonAddress(user?.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getPersonAddress(id) {        
        apiInstance
            .get(`/address/person/${id}`)
            .then(response => {                
                if(response.data.status) {                    
                    setAddress(response.data.data);                    
                } else message.warning(response.data?.data || 'something went wrong');
            }).catch(e => {            
                console.log(e);                
            });
    }

    const postAddressCreation = (flag) => {
        setAddAddressModal(flag);
        getPersonAddress(user?.id);
    }

    useEffect(() => {
        document.title = "Assistance Profile";
        async function getUserData() {
            apiInstance.get(`/person/profile/${username.replace('-',' ')}`)
                        .then(response => {
                            if (response.data.status) {
                                document.title = `${response.data.data?.username} - Assistance Profile`;
                                setName(response.data.data?.username);
                                setEmail(response.data.data?.email_address);
                                setUserData(response.data.data);
                                setAgree(parseInt(response.data.data?.location_sharing_allowed)===1?true:false);
                            } else {
                                setUserData(null);
                            }
                        }).catch(e => {
                            if(e.response || e.response.data) {                
                                message.error(e.response.data.message);
                                if(e.response.status === 401) {
                                    sessionStorage.clear();
                                    window.location.replace("/signin");
                                }
                            } else message.error("Internal Server Error");
                        });
        } 
        getUserData();        
    },[username]);

    function deactivateConfirmation() {
        confirm({
          okButtonProps: {
              loading: closeLoad
          },
          title: 'Do you want to deactivate your account?',
          icon: <ExclamationCircleOutlined />,
          content: 'Once it is deactivated, it cannot be used anymore',
          onOk() {
            setCloseLoad(true);

            apiInstance.put(`/person/delete/${userData.id}`)
            .then(response => {
                if(response.data.status) {                 
                    sessionStorage.clear();   
                    setCloseLoad(false);
                    window.location.replace("/signin");
                    message.success('Account Deactivated Successfully');
                } else message.warning(response.data?.data|| 'Something went wrong');
            }).catch(e => {
                if(e.response || e.response.data) {                
                    message.error(e.response.data.message);
                    if(e.response.status === 401) {
                        sessionStorage.clear();
                        window.location.replace("/signin");
                    }
                } else message.error("Internal Server Error");
            });
    

          },
          onCancel() {
            message.warning('Deactivation cancelled');
          },
        });
    }
    
    /**
     * Used to update the user data
     * based on the given details
     */
    const handleUserDataChange = () => {
        if(email) {
            if(validateEmail(email)) {

                setDataLoad(true);
                let userPayload = {                    
                    email_address: email,
                    agree: agree ? 1 : 0
                };
                apiInstance.put(`/person/profile/update/${userData.id}`, userPayload)
                .then(response => {
                    setDataLoad(false);                    
                        message.success(response.data.data);
                        let tempLocaler = JSON.parse(sessionStorage.getItem('assistance_user'));
                        if(tempLocaler) {
                            tempLocaler = {
                                ...tempLocaler,                                
                                email_address: userPayload.email_address
                            };
                        }
                        sessionStorage.setItem('assistance_user', JSON.stringify(tempLocaler));

                        dispatch({ // FIRE IT ON DATA LAYER FOR NOW !!
                            type: "UPDATE_USER"                        
                        });                                      
                }).catch(e => {                    
                    setDataLoad(false);
                    if(e.response || e.response.data) {                
                        message.error(e.response.data.message);
                        if(e.response.status === 401) {
                            sessionStorage.clear();
                            window.location.replace("/signin");
                        }
                    } else message.error("Internal Server Error");
                });
            } else message.warning('Invalid Email Address');
            
        } else message.warning('Email cannot be empty');
    }

    /**
     * Used to change the password based on the given
     * details with validation of new passsword
     * @param {oldPassword, newPassword, confirmPassword} data 
     */
    const handlePasswordChange = () => {
        if(form.getFieldValue('newPassword') === form.getFieldValue('confirmPassword')) {
            // positive
            setPasswordLoad(true);
            let passwordPaylod = {
                password: form.getFieldValue('oldPassword'),                
                new_password: form.getFieldValue('newPassword')
            };            
            apiInstance.put(`/person/password/secure/${userData.id}`, passwordPaylod)
            .then(response => {
                if(response.data.status) {
                    message.success(response.data.data);
                } else message.warning(response.data?.data|| 'Something went wrong');
                setPasswordLoad(false);
            }).catch(e => {
                if(e.response || e.response.data) {                
                    message.error(e.response.data.message);
                    if(e.response.status === 401) {
                        sessionStorage.clear();
                        window.location.replace("/admin");
                    }
                } else message.error("Internal Server Error");
            });
    
            form.resetFields();
            
        } else message.warning('Password donot match');
    }

    const handleDelete = (id) => {
        apiInstance.delete(`/address/${id}`)
        .then(response => {
            if(response.data.status) {       
                setAddress(address.filter(oa => oa.id !== id));                                      
                message.success(response.data.data);
            } else message.warning(response.data?.data || 'something went wrong');
        })
        .catch(e => {
            console.log(e);
            if(e.response || e.response?.data) {                
                message.error(e.response.data.message);
                if(e.response.status === 401) {
                    sessionStorage.clear();
                    window.location.replace("/signin");
                }
            } else message.error("Internal Server Error");
        });
    }

    const openAddressEditModal = (e, data) => {
        e.preventDefault();
        setCurrentAddress(data);
        setUpdateAddressModal(true);
    }

    const postAddressUpdation = (flag) => {
        getPersonAddress(user?.id);
        setUpdateAddressModal(flag);
    }

    return (
        <>
            <Header />            
            <div className={`w-full h-full flex flex-col lg:flex-row ${user?.username !== username?.replace('-',' ')?'items-center justify-center':'items-start'} px-6 lg:px-20 py-10 lg:py-12`}>  

                {
                    userData ? (
                        <>
                        <div className="w-full lg:w-1/3 flex flex-col items-center p-10 border rounded-md lg:mx-4 shadow my-3">

                        <img
                            alt="profile"
                            src={userData?.image || getProfileImage(username.replace('-',' '))}
                            className="h-28 rounded-full"
                        />
                        <h2
                            className="py-2 text-center text-lg font-medium primary-color"
                        >{username.replace('-', ' ')}</h2>                    
                        {
                            !!token ? 
                            (
                                <a                        
                                    href={`mailto:${email}`}                                          
                                    className="text-white rounded-lg text-md block primary-bg text-center
                                            px-4 py-2 cursor-pointer mb-4 focus:outline-none"
                                >Contact
                                </a>                                
                            ) : (
                                <Link                        
                                    to="/signin"
                                    className="text-white rounded-lg text-md block primary-bg text-center
                                            px-4 py-2 cursor-pointer mb-4 focus:outline-none"
                                >Sign in to view email
                                </Link>
                            )
                        }
                        <p
                            className="py-1 text-center text-md primary-color"
                        >Joined on Feb 22</p>                    
                        </div>                        
                        {
                        (username && user?.username === username?.replace('-',' ')) && (
                        <div className="w-full flex flex-col items-start lg:p-10 p-2 border rounded-md shadow">
                        <Tabs tabPosition={"left"}>
                            <TabPane tab="General" key="1">
                            <div className="w-full flex flex-col">                                    
                                    <p
                                        className="py-2 mb-2 border-b text-md font-bold"
                                    >Personal Information                
                                    </p>
                                    <div className="lg:w-full flex flex-col">
                                        <p 
                                            className="text-md text-left py-1 font-semibold text-gray-700 mb-1"
                                        >Username :                     
                                        </p>                                                                            
                                        <Text    
                                            ellipsis={true}                                                                                                                                                           
                                            className="w-40 lg:w-full lg:p-2 my-2 mb-2 lg:mx-4 rounded-lg text-black text-md"
                                        >{name}                            
                                        </Text>                                            
                                        <p 
                                            className="text-md text-left py-1 font-semibold text-gray-700 mb-1"
                                        >Email Address :                     
                                        </p>
                                        {
                                            (user?.account_type === 'default') ? (
                                                <Text   
                                                    ellipsis={true}                                                      
                                                    editable={{ onChange: setEmail, autoSize: { maxRows: 1 } }}                                            
                                                    className="w-40 lg:w-full lg:p-2 my-2 mb-2 lg:mx-4 rounded-lg text-black text-md"
                                                >{email}                            
                                                </Text>   
                                            ) : (
                                                <Text
                                                    ellipsis={true}                                                                                                                                                                                           
                                                    className="w-40 lg:w-full lg:p-2 my-2 mb-2 lg:mx-4 rounded-lg text-black text-md"
                                                >{email}                            
                                                </Text>   
                                            )
                                        }   
                                        {/* <div className="mt-3 flex items-center">
                                            <Switch
                                                checked={agree}
                                                className="text-gray-600 mx-3"
                                                onChange={(e) => setAgree(e)}
                                            />
                                            <p className="text0md text-gray-800">I would like to share my location information in the assistance community.</p>
                                        </div> */}
                                        {
                                             (user?.account_type === 'default') && (
                                                <button
                                                    disabled={(name.length===0) || (email.length===0)}
                                                    onClick={() => handleUserDataChange()}                                            
                                                    className="w-24 lg:w-32 mt-4 text-white rounded-lg text-md block primary-bg text-center
                                                    px-2 py-2 cursor-pointer mb-4 focus:outline-none primary-btn"                                                                                                             
                                                >{dataLoad?'Saving...':'Save'}                        
                                                </button>
                                             )
                                        }      
                                        <p
                                            className="py-1 w-full text-left text-xs mb-2 text-gray-600"
                                        > Contact support <a className="underline" href="mailto:contact@assistance.org">contact@assistance.org</a>
                                        </p>                                                                                                          
                                    </div>                            
                            </div>
                            </TabPane>
                            <TabPane tab="Security" key="2">
                                <p
                                    className="py-2 mb-6 border-b text-md font-bold"
                                >Account Security
                                </p>
                                {
                                    userData?.account_type === 'default' ?
                                    (
                                        <Form
                                            className="w-full"
                                            layout="vertical"
                                            form={form}
                                            onFinish={handlePasswordChange}
                                        >
                                            <Form.Item name="oldPassword">
                                                <Input.Password                                                     
                                                    required                                
                                                    placeholder="Old Password" 
                                                    className="p-2 rounded-lg text-black text-md"
                                                />
                                            </Form.Item>
                                            <Form.Item name="newPassword">
                                                <Input.Password                                                     
                                                    required                                    
                                                    placeholder="New Password" 
                                                    className="p-2 rounded-lg text-black text-md"
                                                />
                                            </Form.Item>
                                            <Form.Item name="confirmPassword">
                                                <Input.Password                                                     
                                                    required                                
                                                    placeholder="Confirm New Password"
                                                    className="p-2 rounded-lg text-black text-md" 
                                                />
                                            </Form.Item>
                                            <Form.Item>
                                                <button
                                                    htmlType="submit"
                                                    className="text-white rounded-lg text-sm block primary-bg text-center
                                                    px-2 py-2 cursor-pointer mb-4 focus:outline-none primary-btn"        
                                                >{passwordLoad?'Please Wait...':'Change Password'}                                
                                                </button>
                                            </Form.Item>
                                        </Form>
                                    ) : (
                                        <p
                                            className="py-1 w-full text-left text-xs mb-2 text-gray-600"
                                        > You cannot update or modify password for a social account type
                                        </p>
                                    )
                                }                            
                            </TabPane>
                            <TabPane tab="Contact" key="3">
                                <>
                                    <div className="w-full flex flex-col">                                    
                                        <p
                                            className="py-2 mb-2 border-b text-md font-bold"
                                        >Address 
                                        {
                                            !address?.length && (
                                                <button onClick={() => setAddAddressModal(true)} className="outline-none focus:outline-none primary-bg text-sm mx-2 text-white rounded-md py-1 px-2">Add</button>
                                            )
                                        }                                            
                                        </p>
                                        {
                                            address?.length ? (
                                                address.map((item, idx) => (
                                                    <Card 
                                                        title={`Address ${idx<10?'0':''}${idx+1} ${item?.default_type===1?<Tag color="blue" size="small">Default</Tag>:''}`} 
                                                        extra={<div className="flex items-center"><p onClick={(e) => openAddressEditModal(e, item)} 
                                                        className="w-auto lg:w-full mr-4 cursor-pointer"><EditOutlined /></p> 
                                                            <Popconfirm
                                                                title="Are you sure?"
                                                                onConfirm={() => handleDelete(item.id)}
                                                                okText={"Yes"}                                        
                                                                cancelText={"No"}
                                                            ><p className="cursor-pointer"><DeleteOutlined /></p>
                                                            </Popconfirm>
                                                        </div>} 
                                                        bordered={true} size="small" className="mt-2 shadow">
                                                        {
                                                            item?.line_2 === null ? (
                                                                <p>{item.line_1}</p>
                                                            ) : ( 
                                                                <p>{item.line_1+" "+item?.line_2||''}</p>
                                                            )
                                                        }
                                                        <p>{item.city+", "+item.state+" - "+item.zipcode}</p>
                                                        <p>{item.country}</p>
                                                    </Card>
                                                ))
                                            ) : <p>No Address Available</p>
                                        }       
                                    </div>
                                </>
                            </TabPane>
                            <TabPane tab="Account" key="4">
                                        <p 
                                            className="text-md text-left py-1 font-semibold text-gray-700 mb-1"
                                        >Joined Assistance :                     
                                        </p>                
                                        <p 
                                            className="text-sm text-left py-1 mb-1 mx-4"
                                        >{moment(userData?.created_at).format('MMM YYYY')}
                                        </p>
                                        <p 
                                            className="text-md text-left py-1 font-semibold text-gray-700 mb-1"
                                        >Account Type :                     
                                        </p>                
                                        <Tag color="blue" className="lg:mx-4 my-2">
                                            {userData?.account_type==='default'?'Assistance Account':userData?.account_type?.toUpperCase()}
                                        </Tag>                                                                               
                                        <button
                                            onClick={() => deactivateConfirmation()}
                                            className="lg:w-1/2 mt-4 text-white rounded-lg text-md block bg-red-500 text-center hover:bg-red-600
                                            px-2 py-2 cursor-pointer mb-4 focus:outline-none"                                                 
                                        >Deactivate Account
                                        </button>
                                        <p
                                            className="py-1 w-full text-left text-xs mb-2 text-gray-600"
                                        > Once you deactivate your account you use it again until it is allowed by admin
                                        </p>
                            </TabPane>
                        </Tabs>
                        </div>
                        )}                        
                        </>
                    ) : 
                    (
                        <Skeleton loading={true} active className="mb-4">
                            <h4>Assistace.org Sample Title</h4>                                    
                            <p>assistance user display profile</p>
                        </Skeleton> 
                    )
                }        
            </div>
            {
                addAddressModal &&
                <AddressModal
                    sector={"person"}
                    data={null}            
                    type="create"
                    modalTitle="Add Address"
                    okLabel="Add"
                    trigger={addAddressModal}  
                    callTrigger={postAddressCreation}  
                    id={user?.id}                
                />
            }
            {
                updateAddressModal &&
                <AddressModal
                    sector={"person"}
                    data={currentAddress}            
                    type="update"
                    modalTitle="Edit Address"
                    okLabel="Save"
                    trigger={updateAddressModal}  
                    callTrigger={postAddressUpdation}  
                    id={user?.id}                
                />
            }
        </>
    )
}

export default Profile
