import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import SearchBar from '../components/User/SearchBar'
import apiInstance from '../api'
import assistanceIcon from '../assets/img/icon.png'
import orgLogo from '../assets/img/org.png'
import { Button, message, Select, Pagination } from 'antd'
import { checkAvailability, validateURL } from '../utils/Helper'
import Header from '../components/User/Header'
import Maps from '../components/User/Maps'
import { useDataLayerValue } from '../DataLayer'
import { FilterFilled } from '@ant-design/icons'
import SearchResultItem from '../components/User/SearchResultItem'

const { Option } = Select;

function Search() {
    let query = new URLSearchParams(useLocation().search);    

    const [{ categories }] = useDataLayerValue();

    // eslint-disable-next-line
    const [orgs, setOrgs] = useState([]);
    const [blogs, setBlogs] = useState([]);
    const [tab, setTab] = useState("blogs");
    const [preload, setPreload] = useState(false);

    const [blogPages, setBlogPages] = useState(0);
    const [currentBPage, setCurrentBPage] = useState(1);
    const [orgPages, setOrgPages] = useState(0);
    const [currentOPage, setCurrentOPage] = useState(1);

    const [filterBlogs, setFilterBlogs] = useState([]);
    const [blogFilterText, setBlogFilterText] = useState('filter');

    const [filterCountry, setFilterCountry] = useState('country');
    const [filterState, setFilterState] = useState('state');
    const [country, setCountry] = useState([]);
    const [states, setStates] = useState([]);

    function fetchFilterBlogsIfExists(data) {
        if (data?.length) {
            let tempFilter = new Set();
            let filter = [];
            data.forEach(item => {
                let filterItem = { title: item?.category, value: item?.category };
                if (!tempFilter.has(item?.category)) {
                    filter.push(filterItem);
                    tempFilter.add(item?.category);
                } 
            });
            setFilterBlogs(filter);
        }
    }

    function fetchFilterItemsIfExists(data) {
        let tempCountryList = new Set();
        let tempStateList = new Set();
        if (data?.length) {
            data.forEach(function(item) {
                if (item?.country) tempCountryList.add(item.country);
                if (item?.state) tempStateList.add(item.state);
            });
            if (tempCountryList?.size) setCountry(Array.from(tempCountryList));   
            if (tempStateList?.size) setStates(Array.from(tempStateList));
        }
    }

    document.title = "Search - Assistance";

    async function getSearchResult() {
        setPreload(true);
        apiInstance.get(`/homepage/search?${query}&page=${currentBPage}`)
        .then(response => {
            setPreload(false);
            if(response.data.status) {      
                if (response.data.blogs?.data?.length) {
                    setTab("blogs");
                } else if (response.data.orgs?.data?.length) {
                    setTab("orgs");
                }              
                fetchFilterBlogsIfExists(response?.data.blogs?.data);
                fetchFilterItemsIfExists(response.data?.orgs?.data);
                setBlogs(response.data.blogs?.data);
                setBlogPages(response.data.blogs?.found);
                setOrgPages(response.data.orgs?.found);
                setOrgs(response.data.orgs?.data);
            } else message.warning(response.data?.data||'Something Went Wrong!');
        }) 
        .catch(e => {
            setPreload(false);
            console.log(e);
            if(e.response || e.response?.data) {                
                message.error(typeof e.response.data.message === 'string'? e.response.data.message : 'Something went wrong' );
                if(e.response.status === 401) {
                    sessionStorage.clear();
                    window.location.replace("/signin");
                }
            } else message.error("Internal Server Error");
          });

    }

    useEffect(() => {        
        getSearchResult();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentBPage]);

    function getMeFilteredOrgs() {
        if (filterState==='state' && filterCountry==='country') {
            return orgs;
        } else if (filterCountry!=='country') {
            return orgs?.filter(item => item.country === filterCountry);
        } else if (filterState!=='state') {
            return orgs?.filter(item => item.state === filterState);
        } else return orgs;
    }

    return (
        <div className="py-4">
            <Header />
            <div className="lg:px-20 px-2">
            <SearchBar 
                size="small"
                type="simple" 
                trigger={getSearchResult} 
                placeholderText="Search for Assistance"               
            />            
            
                <div className="py-2">
                    <div className="w-full flex items-center lg:justify-start justify-center py-2 border-b border-gray-400 mb-3">
                        <h3 className="text-xs lg:text-md text-left text-gray-700 py-2 mr-3"
                        >{blogPages+orgPages} {(blogPages > 1)&&(orgPages > 1) ?'Results': 'Result'}  Found              
                        </h3> 
                        {
                           !!(orgs?.length || blogs?.length) && (
                            <>
                                <span>|</span> 
                                <p 
                                    onClick={() => setTab("blogs")} 
                                    className={`text-xs lg:text-md text-gray-800 mx-3 cursor-pointer hover:underline ${tab==='blogs' && 'font-bold'}`}
                                >Blogs {blogs.length > 0 ? `(${blogs.length})` : ""}</p> 
                                    {
                                        !!orgs?.length && (
                                            <><span>|</span> 
                                                <p 
                                                    onClick={() => setTab("orgs")} 
                                                    className={`text-xs lg:text-md text-gray-800 mx-3 cursor-pointer hover:underline ${tab==='orgs' && 'font-bold'}`}
                                                >Organizations {!!orgs.length > 0 ? `(${orgs.length})` : ""}</p> {!!orgs?.length && ('|')}
                                                {/* <p 
                                                    onClick={() => setTab("maps")} 
                                                    className={`text-xs lg:text-md text-gray-800 mx-3 cursor-pointer hover:underline ${tab==='maps' && 'font-bold'}`}
                                                >Maps</p> */}
                                            </>
                                        )
                                    }
                            </>
                           )
                        }    
                    </div>
                    <div className="py-1 w-full flex flex-row items-center">
                        {
                            tab === "blogs" && !!blogs?.length && (
                                <>
                                    <p className="text-lg px-2"><FilterFilled /></p>
                                    <Select  
                                        value={blogFilterText}
                                        onChange={(e) => setBlogFilterText(e)}
                                        style={{ width: 200 }}
                                    >
                                        <Option value="filter" disabled="true">Select by category</Option>
                                        {
                                            filterBlogs?.map((item, idx) => (
                                                <Option key={idx} value={item?.value}>{item?.title}</Option>
                                            ))
                                        }
                                    </Select>
                                    <Button 
                                        disabled={blogFilterText==='filter'} 
                                        className="rounded-md mx-2" 
                                        type="dashed" 
                                        danger 
                                        onClick={() => setBlogFilterText('filter')}
                                    >Clear Filter</Button>
                                </>
                            )
                        }
                        {
                            tab === "orgs" && !!orgs?.length && (
                                <>
                                    <p className="text-lg px-2"><FilterFilled /></p>
                                    <Select  
                                        value={filterState}
                                        onChange={(e) => setFilterState(e)}
                                        style={{ width: 200 }}
                                    >
                                        <Option value="state" disabled="true">Select State</Option>
                                        {
                                            states?.map((item, idx) => (
                                                <Option key={idx} value={item}>{item}</Option>
                                            ))
                                        }
                                    </Select>
                                    <Select  
                                        className="mx-2"
                                        value={filterCountry}
                                        onChange={(e) => setFilterCountry(e)}
                                        style={{ width: 200 }}
                                    >
                                        <Option value="country" disabled="true">Select Country</Option>
                                        {
                                            country?.map((item, idx) => (
                                                <Option key={idx} value={item}>{item}</Option>
                                            ))
                                        }
                                    </Select>
                                    <Button 
                                        disabled={filterCountry==='country' && filterState === 'state'} 
                                        className="rounded-md mx-2" 
                                        type="dashed" 
                                        danger 
                                        onClick={() => { setFilterState('state'); setFilterCountry('country')}}
                                    >Clear Filter</Button>
                                </>    
                            )
                        }
                    </div>
                    {
                        (!blogs.length && !orgs.length && !preload) ?
                            <h3 className="px-20 text-xl text-center w-full text-gray-600 py-10"
                            >No Blogs and Organizations Found
                            </h3>
                        : (!blogs.length && orgs.length && tab === "blogs" && !preload) ?
                            <h3 className="px-20 text-xl text-center w-full text-gray-600 py-10"
                            >No Blogs Found
                            </h3>
                        :  (blogs.length && !orgs.length && tab === "orgs" && !preload) ?
                            <h3 className="px-20 text-xl text-center w-full text-gray-600 py-10"
                            >No Orgnizations Found
                            </h3>
                        : null
                    }
                    {
                        preload && (
                            <h3 className="px-20 text-xl text-center w-full text-gray-600 py-10"
                            >Searching through assistance ...
                            </h3>
                        )
                    }
                    {
                        tab === "blogs" && blogs.length ?
                    <div className="px-2 w-full lg:w-1/2 relative flex flex-col">
                        {
                            (blogFilterText!=='filter' ? blogs?.filter(item => item.category === blogFilterText) : blogs)?.filter(item => checkAvailability(item?.active_from, item?.active_to) || []).map((item, idx) => (
                                <SearchResultItem
                                    id={item?.id}
                                    title={item?.title}
                                    category={item?.category}
                                    description={item?.description || 'description'}
                                    image={validateURL(item?.logo) ? item?.logo : assistanceIcon}
                                    url={`/${item.url_key}`}
                                />
                            ))
                        }  
                        {
                            blogPages > 10 && (
                                <Pagination 
                                    className="mt-2"
                                    current={currentBPage} 
                                    onChange={(e) => setCurrentBPage(e)} 
                                    total={blogPages} 
                                /> 
                            )
                        }                     
                    </div>
                    : null }
                    {
                        tab === "orgs" && orgs.length ?
                    <div className="px-2 w-full lg:w-1/2 relative flex flex-col">
                        {
                            getMeFilteredOrgs().map((item, idx) => (
                                <SearchResultItem
                                    map={true}
                                    id={item?.id}
                                    title={item?.title}
                                    category={categories?.filter(category => category.id === item?.category)[0]?.title || 'Category'}
                                    description={item?.description || 'description'}
                                    image={validateURL(item?.logo) ? item?.logo : orgLogo}
                                    url={`/organization/${item?.url_key}`}
                                    mapData={item?.city?item:null}
                                />
                            ))
                        }     
                        {
                            orgPages > 10 && (
                                <Pagination 
                                    className="mt-2"
                                    current={currentOPage} 
                                    onChange={(e) => setCurrentOPage(e)} 
                                    total={orgPages} 
                                /> 
                            )
                        }                     
                    </div>
                    : null }
                    {
                        tab === "maps" && (
                            <Maps
                                data={orgs} 
                            />
                        )
                    }
                </div>
                
            </div>           
        </div>
    )
}

export default Search
