/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { Button, Breadcrumb, List, Switch, Popconfirm, message, Tooltip, Input } from 'antd'
import { PlusOutlined, EditOutlined, CheckCircleTwoTone,ThunderboltTwoTone,EyeTwoTone, DeleteOutlined, MessageTwoTone } from '@ant-design/icons'
import moment from 'moment'
import '../../stylesheets/RSSFeed.css'
import { constructBreadCrumb, authorizeCheck, injectToSitemap } from '../../utils/Helper'
import FeedModal from './FeedModal'
import { useDataLayerValue } from '../../DataLayer'
import apiInstance from '../../api'
import ViewFeed from './ViewFeed'

const { Search } = Input;

function RSSFeed() {    
    const [addFeedModal, setAddFeedModal] = useState(false);
    const [updateFeedModal, setUpdateFeedModal] = useState(false);
    const [viewFeedModal, setViewFeedModal] = useState(false);
    const [currentFeed, setCurrentFeed] = useState(null);
    const [load, setLoad] = useState(false);
    const [progress, setProgress] = useState(0);
    const [feedLoad, setFeedLoad] = useState(true);

    const [filterFeed, setFilterFeed] = useState([]);

    const [{ permissions, categories, selectedCategory, allKeywordsStatus}, dispatch] = useDataLayerValue();        

    async function getFeedByCategory() {
        setFeedLoad(true);
        if (selectedCategory) {
            apiInstance.get(`/feed/read/category/${selectedCategory}`)
            .then(response => {
              if(response.data.status) {                              
                setFeedLoad(false);
                const newFilteredFeeds = response.data.data;                
                // sorting due to updated at descending order
                const sortedFeeds = newFilteredFeeds.sort((itemA, itemB) => {
                    return new Date(itemB.modified_at) - new Date(itemA.modified_at);
                });
                setFilterFeed(sortedFeeds);                
              } else {
                setFeedLoad(false);
                // window.location.replace('/fallback');
                console.log(response.data?.data||response?.status);            
              }
            })
            .catch(e => {
            setFeedLoad(false);
              console.log(e);
              if(e.response || e.response?.data) {                          
                  // message.error(e.response.data.message);
                  if(e.response.status === 401) {
                      sessionStorage.clear();
                      window.location.replace("/admin");
                  }
              } else {
                // history.push('/');
              }
            });  
        }        
    } 
    
    useEffect(() => {               
          getFeedByCategory();      
    },[selectedCategory]);

    /**
     * Used to handle the deletion of a particular
     * feed item based on the selection
     * @param {*} e 
     */
    const handleDelete = e => {
        setLoad(true);
        apiInstance.delete(`/${e}`)
        .then(response => {
            if(response.data.status) {                                             
                message.success(response.data.data);
                getFeedByCategory();
            } else message.warning(response.data?.data || 'something went wrong');
            setLoad(false);
        })
        .catch(e => {
            setLoad(false);
            console.log(e);
            if(e.response || e.response?.data) {                
                message.error(e.response.data.message);
                if(e.response.status === 401) {
                    sessionStorage.clear();
                    window.location.replace("/admin");
                }
            } else message.error("Internal Server Error");
        });
    }

    /**
     * Used to handle the updatiion modal of a particular
     * feed item based on the selection
     * @param {boolean, feed} e 
     */
    const openUpdateModal = (e, data) => {        
        setCurrentFeed(data); // passing the selected feed id
        setUpdateFeedModal(e); // opening the modal          
    }

    /**
     * Used to create a feed using the given details
     * without keyword mapping
     * @param {*} data 
     */
    const createAFeed = (data) => {        
        setLoad(true);        
        apiInstance.post("/feed", data , {
            headers: {
                'Content-Type': 'multipart/form-data'
              }
        })
        .then(response => {
            if(response.data.status) {                                             
                message.success(response.data.data);
                dispatch({
                    type: 'SET_ALL_KEYWORDS_STATUS',
                    status: !allKeywordsStatus
                });
                getFeedByCategory();
                setAddFeedModal(false);
            } else message.warning(response.data?.data || 'something went wrong');
            setLoad(false);
        })
        .catch(e => {            
            setLoad(false);
            console.log(e);
            if(e.response || e.response?.data) {                
               if(typeof e.response.data.message === 'string') message.error(e.response.data.message);
                if(e.response.status === 401) {
                    sessionStorage.clear();
                    window.location.replace("/admin");
                }
            } else message.error("Internal Server Error");
        });
    }

    /**
     * Used to update a feed using the given details     
     * @param {*} data 
     */
    const updateAFeed = (data, id) => {        
        setLoad(true);
        apiInstance.put(`/feed/${id}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
              }
        })
        .then(response => {
            if(response.data.status) {                                             
                message.success(response.data.data);
                getFeedByCategory();
                dispatch({
                    type: 'SET_ALL_KEYWORDS_STATUS',
                    status: !allKeywordsStatus
                });
                setUpdateFeedModal(false);
            } else message.warning(response.data?.data || 'something went wrong');
            setLoad(false);
        })
        .catch(e => {
            setLoad(false);
            console.log(e);
            if(e.response || e.response?.data) {                
                if(typeof e.response.data.message === 'string') message.error(e.response.data?.message||"bad");
                if(e.response.status === 401) {
                    sessionStorage.clear();
                    window.location.replace("/admin");
                }
            } else message.error("Internal Server Error");
        });
    }

    const actionFromModal = (flag, type, payloadData, id) => {        
        switch(type) {
            case 'create':  createAFeed(payloadData);                            
                            break;                            
            case 'update':  updateAFeed(payloadData, id);                            
                            break;
            default: setAddFeedModal(false);
                     setUpdateFeedModal(false);
                     setCurrentFeed(null);
        }
    }

    /**
     * Used to change the current status of
     * a feed based on provided id
     * @param {*} id 
     */
    const changeStatusOfAFeed = (data, id) => {
        setLoad(true);
        apiInstance.put(`/feed/status/${id}`, data)
        .then(response => {
            if(response.data.status) {
                setLoad(false);
                message.success(response.data.data);                
                setCurrentFeed(null);   
                setFilterFeed(injectCorrections(filterFeed, 'is_active', data.status, id));
            } else message.warning(response.data?.data || 'something went wrong');
            setLoad(false);          
        })
        .catch(e => {
            setLoad(false);
            console.log(e);
            if(e.response || e.response?.data) {                
                message.error(e.response.data.message);
                if(e.response.status === 401) {
                    sessionStorage.clear();
                    window.location.replace("/admin");
                }
            } else message.error("Internal Server Error");
        });        
    }

    const injectCorrections = (data, key, value, id) => {
        const feedItemIdx = data.findIndex(item => item.id === id);
        let items = [...filterFeed];
        let item = {...items[feedItemIdx]};
        item[key] = value;
        items[feedItemIdx] = item;        
        return items;        
    }

    /**
     * Used to modify the approval status
     * of a specific feed selected
     * @param {status} data 
     * @param {id} id 
     */
    const changeApprovalStatus = (data, id) => {
        setLoad(true);
        apiInstance.put(`/feed/approval/${id}`, data)
        .then(response => {
            if(response.data.status) {
                setLoad(false);
                message.success(response.data.data);                
                setCurrentFeed(null);
                injectToSitemap(data?.get('title'), `https://assistance.org/${data?.get('urlKey')}`, 'feeds');
                // UPDATE THE DATA LAYER WITH NEW FEED ADDED
                setFilterFeed(injectCorrections(filterFeed, 'approval', data.status, id));
            } else message.warning(response.data?.data || 'something went wrong');
            setLoad(false);          
        })
        .catch(e => {
            setLoad(false);
            console.log(e);
            if(e.response || e.response?.data) {                
                message.error(e.response.data.message);
                if(e.response.status === 401) {
                    sessionStorage.clear();
                    window.location.replace("/admin");
                }
            } else message.error("Internal Server Error");
        });      
    }

    const changeCommentStatus = (data, id) => {
        apiInstance.put(`/feed/commentlock/${id}`, data)
        .then(response => {
            if(response.data.status) {            
                message.success(response.data.data);                
                setCurrentFeed(null);
                // UPDATE THE DATA LAYER WITH NEW FEED ADDED
                setFilterFeed(injectCorrections(filterFeed, 'is_comment_locked', data.status, id));
            } else message.warning(response.data?.data || 'something went wrong');            
        })
        .catch(e => {            
            console.log(e);
            if(e.response || e.response?.data) {                
                message.error(e.response.data.message);
                if(e.response.status === 401) {
                    sessionStorage.clear();
                    window.location.replace("/admin");
                }
            } else message.error("Internal Server Error");
        });   
    }

    const viewFeedDetails = (data) => {
        setCurrentFeed(data);
        setViewFeedModal(true);
    }

    const openPreview = (id, url, type) => {
        if (type === 'External Blog') {
            window.open(url);
        } else {
            let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
                        width=0,height=0,left=-1000,top=-1000`;
            window.open(`${window.location.protocol}//${window.location.host}/${id}`, 'Feed Preview', params);            
        }
    }

    const onSearch = value => {        
        if(value) {
            const newFeedFiltered = filterFeed
                                .filter(feed =>
                                    (feed.title.toLowerCase().includes(value.toLowerCase()))                                    
                                );
            setFilterFeed(newFeedFiltered);   
        } else {
           getFeedByCategory();
        }
    };

    const onNullSearch = (value) => {
            if(!value) {                
                getFeedByCategory();
            }            
    }

    return (
        <div className="w-full h-full">

            <div className="flex items-center mb-4">
            {
                authorizeCheck(permissions, 'feed:create') && (
                    <Button
                        onClick={() => setAddFeedModal(true)}
                        className="flex items-center"
                        type="primary"                
                    ><PlusOutlined /> Add                  
                    </Button>
                )
            }                
                <Breadcrumb 
                    separator=">"
                    className="mx-4">
                    {
                        selectedCategory &&
                        constructBreadCrumb(categories, selectedCategory).reverse().map((category, index) => (
                            <Breadcrumb.Item key={index}>{category}</Breadcrumb.Item>
                        ))
                    }
                </Breadcrumb>          
            </div>
            {
                !!selectedCategory && (
                    <Search                         
                        onChange={e => onNullSearch(e.target.value)}
                        className="w-1/2 text-sm mb-2"                
                        placeholder="Search Feed"                         
                        onSearch={onSearch} 
                        enterButton 
                    />
                )
            }            
            {
                selectedCategory ?
                (
                    <List    
                loading={feedLoad}               
                itemLayout="horizontal"
                dataSource={filterFeed}
                size="small"
                pagination={{
                    onChange: page => {},
                    pageSize: 4
                }}
                renderItem={ item => (
                    <List.Item
                        actions={[  
                            <>
                            {
                                authorizeCheck(permissions, 'feed:update') && (
                                    <Tooltip title={item.approval?'Published':'Pending'}>
                                        <Popconfirm
                                        okButtonProps={{
                                            loading: load
                                        }}
                                        onConfirm={() => changeApprovalStatus({status: item.approval?false:true}, item.id)}
                                        icon={<ThunderboltTwoTone />}
                                        title={item.approval?'UnPublish':'Publish'}
                                        okText="Yes"
                                        cancelText="Cancel"
                                        ><CheckCircleTwoTone 
                                            title="Approval"
                                            twoToneColor={item.approval?'#52c41a':'#eb2f96'} 
                                        />                                        
                                        </Popconfirm>
                                    </Tooltip>
                                )
                            }
                            </>,
                                    <Tooltip title={item.is_comment_locked?'Locked':'Allowed'}>
                                        <Popconfirm
                                        okButtonProps={{
                                            loading: load
                                        }}
                                        onConfirm={() => changeCommentStatus({status: item.is_comment_locked?false:true}, item.id)}
                                        icon={<MessageTwoTone />}
                                        title={item.is_comment_locked?'UnLock':'Lock'}
                                        okText="Yes"
                                        cancelText="Cancel"
                                        ><MessageTwoTone
                                            title="Allowed"
                                            twoToneColor={item.is_comment_locked?'#eb2f96':'#52c41a'} 
                                        />                                        
                                        </Popconfirm>
                                    </Tooltip>                                    
                                    ,
                                    <EyeTwoTone 
                                        onClick={() => openPreview(item.url_key, item.url, item.feed_type)}
                                        title="Preview" />,
                                    <>
                                    {
                                        authorizeCheck(permissions, 'feed:update') && (
                                            <Switch
                                                title="Status"
                                                loading={load}
                                                onChange={e => changeStatusOfAFeed({status: e?1:0}, item.id)}                                                                      
                                                size="small"                              
                                                defaultChecked={item.is_active} 
                                                checked={item.is_active}                                       
                                            />
                                        )
                                    }  
                                    </>,
                                    <>        
                                    {
                                        authorizeCheck(permissions, 'feed:update') && (
                                            <a                                             
                                            onClick={() => openUpdateModal(true, item)}
                                            ><EditOutlined 
                                                title="Modify"
                                            />
                                            </a>
                                        )
                                    }
                                    </>                                                                                                   
                                    // , 
                                    // <Popconfirm
                                    //     title="Are you sure?"
                                    //     onConfirm={() => handleDelete(item.id)}
                                    //     okText={"Yes"}                                        
                                    //     cancelText={"No"}
                                    // ><a href=""><DeleteOutlined /></a> 
                                    // </Popconfirm>
                                ]}
                    >
                        <List.Item.Meta
                            title={<div className="flex flex-col items-start">
                                <p className="text-sm topFeedBox__title">{item?.title}</p>
                                <a className="text-xs" onClick={() => viewFeedDetails(item)}>{item?.url || `${window.location.protocol}//${window.location.host}/${item.url_key}`}</a>
                            </div>}
                            description={<p className="text-xs">Last updated by {item.modified_name} at {moment(item.modified_at).format('DD MMM YYYY')}</p>}
                        >                            
                        </List.Item.Meta>
                    </List.Item>
                )}
            />
                ) : 
                <p className="text-gray-700 font-bold text-lg text-center p-10">
                    Select Category to preview feeds
                </p>
            }

            {
                addFeedModal
                ?
                <FeedModal 
                    progress={progress}
                    load={load}                   
                    type="create"
                    modalTitle="Create a Feed"
                    okLabel="Create"
                    trigger={addFeedModal}
                    callTrigger={actionFromModal}
                /> : null
            }

            {
                updateFeedModal
                ?
                <FeedModal                    
                    load={load}                   
                    type="update"
                    data={currentFeed}
                    modalTitle="Update Feed"
                    okLabel="Update"
                    trigger={updateFeedModal}
                    callTrigger={actionFromModal}
                /> : null
            }
            {
                viewFeedModal
                ?
                <ViewFeed
                    data={currentFeed}
                    trigger={viewFeedModal}
                    callTrigger={setViewFeedModal}
                />
                : null
            }
        </div>
    )
}

export default RSSFeed
