import React from 'react'
import { Modal, Tag, Image, Typography, Row, Col, Tooltip } from 'antd'
import { useDataLayerValue } from '../../DataLayer'
import moment from 'moment'
import { CheckCircleTwoTone } from '@ant-design/icons'
import assistanceIcon from '../../assets/img/icon.png'

const { Text } = Typography;

function ViewFeed({ data=null, callTrigger = ct => ct, trigger }) {
        
    const [{ categories }] = useDataLayerValue();

    return (
        <Modal  
            width={700}          
            destroyOnClose={true}
            title="Feed"            
            cancelText="Close"            
            visible={trigger}
            onCancel={() => callTrigger(false)}
            okButtonProps={{ style: { display: 'none' } }}            
        >
            <div className="w-full flex flex-col justify-center">
                <Row>
                    <Image                        
                        width={110} 
                        height={110}
                        src={data.feed_type === 'Internal Blog'? assistanceIcon : data?.image||assistanceIcon}                    
                    />
                    <div className="w-4/6 flex flex-col items-start mx-4">
                        <a  
                            rel="noopener noreferrer"
                            target="_blank"
                            href={data?.url||`/feed/${data.id}`}
                            className="font-bold text-lg text-left py-1 flex items-center">
                        {data?.title||'N/A'} <Tooltip title={data.approval?'Published':'Pending'}>
                                                <CheckCircleTwoTone title="Approval"
                                                            className="mx-2"
                                                            twoToneColor={data.approval?'#52c41a':'#eb2f96'} />                     
                                            </Tooltip>
                        </a>
                        <p 
                            className="text-md text-gray-600"
                            >{data?.description}
                        </p>
                    </div>
                </Row>
                <div className="flex items-start my-4">
                        Category :
                        <Tag 
                            className="mx-2"
                            title="Category"
                            color="blue"
                        >{categories.filter(c=> c.id===parseInt(data.category_id))[0].title||'N/A'}
                        </Tag>
                        Type of Feed:                        
                        <Tag 
                            title="Feed Type"
                            color="geekblue" 
                            className="mx-2"
                        >{data?.feed_type||'N/A'}
                        </Tag>
                        Status:                        
                        <Tag 
                            title="Feed Status"
                            color={data.is_active?'green':'red'} 
                            className="mx-2"
                        >{data?.is_active?'Active':'In Active'||'N/A'}
                        </Tag>
                </div>                                
                <Row className="py-1" gutter={8}>
                    <Col>
                        Created at: <strong>{moment(data.created_at).format('lll')}</strong>
                    </Col>
                    <Col>
                        Last Updated at: <strong>{moment(data.modified_at).format('lll')}</strong>
                    </Col>
                </Row>
                <Row className="my-1 flex">
                    This feed is active {data.active_to?'during ':'from '} <Text className="mx-1" mark>{moment(data.active_from).format('DD MMM YYYY')}</Text> {data.active_to ? 'to' : null} {data.active_to?<Text className="mx-1" mark>{moment(data.active_to).format('DD MMM YYYY')}</Text>:null}
                </Row>
            </div>            
        </Modal>
    )
}

export default ViewFeed
