import React, { useEffect } from 'react';
import { SmileOutlined } from '@ant-design/icons';
import Header from '../components/User/Header';
import { Result } from 'antd';
import { Link, useLocation } from 'react-router-dom';

function RegisterFallback() {

    let query = new URLSearchParams(useLocation().search);

    useEffect(() => {

        document.title = "Account Creation - Assistance.org"
        
        if (sessionStorage.getItem('assistance_current_registration_helper_flag') !== query.get('c')) {
            window.location.replace("/");
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <>
            <Header />
            {
                !!query.get('c') && (
                    <Result 
                        className="w-1/2 mx-auto"
                        icon={<SmileOutlined />}
                        title={`Assistance account successfully created`}
                        subTitle={<p className="text-md text-black text-center">{`Hello ${query.get('u')|| "user"}, we have sent an email to your registered email address in order to verify your account.
                                    For more information email us at contact@assistance.org`}</p>}
                        extra={[
                            <Link to="/" key="console" className="primary-bg rounded-md text-white px-8 mx-2 py-2 hover:text-gray-400 my-2">
                                Go to Home
                            </Link>,
                        ]}
                    />
                )
            }
        </>
    )
}

export default RegisterFallback
