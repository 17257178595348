import React from 'react'
import { constructBreadCrumb } from '../../utils/Helper'
import { useDataLayerValue } from '../../DataLayer'
import { Breadcrumb } from 'antd'
import '../../stylesheets/CategoryHeader.css'

function CategoryHeader({ title, id, image }) {

    const [{ categories }] = useDataLayerValue();  

    return (
        <div className="w-full text-white py-12 px-8 lg:px-24 flex items-center justify-between categoryHeader__banner"
            style={{ background: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('${image?`${process.env.REACT_APP_SERVER_URL}${image}`:'https://images.unsplash.com/photo-1582803824594-65b5b4632cad?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1951&h=300&q=80'}')`}}
        >
            <h5 className="text-white font-semibold text-lg text-right tracking-wide"
            >{title}                
            </h5>
            <p className="text-md font-medium text-left text-gray-400"
            >{
                constructBreadCrumb(categories, id).length ?
                constructBreadCrumb(categories, id)
                .reverse()
                .map((category, index) => (
                    <Breadcrumb.Item      
                        target="self"
                        href={`/article/${categories.filter(c => c.title === category)[0].title}`}
                        key={index+parseInt(id)}
                        separator={'/'}
                    >{category}
                    </Breadcrumb.Item>
                )) :
                <Breadcrumb.Item href={`/article/${title}`} separator={'/'}>{title}</Breadcrumb.Item>                
            }
            </p>
        </div>
    )
}

export default CategoryHeader
