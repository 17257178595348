import React, { useEffect, useState } from 'react'
import Header from '../components/User/Header'
import { useParams, Link } from 'react-router-dom'
import apiInstance from '../api'
import { CheckCircleOutlined, CheckCircleTwoTone } from '@ant-design/icons'
import { message, Result, Skeleton } from 'antd'
import moment from 'moment'
import Footer from '../components/User/Footer'
import orgLogo from '../assets/img/org.png'
import { useDataLayerValue } from '../DataLayer'

function OrganizationView() {

    let { id } = useParams();

    const [{ categories }] = useDataLayerValue();

    const [org, setOrg] = useState(null);
    const [notFound, setNotFound] = useState(false);
    const [load, setLoad] = useState(true);

    useEffect(() => {
        document.title = 'Organization - Assistance';
        async function getOrgData() {
            setLoad(true);
            apiInstance
                .get(`/organization/key/${id}`)
                .then(response => {
                    setLoad(false);
                    if (response?.data?.data) {
                        document.title = `${response?.data?.data?.name} - Assistance`;
                        setOrg(response.data?.data);
                    } else {
                        setNotFound(true);
                        message.warning("Organization Not Available");
                    }
                })
                .catch(e => {
                    setLoad(false);
                    if (e.response?.status === 404) setNotFound(true);
                    console.log(e);
                });
        }
        getOrgData();
    },[id]);

    return (
        <>
            <Header />
            <div className="px-3 lg:px-24 w-full flex flex-col items-center justify-center">
                    { 
                            load ? (
                                <>
                                        <Skeleton loading={true} active className="border-b mb-4">
                                            <h4>Assistace.org Sample Title</h4>                                    
                                        </Skeleton>                            
                                </>
                            ) : 
                                !notFound ? (
                        <>
                            <div className="w-full flex items-start mt-10">
                                <img 
                                    alt="org"
                                    className="object-contain w-16 h-16 mx-4 mt-2"                                
                                    src={org?.logo||orgLogo}
                                />    
                                <div className="w-full flex flex-col items-start">
                                        <h1 className="font-bold text-xl lg:text-3xl text-left mb-1">
                                            {org?.name}
                                        </h1>
                                        {org?.description && (
                                            <p className="text-gray-700 text-left mb-1 text-md">
                                                {org?.description}
                                            </p>
                                        )}
                                        <div className="flex lg:flex-row flex-col items-start lg:items-center">
                                            <div className="flex items-center px-2 mt-2">
                                                {
                                                    org?.isVerified === 1 ? (
                                                        <CheckCircleTwoTone color="blue" />
                                                    ) : (
                                                        <CheckCircleOutlined />
                                                    )
                                                }
                                                
                                                <p className={`px-2 text-md ${org?.isVerified===1?'text-black':'text-gray-600'} underline`}>{org?.isVerified===1?'Verified':'Unverified'}</p>
                                            </div>
                                            <p className="text-gray-600 lg:text-md text-sm mt-2 text-left">
                                                Last Updated at: {moment(org?.modified_at).format('lll')}
                                            </p>
                                        </div>                                
                                </div>
                            </div>
                            <div className="w-full flex lg:flex-row flex-col items-center">
                                    <div className="w-full lg:w-1/2 flex flex-col items-start py-4 lg:py-12">
                                                <div className="w-full p-4 my-4 shadow border flex flex-col items-start">
                                                    <h2 className="text-lg text-left py-2 px-1 text-black"><strong>Category:</strong> <span className="text-sm primary-bg text-white rounded-lg px-2 py-1">{categories?.filter(item => item?.id === org?.orgtype)[0]?.title || 'Category'}</span></h2>
                                                    <h2 className="text-lg text-left py-2 px-1 text-black"><strong>Website:</strong> <a target="_blank" rel="noopener noreferrer" href={org?.url}>{org?.url}</a></h2>
                                                    {org?.email && (<h2 className="text-lg text-left py-2 px-1 text-black"><strong>Email:</strong> <a href={`mailto:${org?.email}`}>{org?.email}</a></h2>)}
                                                    {org?.phone && (<h2 className="text-lg text-left py-2 px-1 text-black"><strong>Phone:</strong> {org?.phone}</h2>)}
                                                    {org?.contact_name && (<h2 className="text-lg text-left py-2 px-1 text-black"><strong>Contact Name:</strong> {org?.contact_name}</h2>)}
                                                </div>
                                    </div>
                                    <div className="w-full lg:w-1/2 flex flex-col items-start mx-2 lg:mb-0 mb-10">
                                        <iframe
                                            className="w-full h-40 lg:h-64 lg:mt-2"
                                            title="embed map"
                                            style={{ border: 0 }}
                                            loading="lazy"
                                            allowFullScreen={true}
                                            src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_EMBED_MAP_API}
                                                &q=${org?.line_1.replace(' ', '+')},${org?.city},${org?.state}+${org?.country}`}>
                                        </iframe>                             
                                    </div>
                            </div>
            </> ): (
                <Result
                    status="warning"
                    title="Organization Not Found."
                    extra={
                        <Link to="/" key="console" className="primary-bg rounded-md text-white px-8 mx-2 py-2 hover:text-gray-400 my-2">
                            Back to Home
                        </Link>
                    }
                />  
            )
            }
            <Footer />
            </div>
        </>
    )
}

export default OrganizationView
