import React,{ useState } from 'react'
import CrudUI from './CrudUI'
import apiInstance from '../../../api'
import { useDataLayerValue } from '../../../DataLayer'
import { message } from 'antd'

function FeedTypes() {

    const [{ allFeedTypes, allFeedTypesStatus}, dispatch] = useDataLayerValue();

    const [load, setLoad] = useState(false);
    const [addModalTrigger, setAddModalTrigger] = useState(false);
    const [updateModalTrigger, setUpdateModalTrigger] = useState(false);
    const [currentItem, setCurrentItem] = useState(null); 

    /**
     * Used to create a feed types using the 
     * standard API
     * @param {*} data 
     */
    const createAFeedType = (data) => {        
        setLoad(true);
        apiInstance.post("/feedtype", data)
        .then(response => {
            if(response.data.status) {
                setLoad(false);
                message.success(response.data.data);
                setAddModalTrigger(false);
                setCurrentItem(null);
                // UPDATE THE DATA LAYER WITH NEW FEED TYPES ADDED
                dispatch({
                    type: 'SET_ALL_FEED_TYPES_STATUS',
                    status: !allFeedTypesStatus
                });
            } else message.warning(response.data?.data || 'something went wrong');
            setLoad(false);          
        })
        .catch(e => {
            setLoad(false);
            console.log(e);
            if(e.response || e.response?.data) {                                    
                if(e.response.status === 401) {
                    alert('Your Session is expired!');
                    sessionStorage.clear();                            
                    window.location.replace("/signin");                                     
                } else {
                    if(typeof e.response?.data?.message === 'string') {
                        message.error(e.response.data.message);        
                    } else {
                        message.warn("Something went wrong");
                    }                        
                }
            } else message.error("Internal Server Error");  
        });        
    }

    /**
     * Used to update a feed types using the
     * standard API
     * @param {id} id
     * @param {*} data 
     */
    const updateAFeedType = (id, data) => {        
        setLoad(true);
        apiInstance.put(`/feedtype/${id}`, data)
        .then(response => {
            if(response.data.status) {
                setLoad(false);
                message.success(response.data.data);
                setUpdateModalTrigger(false);
                setCurrentItem(null);
                // UPDATE THE DATA LAYER WITH NEW FEED TYPES ADDED
                dispatch({
                    type: 'SET_ALL_FEED_TYPES_STATUS',
                    status: !allFeedTypesStatus                    
                });
            } else message.warning(response.data?.data || 'something went wrong');
            setLoad(false);            
        })
        .catch(e => {
            setLoad(false);
            console.log(e);
            if(e.response || e.response?.data) {                                    
                if(e.response.status === 401) {
                    alert('Your Session is expired!');
                    sessionStorage.clear();                            
                    window.location.replace("/signin");                                     
                } else {
                    if(typeof e.response?.data?.message === 'string') {
                        message.error(e.response.data.message);                    
                    } else {
                        message.warn("Something went wrong");
                    }                        
                }
            } else message.error("Internal Server Error"); 
        });        
    }

    /**
     * Used to soft delete a feed types using the
     * standard API
     * @param {*} id 
     */
    const deleteAFeedType = (id) => {
        setLoad(true);
        apiInstance.put(`/feedtype/delete/${id}`)
        .then(response => {
            if(response.data.status) {
                message.success(response.data.data);
                setLoad(false);                
                setCurrentItem(null);
                // UPDATE THE DATA LAYER WITH NEW FEED TYPES ADDED
                dispatch({
                    type: 'SET_ALL_FEED_TYPES_STATUS',
                    status: !allFeedTypesStatus                    
                });            
                return true;    
            } else message.warning(response.data?.data || 'something went wrong');
            setLoad(false);
        })
        .catch(e => {            
            setLoad(false);
            console.log(e);
            if(e.response || e.response?.data) {                                    
                if(e.response.status === 401) {
                    alert('Your Session is expired!');
                    sessionStorage.clear();                            
                    window.location.replace("/signin");                                     
                } else {
                    if(typeof e.response?.data?.message === 'string') {
                        message.error(e.response.data.message);                       
                    } else {
                        message.warn("Something went wrong");
                    }                        
                }
            } else message.error("Internal Server Error"); 
            return false;
        });        
    }

    /**
     * Used to change the current status of
     * a feed types based on provided id
     * @param {*} id 
     */
    const changeStatusOfAFeedType = (data, id) => {    
        setLoad(true);
        apiInstance.put(`/feedtype/status/${id}`, data)
        .then(response => {
            if(response.data.status) {
                setLoad(false);
                message.success(response.data.data);                
                setCurrentItem(null);
                // UPDATE THE DATA LAYER WITH NEW FEED TYPES ADDED
                dispatch({
                    type: 'SET_ALL_FEED_TYPES_STATUS',
                    status: !allFeedTypesStatus                    
                });
            } else message.warning(response.data?.data || 'something went wrong');
            setLoad(false);          
        })
        .catch(e => {
            setLoad(false);
            console.log(e);
            if(e.response || e.response?.data) {                                    
                if(e.response.status === 401) {
                    alert('Your Session is expired!');
                    sessionStorage.clear();                            
                    window.location.replace("/signin");                                     
                } else {
                    if(typeof e.response?.data?.message === 'string') {
                        message.error(e.response.data.message);                      
                    } else {
                        message.warn("Something went wrong");
                    }                        
                }
            } else message.error("Internal Server Error"); 
        });        
    }

    return (
        <div className="w-full">
            <CrudUI
                title="Feed Type"
                utility="Feed Types"
                data={allFeedTypes}
                addModalTrigger={addModalTrigger}
                updateModalTrigger={updateModalTrigger} 
                load={load}                 
                setAddModalTrigger={setAddModalTrigger}
                setUpdateModalTrigger={setUpdateModalTrigger}
                createItem={createAFeedType}
                updateItem={updateAFeedType}
                deleteItem={deleteAFeedType}
                changeStatus={changeStatusOfAFeedType}
                currentItem={currentItem}
                setCurrentItem={setCurrentItem}
            />
        </div>
    )
}

export default FeedTypes
