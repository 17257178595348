import React, { useState } from 'react'
import Header from '../components/User/Header'
import { Input, message, Result } from 'antd'
import { Link } from 'react-router-dom'
import { validateEmail } from '../utils/Helper'
import '../stylesheets/Login.css'
import apiInstance from '../api'
import { SmileOutlined } from '@ant-design/icons';

function ForgetPassword() {
    
    const [email, setEmail] = useState('');
    const [buttonLabel, setButtonLabel] = useState('Send Password Reset Link');
    const [fallback, setFallback] = useState(false);

    const handleResetRequest = (e) => {
        e.preventDefault();
        setButtonLabel('Please Wait...');
        let payload = {
            email
        };
        if (email) {

            if (validateEmail(email)) {

                apiInstance
                    .post('/auth/password/reset/request', payload)
                    .then(response => {
                        setButtonLabel('Send Password Reset Link');
                        if (response.data.status) {
                            message.success(response.data.message);
                            setFallback(true);
                        } else {
                            message.error(response.data?.message || 'Something went wrong!');
                        }
                    })
                    .catch(e => {
                        message.error(e.response.data?.message || 'Something went wrong!');
                        setButtonLabel('Send Password Reset Link');
                        setEmail('');                    
                        console.log(e);
                    });


            } else {
                message.warn('Invalid email address');
            }

        } else {
            message.warn('Email cannot be empty');
        }
    }

    return (
        <>
            <Header />             
            <div className="w-full flex flex-row items-center justify-center">
                {
                    fallback ?
                    (
                        <Result 
                            icon={<SmileOutlined />}
                            title="Your password reset link has been sent"
                            subTitle="Please visit your registered email address and use the link to reset your password."
                            extra={[
                                <Link to="/" key="console" className="primary-bg rounded-md text-white px-8 mx-2 py-2 hover:text-gray-400 my-2">
                                    Go to Home
                                </Link>,
                            ]}
                        />
                    ) :
                    (
                        <div 
                            className="lg:w-1/3 md:w-1/2 bg-white border rounded-lg px-2 py-4 my-8
                                        flex flex-col items-center shadow-md"
                        >
                            <h1 className="text-lg text-black py-3">
                                Reset Password
                            </h1>
                            <form className="w-full login">
                                <Input 
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    required                        
                                    type="email" 
                                    placeholder="Email address"
                                    className="w-full p-2 my-2 rounded-lg text-black text-md"
                                />                                           
                                <button    
                                    onClick={handleResetRequest}                                                                          
                                    htmlType="submit"                                      
                                    className="login__button w-full text-white font-medium rounded-lg text-md block primary-bg text-center
                                            p-3 my-2 cursor-pointer mb-4 focus:outline-none"
                                >{buttonLabel}                
                                </button>
                            </form>                    
                        </div>
                    )
                }                
            </div>           
        </>
    )
}

export default ForgetPassword
