import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Skeleton } from 'antd'
import Header from '../components/User/Header'
import SearchBar from '../components/User/SearchBar'
import CategorySlide from '../components/User/CategorySlide'
import Footer from '../components/User/Footer'
import HomeBox from '../components/User/HomeBox'
import TopFeed from '../components/User/TopFeed'
import apiInstance from '../api/index'
import { useDataLayerValue } from '../DataLayer'
import PreLoaderList from '../components/User/PreLoaderList'
import assistanceIcon from '../assets/img/icon.png'

function Index() {     
    
    document.title = 'Assistance - Helping others matters';

    const [posts, setPosts] = useState([]);
    const [topFeeds, setTopFeeds] = useState([]);   
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(false);

    useEffect(() => {  
        dispatch({
            type: 'SET_USER_CATEGORY',
            category: null
        });
        dispatch({
            type: 'SET_USER_CATEGORIES',
            categories: null
        });                              
        getPosts();
            // eslint-disable-next-line react-hooks/exhaustive-deps
    },[pageNumber]);

    useEffect(() => {
        sessionStorage.setItem('assistance_current_registration_helper_flag', null);
       async function getRandomFeed() {
        apiInstance.get("/homepage/feed/random")       
        .then(response => {                
            setTopFeeds(response.data.data);
        })
        .catch(e => {                
            console.log(e);                
            // message.error("Internal Server Error");
            // history.push('/fallback');
        });
       }

       getRandomFeed(); 
       // eslint-disable-next-line react-hooks/exhaustive-deps           
    },[]);

    const observer = useRef();
    const lastPostElementRef = useCallback(node => {
        if (loading) return
        if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver(entries => {
                if (entries[0].isIntersecting && hasMore)  {
                    setPageNumber(prevPageNumber => prevPageNumber + 1);
                }
            })
        if (node) observer.current.observe(node);        
    }, [loading, hasMore]);

    const [{ categories }, dispatch] = useDataLayerValue();    

    async function getPosts() {
        setLoading(true);
        apiInstance.get(`/homepage/feedData?page=${pageNumber}`)       
        .then(response => {                                
            setLoading(false);            
            setPosts(prevPosts => {
                return [...prevPosts, ...response.data.data.data]
            });            
            setHasMore(response.data.data?.data?.length > 0)
        })
        .catch(e => {         
            setLoading(false);       
            console.log(e);                
            // message.error("Internal Server Error");
            // history.push('/fallback')
        });
    }        
    
    return (
        <div className="w-full bg-white">            
            <Header />
            <div className="w-full flex lg:flex-row flex-col justify-evenly lg:px-12 py-8">
                <div className="lg:hidden md:hidden w-full px-4 mb-8">
                    <SearchBar />
                </div>               
                <div className="w-full flex flex-col items-center h-full">
                    {
                        !!topFeeds.length && !!categories.length ?
                        <TopFeed data={topFeeds.filter(feed => !!feed)} /> :
                        (
                            <Skeleton loading={true} active className="lg:px-16 border-b pb-3 mb-4">
                                    <div>
                                        <h1>Assistance.org Sample Title</h1>
                                        <p>
                                        This is just a simple test description for the pre loader Skeleton
                                        used here dont care about this piece of text.
                                        </p>
                                    </div>                                
                            </Skeleton>
                        )
                    }
                    <div className="w-full lg:hidden md:hidden px-4 mt-3 mb-8">
                        <CategorySlide />
                    </div>                    
                    {
                        !!posts.length ?
                            posts.filter(post => !!post).map((post, index) => (                            
                            categories.length && (
                                (posts.length === index + 1) ? (                                    
                                    <HomeBox
                                        ref={lastPostElementRef}
                                        subTitle={post?.rawFeedTitle}
                                        icon={post?.rawImage||assistanceIcon}                                    
                                        url={post?.link||post.rawFeedUrl}
                                        created={post?.pubDate||post?.isoDate||null}
                                        key={index}
                                        title={post?.title}
                                        category={categories.filter(c => c.id === post?.rawCategory)[0]?.title || 'Assistance Blogs'}
                                        creator={post?.creator || post?.author || post?.rawFeedTitle || 'assistance'}                                
                                        description={post?.feed_type === 'Internal Blog' ? post?.description : post?.content || post?.description || post.contentSnippet || null}
                                        image={post?.image ?`data:image/jpeg;base64,${post?.image}` : null}
                                    />   
                                ) : (                                    
                                    <HomeBox                                        
                                        subTitle={post?.rawFeedTitle}
                                        icon={post?.rawImage||assistanceIcon}                                    
                                        url={post?.link||post.rawFeedUrl}
                                        created={post?.pubDate||post?.isoDate||null}
                                        key={index}
                                        title={post?.title}
                                        category={categories.filter(c => c.id === post?.rawCategory)[0]?.title || 'Assistance Blogs'}
                                        creator={post?.creator || post?.author || post?.rawFeedTitle || 'assistance'}                                
                                        description={post?.feed_type === 'Internal Blog' ? post?.description : post?.content || post?.description || post.contentSnippet || null}
                                        image={post?.image ?`data:image/jpeg;base64,${post?.image}` : null}
                                    />   
                                    )                                
                                )
                            )) :
                            (                                                                
                                <PreLoaderList />                                
                            )
                    }    
                    {
                        loading && (
                            <p className="tex-gray-800 py-10 text-lg font-medium text-center">Loading...</p>
                        )
                    }                                                                           
                </div>
                <div className="w-1/3 flex-col items-center h-full sticky top-0 hidden lg:flex md:flex mx-4">
                    <SearchBar 
                        placeholderText="What you need assistance for ?"                        
                    />
                    <CategorySlide />                    
                    <Footer 
                        type="small"
                    />
                </div>
                <div className="lg:hidden md:hidden w-full px-4 mb-8">
                    <Footer />
                </div>  
            </div>
        </div>
    )
}

export default Index
