/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Modal, Input, message } from 'antd'

function CrudModal({ utility, modalTitle, okLabel, processStatus=false, data=null, modalTrigger, callTrigger = ct => ct, type }) {
    
    const [name, setName] = useState('');          
    const [description, setDescription] = useState('');
    const [action, setAction] = useState('');
    const [oldName, setOldName] = useState('');
    const [oldAction, setOldAction] = useState('');
    const [oldDescription, setOldDescription] = useState('');

    useEffect(() => {
        if(data && utility!=='Permissions') {
            setName(data?.name||''); // UPDATES THE NAME WITH THE PRESENT DATA            
            setOldName(data?.name||''); // UPDATES PREVIOUS STATE
            setDescription(data?.description||'');
            setOldDescription(data?.description||'');
        } else {
            if (data && data?.name?.indexOf(":") !== -1) {
                let permissionSplited = data?.name?.split(":");
                setName(permissionSplited[0]);
                setAction(permissionSplited[1]);
                setOldName(permissionSplited[0]);
                setOldAction(permissionSplited[1]);
            } 
        }
    },[data]);

    /**
     * Handling the positive action of the modal
     * based on the context it is used
     */
    const handleOk = () => {
        let payload = {};
        if (name && action) {
            if(type === 'create') {
                payload = { // PAYLOAD BASED ON THE AVIALABLE DATA
                    name: `${name?.toLowerCase()}:${action?.toLowerCase()}`,
                    description: description || null
                };
            } else {
                payload = { // PAYLOAD BASED ON THE AVIALABLE DATA
                    data: { name: `${name?.toLowerCase()}:${action?.toLowerCase()}`, description: description || null },
                    id: data?.id || ''                
                };
            }
        } else {
            if (utility === 'Permissions') {
                message.warning('Inputs are missing');
            } else {
                if(type === 'create') {
                    payload = { // PAYLOAD BASED ON THE AVIALABLE DATA
                        name,
                        description: description || null
                    };
                } else {
                    payload = { // PAYLOAD BASED ON THE AVIALABLE DATA
                        data: { name, description: description || null },
                        id: data?.id || ''                
                    };
                }
            }
        }
       

        callTrigger(false, type, payload);
        setName('');
        setDescription('');
        setOldName('');
        setOldAction('');
        setOldDescription('');
    }

    /**
     * Handling the negative action of the modal
     * based on the users actions
     */
    const handleCancel = () => {
        callTrigger(false);
        setName('');
        setOldName('');
        setOldAction('');
        setOldDescription('');
        setDescription('');
        setAction('');
        setDescription('');
    }
   
    return (
        <Modal   
            maskClosable={false}         
            visible={modalTrigger}
            destroyOnClose={true}
            title={modalTitle}
            okText={okLabel}
            onOk={handleOk}
            onCancel={handleCancel}
            okButtonProps={{
                loading: processStatus,
                disabled: (((oldName === name) || !name) &&
                            ((oldDescription === description) || !name) || (utility==='Permissions'?(oldAction === action || !action):false))                
            }}
        >     
        {
            utility === 'Permissions' ?
            <div>
                <Input
                    value={name}
                    onChange={e => setName(e.target.value)}
                    placeholder="Entity (eg: person)"
                    type="text"
                    className="mb-3"
                />
                <Input
                    value={action}
                    onChange={e => setAction(e.target.value)}
                    placeholder="Action (eg: read)"
                    type="text"
                    className="mb-3"
                />
                <Input
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    placeholder="Description"
                    type="text"
                    className="mb-3"
                />
            </div>
             :
            <Input
                value={name}
                onChange={e => setName(e.target.value)}
                placeholder="Name"
                type="text"
                className="mb-3"
            />    
        }    
        </Modal>
    )
}

export default CrudModal
