/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from 'react';
import blogLogo from '../assets/img/blog.png';
import Header from '../components/User/Header'
import { Input, Select, message, Button, Pagination } from 'antd';
import { FilterFilled } from '@ant-design/icons';
import apiInstance from '../api';
import { checkAvailability } from '../utils/Helper';
import { Link } from 'react-router-dom';
import assistanceIcon from '../assets/img/icon.png'
import InternalBlogItem from '../components/User/InternalBlogItem';
import Footer from '../components/User/Footer';
import { useDataLayerValue } from '../DataLayer';

const { Option } = Select;

function SearchBlogs() {

    const [result, setResult] = useState([]);
    const [preload, setPreload] = useState(false);
    const [page, setPage] = useState(1);
    const [found, setFound] = useState(0);
    const [load, setLoad] = useState(false);

    const [{categories}] = useDataLayerValue();

    const [searchText, setSearchText] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('filter');
    const [filterCategories, setFilteredCategories] = useState([]);

    useEffect(() => {
        document.title = "Search for Blogs - Assistance";
        sessionStorage.setItem('assistance_current_registration_helper_flag', null);
    },[]);

    async function getSearchResult() {
        setLoad(true);
        setPreload(false);
        apiInstance.get(`/feed/blog/search?q=${searchText}&page=${page}`)
        .then(response => {
            setLoad(false);
            setPreload(true);
            if(response.data.status) { 
                setFound(response.data.data?.found)                
                fetchFilterBlogsIfExists(response?.data.data?.data);
                setResult(response.data.data?.data);
            } else message.warning(response.data?.data||'Something Went Wrong!');
        }) 
        .catch(e => {
            setLoad(false);
            setPreload(true);
            console.log(e);
            if(e.response || e.response?.data) {                
                message.error(typeof e.response.data.message === 'string'? e.response.data.message : 'Something went wrong' );
            } else message.error("Internal Server Error");
          });

    }

    const handleSearch = () => {
        if (searchText?.trim()) {
            getSearchResult();
        }
    }

    useEffect(() => {
        if (searchText?.trim()) {
            getSearchResult();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    function fetchFilterBlogsIfExists(data) {
        if (data?.length) {
            let tempFilter = new Set();
            let filter = [];
            data.forEach(item => {
                let filterItem = { title: item?.category, value: item?.category };
                if (!tempFilter.has(item?.category)) {
                    filter.push(filterItem);
                    tempFilter.add(item?.category);
                } 
            });
            setFilteredCategories(filter);
        }
    }

    const handleSearchText = (e) => {
        if (e.key === 'Enter' && searchText?.trim()) {
            getSearchResult();
        }
    }

    const handleReset = () => {
        setResult([]);
        setPreload(false);
        setSearchText('');
        setSelectedCategory('filter');
    }

    const CategoryItem = ({ image, title, description }) => (
        <Link
            to={`/article/${title}`} 
            className="my-2 mx-2 border bg-white shadow rounded-md p-3 flex flex-col items-center">
            <img
                alt="catgory-image"
                className="w-full h-56 object-cover"
                src={image ? `${process.env.REACT_APP_SERVER_URL}${image}` : 'https://images.unsplash.com/photo-1474552226712-ac0f0961a954?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1051&q=80'}
            />
            <h2 className="w-full text-left py-2 text-lg text-black">{title}</h2>
            <p className="w-full text-md text-gray-600 py-1 text-left">{description}</p>
        </Link>
    )

    return (
        <>
            <Header />
            <div className="w-full flex flex-col items-center primary-bg pb-4">
                    <div className="px-8 py-2 flex flex-col items-center">
                       <div className="flex items-center">
                            <img
                                alt="blogs"
                                className="h-12 contain"
                                src={blogLogo}
                            />
                            <h1 className="font-extrabold text-lg lg:text-3xl text-center lg:text-left mt-3 text-white">Assistance Blogs</h1>
                       </div>
                        <p className="text-sm lg:text-lg text-center lg:text-left py-1 text-gray-400">
                            Search for blogs that might help you out
                        </p>
                    </div> 
                    <div className="w-4/5 lg:w-1/2 flex lg:flex-row flex-col items-center justify-between">
                        <Input
                            value={searchText}
                            onKeyPress={e => handleSearchText(e)}
                            onChange={e => setSearchText(e.target.value)}
                            type="text"
                            size="large"
                            className="mx-4"
                            placeholder="Search blogs"
                        />
                        <button  
                            onClick={() => handleSearch()}                                                        
                            className="lg:my-0 my-4 mx-4 font-bold rounded-lg text-lg block sec-btn text-center
                                        py-2 px-12 cursor-pointer focus:outline-none"
                        >{!load?'Search':'Searching'}                   
                        </button> 
                        <button  
                        onClick={() => handleReset()}                                                        
                        className="mx-4 font-bold rounded-lg text-lg block bg-red-600 text-white text-center hover:bg-red-500
                                    py-2 px-12 cursor-pointer focus:outline-none"
                    >Reset              
                    </button>   
                    </div>
            </div>
            <div className={`w-full ${result.length ? 'flex' : 'hidden'} lg:flex-row flex-col items-center lg:justify-start justify-center py-2 border-b border-gray-400 mb-3 px-8 lg:px-20`}>
                        <h3 className="text-sm lg:text-md text-left text-gray-700 py-2 mr-3"
                        >{found} {(found > 1) ?'Results': 'Result'}  Found              
                        </h3> 
                                    <>
                                    <p className="text-lg px-2"><FilterFilled /></p>
                                    <Select  
                                        value={selectedCategory}
                                        onChange={(e) => setSelectedCategory(e)}
                                        style={{ width: 200 }}
                                    >
                                        <Option value="filter" disabled="true">Select by category</Option>
                                        {
                                            filterCategories?.map((item, idx) => (
                                                <Option key={idx} value={item?.value}>{item?.title}</Option>
                                            ))
                                        }
                                    </Select>
                                    <Button
                                        disabled={selectedCategory==='filter'} 
                                        className="rounded-md mx-2" 
                                        type="dashed" 
                                        danger 
                                        onClick={() => setSelectedCategory('filter')}
                                    >Clear Filter</Button>
                                </>
            </div>
            {
                !result.length && (
                    <div className="w-full grid grid-cols-1 lg:grid-cols-4 gap-8 px-4 py-10 lg:px-20">
                        {
                            categories?.map((item, idx) => (
                                <CategoryItem image={item?.image==="null"?null:item?.image} title={item?.title} description={item?.description}  key={idx} />
                            ))
                        }
                    </div>
                )
            }
            {
                (!result.length && preload) ?
                (
                    <h3 className="px-20 text-xl text-center w-full text-gray-600 py-10"
                    >No Blogs Found
                    </h3>
                ) : null
            }
             <div className="py-10 px-2 w-full lg:w-1/2 mx-auto relative flex flex-col items-center mb-4">
                {
                    (selectedCategory!=='filter' ? result?.filter(item => item.category === selectedCategory) : result)?.filter(item => checkAvailability(item?.active_from, item?.active_to) || [])?.map((item, idx) => (
                        <InternalBlogItem
                            isIcon={item?.logo ? false : true}
                            key={idx}
                            id={item?.id}
                            title={item?.title}
                            category={item?.category}
                            content={item?.content || 'description'}
                            image={item?.logo ? `${process.env.REACT_APP_SERVER_URL}${item?.logo}` : assistanceIcon}
                            url={`/${item.url_key}`}
                        />  
                    ))
                }
                 {
                            found > 10 && (
                                <Pagination 
                                    className="mt-2"
                                    current={page} 
                                    onChange={(e) => setPage(e)} 
                                    total={found} 
                                /> 
                            )
                        }     
            </div> 
            <Footer />                  
        </>
    )
}

export default SearchBlogs
