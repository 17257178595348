/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react';

function CookieBanner() {
    const [cookieFlag, setCookieFlag] = useState(window.localStorage.getItem('cookie-assistance-policy-acceptance'));

    return (
        <div className={`${cookieFlag?'hidden':'flex'} w-full flex-row items-start lg:items-center primary-bg py-2 px-4 lg:px-24 justify-between`}>
            <div className="w-full flex flex-col items-start">
                <h2 className="text-white text-sm lg:text-base font-semibold text-left w-full py-1">This website uses cookies</h2>
                <p className="w-full text-gray-300 text-xs lg:text-sm text-justify lg:pr-0 pr-4 pb-3">
                    Cookies help us to deliver the best experience on our website. By using our website, you agree to use of cookies 
                    <a href="https://assistance.org/cookie-policy" className="underline" target="_blank"> Learn More</a>.
                </p>
            </div>
            <button
                onClick={() => {
                    setCookieFlag(true);
                    window.localStorage.setItem('cookie-assistance-policy-acceptance',true);
                }} 
                className="lg:mt-0 mt-2 bg-white rounded-md primary-color text-sm font-medium px-4 py-2 shadow hover:bg-transparent hover:text-white border hover:border-gray-200 focus:outline-none">Accept</button>
        </div>
    )
}

export default CookieBanner
