import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'antd/dist/antd.css';
import './stylesheets/tailwind.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { DataLayer } from './DataLayer';
import reducer, { initialState } from './reducer';
// import { hydrate, render } from "react-dom";

const APP = (
<React.StrictMode>  
    <DataLayer reducer={reducer} initialState={initialState}>
      <App />
    </DataLayer>  
</React.StrictMode>
);

// const rootElement = document.getElementById("root");
// if (rootElement.hasChildNodes()) {
//   hydrate(APP, rootElement);
// } else {
//   render(APP, rootElement);
// }
ReactDOM.render(APP, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
