import React, { useState, useEffect } from 'react'
import { Modal, TreeSelect, Breadcrumb, Input } from 'antd'
import { generateTreeData, constructBreadCrumb } from '../../utils/Helper'
import { useDataLayerValue } from '../../DataLayer'
// image upload utilities
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginFileEncode from 'filepond-plugin-file-encode'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginImageCrop from 'filepond-plugin-image-crop'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import '../../stylesheets/FilePond.css'
import '../../stylesheets/FilePondPreview.css'

registerPlugin(FilePondPluginFileEncode, FilePondPluginImagePreview, FilePondPluginImageCrop, FilePondPluginFileValidateSize);

function CategoryModal({ type, modalTitle, okLabel, trigger, callTrigger = ct => ct, progress, data=null }) {        

    const [currentSelectedCategory, setCurrentSelectedCategory] = useState(null);    
    const [treeData, setTreeData] = useState([]);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');    
    const [image, setImage] = useState(null);
    const [files, setFiles] = useState([]);
    const [{ categories, selectedCategory }] = useDataLayerValue();    

    useEffect(() => {       

        // on component loads generates the required tree structure using the
        // given data and stores into the state
        const treeData = generateTreeData(categories);            

        // CREATING THE FALLBACK CATEGORY FOR ADDING A NEW PARENT CATEGORY
        let fallbackCategory = { title: "New", id: "new", key: "new" };
        // ADDING THE FALLBACK CATEGORY TO THE TREE DATA AT THE BEGINNING
        treeData.unshift(fallbackCategory);

        setTreeData(treeData);

        if(selectedCategory) setCurrentSelectedCategory(selectedCategory);
// eslint-disable-next-line react-hooks/exhaustive-deps
    },[]); 
    
    useEffect(() => {
       if(type === 'update') {
        let oldData;
        if(currentSelectedCategory) {
            oldData = categories.filter(c => c.id === currentSelectedCategory);
        } else oldData = categories.filter(c => c.id === selectedCategory);        
        setTitle(oldData[0]?.title||'');
        setDescription(oldData[0]?.description||'');
        setImage(oldData[0]?.image||null);
        if(oldData[0]?.image) {
            setFiles([{
                source: `${process.env.REACT_APP_SERVER_URL}${oldData[0].image}`,                                        
            }]);
        }
       }
       // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentSelectedCategory]);

    /**
     * Handling the positive action 
     * button of the modal
     */
    const handleOk = e => {
        let formObj = new FormData();
        formObj.append("id", currentSelectedCategory || selectedCategory);
        formObj.append("title", title);
        formObj.append("description", description);
        formObj.append("parent_id", currentSelectedCategory || selectedCategory);
        formObj.append("image", image||null);
        if (files.length) {
            formObj.append("file", files[0]);
        }
        if(formObj.get("parent_id") === "new") {
            formObj.set("parent_id", null);
        }
        if(type === 'update') formObj.delete("parent_id");        
        callTrigger(false, type, formObj, currentSelectedCategory || selectedCategory);
        setTitle('');
        setDescription('');
        setImage('');
        setCurrentSelectedCategory(null);
    }  

    /**
     * Handling the negative action 
     * button of the modal
     */
    const handleCancel = e => {
        setTitle('');
        setImage(null);
        setFiles([]);
        setDescription('');
        setCurrentSelectedCategory(null);
        callTrigger(false);
    }  

    /**
     * Used to set the selected category of current context
     * based on the user selection
     */
    const onChange = value => {
        setCurrentSelectedCategory(value);
    }

    return (
        <Modal
            maskClosable={false}            
            destroyOnClose={true}
            title={modalTitle}
            visible={trigger}
            onOk={handleOk} 
            onCancel={handleCancel}           
            okText={okLabel}
            okButtonProps={{
                loading: progress,
                disabled: !title
            }}
        >

            {
                type !== 'update' && (
                    <>
                    <TreeSelect                
                        className="mb-2"
                        style={{ width: '100%' }}
                        value={currentSelectedCategory}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        placeholder="Select Parent Category"
                        allowClear                
                        treeData={treeData}
                        onChange={onChange}                
                    ></TreeSelect>
                    <Breadcrumb 
                        separator=">"
                        className="mb-3"
                    >
                        {
                            currentSelectedCategory === "new"
                            ? <Breadcrumb.Item>Attempting to create a new parent category</Breadcrumb.Item>
                            : constructBreadCrumb(categories, currentSelectedCategory||selectedCategory)
                            .reverse()
                            .map((category, index) => (
                                <Breadcrumb.Item key={index}>{category}</Breadcrumb.Item>
                            ))
                        }
                    </Breadcrumb>
                    </>
                )
            }
            {type==='update'&&(<p className="text-gray-700 py-1 text-md text-left">Title:</p>)}
            <Input                                       
                onChange={e => setTitle(e.target.value)}
                value={title}                
                placeholder="Title"
                type="text"
                className="mb-3"
            />
            {type==='update'&&(<p className="text-gray-700 py-1 text-md text-left">Description:</p>)}
            <Input                 
                onChange={e => setDescription(e.target.value)}
                value={description}                
                type="text"
                placeholder="Short Description"
                className="mb-3"
            />
            <p className="text-gray-700 py-1 text-md text-left">Image: (Optional)</p>
            <FilePond       
                name="file"                                            
                files={files}          
                allowFileSizeValidation={true}
                maxFileSize="1MB"
                imageCropAspectRatio="16:9"
                allowMultiple={false}
                allowImageCrop={true}
                maxFiles={1}                
                onupdatefiles={(fileItems) => {
                    if (fileItems.length) {
                        setFiles(fileItems.map(item => item.file))
                    } else {
                        setFiles([]);
                    }
                }}
                onremovefile={(fileItems) => {
                    setFiles([]);
                    setImage(null);
                }} 
            />

        </Modal>
    )
}

export default CategoryModal
