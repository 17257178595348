import React,{ useEffect, useState } from 'react'
import { addKeyToData, authorizeCheck, } from '../../utils/Helper'
import { Button, Table, Tag, Input, Space, message, Popconfirm, Modal, Row, Col, Spin, Card } from 'antd'
import { PlusOutlined, SearchOutlined, EditOutlined, DeleteOutlined, EyeTwoTone, CheckCircleOutlined, ThunderboltTwoTone } from '@ant-design/icons'
import orgLogo from '../../assets/img/org.png'
import Highlighter from 'react-highlight-words'
import moment from 'moment'
import apiInstance from '../../api'
import { useDataLayerValue } from '../../DataLayer'
import OrganizationModal from './OrganizationModal'
import AddressModal from './AddressModal'
import axios from 'axios'

function Organization() {

    const [{ permissions }] = useDataLayerValue();

    const [organizations, setOrganizations] = useState([]);
    const [load, setLoad] = useState(false);
    const [postload, setPostLoad] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const [addModalTrigger, setAddModalTrigger] = useState(false);
    const [updateModalTrigger, setUpdateModalTrigger] = useState(false);  
    const [currentOrganization, setCurrentOrganization] = useState(null);
    const [viewOrganizationFlag, setViewOrganizationFlag] = useState(false);
    const [addAddressModal, setAddAddressModal] = useState(false); 
    const [orgAddress, setOrgAddress] = useState([]);
    const [addressLoad, setAddressLoad] = useState(false);
    const [updateAddressModal, setUpdateAddressModal] = useState(false);
    const [currentAddress, setCurrentAddress] = useState(null);
    const [country, setCountry] = useState('');
    const [city, setCity] = useState('');
    
    async function getOrgAddress(id) {
        setAddressLoad(true);
        apiInstance
            .get(`/address/organization/${id}`)
            .then(response => {
                setAddressLoad(false);
                if(response.data.status) {                    
                    setOrgAddress(response.data.data);                    
                } else message.warning(response.data?.data || 'something went wrong');
            }).catch(e => {
                setAddressLoad(false);
                console.log(e);
                if(e.response || e.response?.data) {                                    
                    if(e.response.status === 401) {
                        alert('Your Session is expired!');
                        sessionStorage.clear();                            
                        window.location.replace("/signin");                                     
                    } else {
                        if(typeof e.response?.data?.message === 'string') {
                            message.error(e.response.data.message);                                      
                        } else {
                            message.warn("Something went wrong");
                        }                        
                    }
                } else message.error("Internal Server Error");  
            });
    }

    // function tuneMyCategories(data) {
    //     data.forEach(function(item) {
    //         item.orgtype = categories?.filter(category => category.id === item.orgtype)[0]?.title;
    //     });
    //     return data;  
    // }

    async function getOrganizations() {
        setLoad(true);
        apiInstance.get("/organization/")
        .then(response => {
            if(response.data.status) {
                // response.data.data = tuneMyCategories(response.data.data);
                addKeyToData(response.data.data, "id");
                const newOrganizations = response.data.data.sort((organizationA, organizationB) => {
                    return new Date(organizationB.modified_at) - new Date(organizationA.modified_at);
                });
                setOrganizations(newOrganizations);
                setLoad(false);
            } else message.warning(response.data?.data || 'something went wrong');
        })
        .catch(e => {
            console.log(e);
            if(e.response || e.response?.data) {                                    
                if(e.response.status === 401) {
                    alert('Your Session is expired!');
                    sessionStorage.clear();                            
                    window.location.replace("/signin");                                     
                } else {
                    if(typeof e.response?.data?.message === 'string') {
                        message.error(e.response.data.message);                              
                    } else {
                        message.warn("Something went wrong");
                    }                        
                }
            } else message.error("Internal Server Error");     
        });
    }

    useEffect(() => {
        
        
        getOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[postload]);

    const handleDelete = (id) => {
        apiInstance.delete(`/address/${id}`)
        .then(response => {
            if(response.data.status) {       
                setOrgAddress(orgAddress.filter(oa => oa.id !== id));                                      
                message.success(response.data.data);
            } else message.warning(response.data?.data || 'something went wrong');
        })
        .catch(e => {
            console.log(e);
            if(e.response || e.response?.data) {                
                message.error(e.response.data.message);
                if(e.response.status === 401) {
                    sessionStorage.clear();
                    window.location.replace("/signin");
                }
            } else message.error("Internal Server Error");
        });
    }

    // ANT DESIGN METHODS
    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
            <Input
            ref={node => {
                setSearchInput(node);
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
            <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
            >
                Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
            </Button>
            </Space>
        </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
        record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
        if (visible && searchInput) {
            setTimeout(() => searchInput.select(), 100);
        }
        },
        render: text =>
        searchedColumn === dataIndex ? (
            <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
            />
        ) : (
            text
        ),
    });

    // ANT DESIGN METHODS
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);        
    };

    // ANT DESIGN METHODS
    const handleReset = clearFilters => {
        clearFilters();        
        setSearchText('');
    };

    /**
     * Used to soft delete the organization selected
     * from the table with a confirmation
     * @param {id} id 
     */
    const deleteOrganization = (id) => {
        
        apiInstance.delete(`/organization/${id}`)
        .then(response => {
            if(response.data.status) {                             
                setPostLoad(!postload);
                message.success(response.data.data);
            } else message.warning(response.data?.data || 'something went wrong');
        })
        .catch(e => {
            console.log(e);
            if(e.response || e.response?.data) {                
                message.error(e.response.data.message);
                if(e.response.status === 401) {
                    sessionStorage.clear();
                    window.location.replace("/signin");
                }
            } else message.error("Internal Server Error");
        });

    }

    const openViewModal = (flag, data) => {        
        getOrgAddress(data?.id);
        setCurrentOrganization(data);   
        // call to user ip data
        if (data?.ipOriginatedFrom) {
            getIPData(data?.ipOriginatedFrom);
        }     
        setViewOrganizationFlag(flag);
    }

    const openViewAddressModal = (flag, data) => {
        setCurrentOrganization(data);
        setAddAddressModal(flag);
    }

    const changeApprovalStatus = (status, id) => {
        setLoad(true);
        apiInstance
            .put(`/organization/status/${id}`, status)
            .then(response => {
                setLoad(false);
                if(response.data.status) {                             
                    message.success(response.data.data);
                    // setOrganizations(injectCorrections(organizations, "isApproved", status?1:0, id));
                    getOrganizations();
                } else message.warning(response.data?.data || 'something went wrong');
            })
            .catch(e => {
                setLoad(false);
                console.log(e);
                if(e.response || e.response?.data) {                
                    message.error(e.response.data.message);
                    if(e.response.status === 401) {
                        sessionStorage.clear();
                        window.location.replace("/signin");
                    }
                } else message.error("Internal Server Error");
            });
    }

    const changeVerificationStatus = (status, id) => {
        setLoad(true);
        apiInstance
            .put(`/organization/verification/${id}`, status)
            .then(response => {
                setLoad(false);
                if(response.data.status) {                             
                    message.success(response.data.data);
                    // setOrganizations(injectCorrections(organizations, "isApproved", status?1:0, id));
                    getOrganizations();
                } else message.warning(response.data?.data || 'something went wrong');
            })
            .catch(e => {
                setLoad(false);
                console.log(e);
                if(e.response || e.response?.data) {                
                    message.error(e.response.data.message);
                    if(e.response.status === 401) {
                        sessionStorage.clear();
                        window.location.replace("/signin");
                    }
                } else message.error("Internal Server Error");
            });
    }

    const openAddressInMap = (item) => {
        let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
                        width=0,height=0,left=-1000,top=-1000`;
        let googleMapsUrl = "";
                        if (item?.latitude && item?.longitude) {
                            googleMapsUrl = `https://www.google.com/maps?q=${item.latitude},${item.longitude}`;
                        } else {
                            googleMapsUrl = `https://www.google.com/maps/place/${item?.line1}, ${item?.city} ${item?.states} - ${item?.zip}, ${item?.country}`;
                        }
        
        window.open(googleMapsUrl, "Location Helper", params);
    }

     // gets the user ip information if avaialble to the modal view
     async function getIPData(ipAddress) {
        const ipdata = await axios.get(`https://ipapi.co/${ipAddress}/json/`);
        const ipCity = ipdata.data?.city || '';
        const ipCountry = ipdata.data?.country_name || ipdata.data?.country ||'';
        setCity(ipCity);
        setCountry(ipCountry); 
    }

    // Table Columns Defined
    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id'            
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',            
            ...getColumnSearchProps('name')
        },
        {
            title: 'Email Address',
            dataIndex: 'email',
            key: 'email',
            ...getColumnSearchProps('email')
        },
        {
            title: 'URL',
            dataIndex: 'url',
            key: 'url',
            render: url => {
                return <Tag color="blue"><a href={url} rel="noopener noreferrer" target="_blank">VIEW URL</a></Tag>
            }            
        },
        {
            title: 'Type',
            dataIndex: 'org_type',
            key: 'org_type',
            ...getColumnSearchProps('org_type')
        },     
        {
            title: 'Approval',
            dataIndex: 'isApproved',
            key: 'isApproved',
            render: approval => {
                return <Tag color={approval===1?"green":"orange"}>{approval===1?"Approved":"Pending"}</Tag>
            },
            filters: [
                { text: 'Approved', value: 'Approved' },
                { text: 'Pending', value: 'Pending' } 
            ],
            onFilter: (value, record) => record.isApproved === (value==='Approved' ? 1 : 0)
        },
        {
            title: 'Verification',
            dataIndex: 'isVerified',
            key: 'isVerified',
            render: verification => {
                return <Tag color={verification===1?"blue":null}>{verification===1?"Verified":"UnVerified"}</Tag>
            },
            filters: [
                { text: 'Verified', value: 'Verified' },
                { text: 'UnVerified', value: 'UnVerified' } 
            ],
            onFilter: (value, record) => record.isVerified === (value==='Verified' ? 1 : 0)
        },
        {
            title: 'Last Updated',
            dataIndex: 'modified_at',
            key: 'modified_at',
            render: modified_at => {
                return moment(modified_at).format('lll');
            }
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    {
                                authorizeCheck(permissions, 'organization:update') && (
                                        <Popconfirm
                                        okButtonProps={{
                                            loading: load
                                        }}
                                        onConfirm={() => changeApprovalStatus({status: record.isApproved===1?0:1}, record.id)}
                                        icon={<ThunderboltTwoTone />}
                                        title={record.isApproved===1?'Decline':'Approve'}
                                        okText="Yes"
                                        cancelText="Cancel"
                                        >
                                        {/* <CheckCircleTwoTone 
                                            title="Approve"
                                            twoToneColor={record.isApproved?'#52c41a':'#eb2f96'} 
                                        />                                         */}
                                            <p className="text-blue-400 cursor-pointer">
                                                {record.isApproved===1?'decline':'aprrove'}
                                            </p>
                                        </Popconfirm>
                                )
                    }
                    {
                                authorizeCheck(permissions, 'organization:update') && (
                                        <Popconfirm
                                        okButtonProps={{
                                            loading: load
                                        }}
                                        onConfirm={() => changeVerificationStatus({status: record.isVerified===1?0:1}, record.id)}
                                        icon={<CheckCircleOutlined />}
                                        title={record.isVerified===1?'unverify':'Verify'}
                                        okText="Yes"
                                        cancelText="Cancel"
                                        >
                                            <p className="text-blue-400 cursor-pointer">
                                                {record.isVerified===1?'unverify':'verify'}
                                            </p>
                                        </Popconfirm>
                                )
                    }
                    {
                        authorizeCheck(permissions, 'organization:read') && (
                            <p  
                                title="View"      
                                className="cursor-pointer primary-color"
                                onClick={() => openViewModal(true, record)}                                    
                            ><EyeTwoTone title="View" />
                            </p>
                        )
                    }                                    
                    {
                        authorizeCheck(permissions, 'organization:delete') && (
                            <Popconfirm
                                onConfirm={() => deleteOrganization(record.id)}
                                title="Are you sure?"                                     
                                okText={"Yes"}                                        
                                cancelText={"No"}
                            ><p className="cursor-pointer" title="delete"><DeleteOutlined /></p> 
                            </Popconfirm>                    
                        )
                    }                                    
                </Space>
            )
        }
    ];

    const postCreation = (flag) => {
        setPostLoad(!postload);
        if (flag === 'cancel') {
            setAddModalTrigger(false);
        } else {
            setAddModalTrigger(flag);
        }
        
    }

    const postAddressCreation = (flag) => {
        if (flag === 'cancel') {
            setAddAddressModal(false);     
        } else {
            setAddAddressModal(flag);     
        }
           
    }

    const postUpdation = (flag) => {

        setPostLoad(!postload);
        if (flag === 'cancel') {
            setUpdateModalTrigger(false);
        } else {
            setUpdateModalTrigger(flag);
        }
    }

    const openUpdateModal = (e, data) => {        
        setCurrentOrganization(data); // passing the selected user id
        setUpdateModalTrigger(e); // opening the modal          
    }

    const closeViewModal = () => {        
        setViewOrganizationFlag(false);
        setCurrentOrganization(null);
    }

    const openAddressEditModal = (e, data) => {
        e.preventDefault();
        setCurrentAddress(data);
        setUpdateAddressModal(true);
    }

    const postAddressUpdation = (flag) => {
        if (flag !== 'cancel') {
            getOrgAddress(currentOrganization?.id);
        }
        if (flag === 'cancel') {
            setUpdateAddressModal(false);
        } else {
            setUpdateAddressModal(flag);
        }
        
    }

    const setAddressDefault = (item) => {
        setLoad(true);
        apiInstance
            .put(`/address/organization/default/${item?.id}`, { org_id: currentOrganization?.id, status: true })
            .then(response => {
                setLoad(false);
                if(response.data.status) {                             
                    message.success(response.data.data);
                    getOrgAddress(currentOrganization?.id);
                } else message.warning(response.data?.data || 'something went wrong');
            })
            .catch(e => {
                setLoad(false);
                console.log(e);
                if(e.response || e.response?.data) {                
                    message.error(e.response.data.message);
                    if(e.response.status === 401) {
                        sessionStorage.clear();
                        window.location.replace("/signin");
                    }
                } else message.error("Internal Server Error");
            });
    }

    return (
        <div className="w-full h-full flex flex-col items-center px-4">
            <div className="w-full flex flex-row items-start">
            <h1
                className="text-left text-lg font-semibold mb-4 mx-4"
            >Organizations
            </h1> 
                <Button
                    onClick={() => window.location.replace("/submit-organization")}
                    type="dashed"
                    style={{ marginRight: 13 }}                    
                >Submit an Organization
                </Button> 
            {
                    authorizeCheck(permissions, 'organization:create') && (
                        <Button
                            onClick={() => setAddModalTrigger(true)}
                            type="primary"                    
                        ><PlusOutlined/> Add
                        </Button>                
                    )
            }          
            </div>
            <Table      
                pagination={{
                    pageSize: 6
                }}               
                loading={load}
                className="w-full py-4"
                dataSource={organizations}
                columns={columns}
            />
            {
                addAddressModal &&
                <AddressModal
                    sector={"org"}
                    data={null}            
                    type="create"
                    modalTitle="Add Address"
                    okLabel="Add"
                    trigger={addAddressModal}  
                    callTrigger={postAddressCreation}  
                    id={currentOrganization?.id}                
                />
            }
            {
                updateAddressModal &&
                <AddressModal
                    sector={"org"}
                    data={currentAddress}            
                    type="update"
                    modalTitle="Edit Address"
                    okLabel="Save"
                    trigger={updateAddressModal}  
                    callTrigger={postAddressUpdation}  
                    id={currentOrganization?.id}                
                />
            }
            {   
                addModalTrigger && 
                <OrganizationModal    
                    data={null}            
                    type="create"
                    modalTitle="Create Organization"
                    okLabel="Create"
                    trigger={addModalTrigger}
                    callTrigger={postCreation}
            />
            }
            {
                updateModalTrigger &&
                <OrganizationModal
                type="update"
                data={currentOrganization}
                modalTitle="Update Organization"
                okLabel="Save"
                trigger={updateModalTrigger}
                callTrigger={postUpdation}
            />
            }     
            {
                viewOrganizationFlag  && (
                 
            <Modal  
                style={{ top: 20 }}
                width={700}
                title="Organization"
                visible={viewOrganizationFlag}                
                cancelText={"Close"}
                onCancel={() => closeViewModal()}
                okButtonProps={{ style: { display: 'none' } }}                                    
            >
                { !addressLoad ?
              <div className="w-full flex flex-col justify-center">
                <Row>                    
                    <img
                        alt="org"
                        className="h-20 p-2"
                        src={currentOrganization?.logo || orgLogo}
                    />
                    <div className="w-4/6 flex flex-col items-start mx-4">
                        <div className="w-full flex flex-row items-center">
                            <a  
                                rel="noopener noreferrer"
                                target="_blank"
                                href={currentOrganization?.url}
                                className="font-bold text-lg text-left py-1 flex items-start underline">
                                {currentOrganization?.name||'N/A'} 
                            </a>
                            {
                                authorizeCheck(permissions, 'organization:update') && (
                                    <p onClick={(e) => openUpdateModal(true, currentOrganization)} 
                                        className="ml-4 cursor-pointer"><EditOutlined /></p> 
                                )
                            }  
                        </div>
                        <p 
                            className="text-md text-gray-600"
                            >{currentOrganization?.description}
                        </p>
                    </div>
                    <div className="flex items-start mt-1 mx-4">
                        <Tag 
                            className="mx-2"
                            title="approval"
                            color={currentOrganization?.isVerified===1?"blue":null}
                        >{currentOrganization?.isVerified===1?'Verified':'Unverified'}
                        </Tag>
                        <Tag 
                            className="mx-2"
                            title="approval"
                            color={currentOrganization?.isApproved===1?"green":"orange"}
                        >{currentOrganization?.isApproved===1?'Approved':'Pending'}
                        </Tag>         
                    </div>
                </Row>
                <div className="flex items-start my-4">
                        {
                            currentOrganization?.org_type && (
                            <div>
                            Type:   
                                <Tag 
                                    className="mx-2"
                                    title="org type"
                                    color="blue"
                                >{currentOrganization?.org_type}
                                </Tag>                                                         
                            </div>                     
                            )
                        }   
                        {
                            currentOrganization?.email &&  (
                                <>
                                <p>Email :</p>        
                                <Tag 
                                    className="mx-2"
                                    title="email"
                                    color="blue"
                                >{currentOrganization?.email}
                                </Tag>
                                </>
                            )
                        }
                        {
                            currentOrganization?.phone && (
                                <>
                                <p>Phone:</p>   
                                <Tag 
                                    title="Mobile Number"
                                    color="blue" 
                                    className="mx-2"
                                >{currentOrganization?.phone||'N/A'}
                                </Tag>
                                </>
                            )
                        } 
                        {
                            currentOrganization?.url && (
                                <>
                                <p>URL:</p>   
                                <Tag 
                                    title="url"
                                    color="blue" 
                                    className="mx-2"
                                ><a target="_blank" rel="noopener noreferrer" href={currentOrganization?.url}>Click to Open</a>
                                </Tag>
                                </>
                            )
                        }                        
                </div>                           
                {
                    currentOrganization?.contact_name && (                                                              
                            <p 
                                className="text-md"
                                >Contact Name: <strong>{currentOrganization?.contact_name}</strong>
                            </p>                    
                    )
                }                
                <Row className="py-1" gutter={8}>                    
                    <Col>
                        Created at: <strong>{moment(currentOrganization?.created_at).format('lll')}</strong>
                    </Col>
                    <Col>
                        Last Updated at: <strong>{moment(currentOrganization?.modified_at).format('lll')}</strong>
                    </Col>
                </Row>
                <Row className="py-1" gutter={8}>                    
                    {
                        currentOrganization?.modified_name && (
                            <Col>
                                Last Updated by: <strong>{currentOrganization?.modified_name}</strong>
                            </Col>
                        )
                    }
                    {
                        currentOrganization?.ipOriginatedFrom && (
                            <Col>
                                Requested IP: <strong>{currentOrganization?.ipOriginatedFrom}</strong> {city && `(${city})`} {country && `(${country})`}
                            </Col>
                        )
                    }
                </Row> 
                <hr></hr>
                <Row>
                {
                        authorizeCheck(permissions, 'address:create') && (
                            <Button title="Add Address"      
                                className="cursor-pointer primary-color my-2" 
                                size="small" 
                                type="primary"  
                                onClick={() => openViewAddressModal(true, currentOrganization)}>
                                Add Address
                            </Button>
                        )
                }  
                </Row>
                {
                    orgAddress?.length ? (
                        orgAddress.map((item, idx) => (
                            <Card title={`Address ${idx<10?'0':''}${idx+1} ${item?.default_type===1?"(Default)":""}`} extra={
                                <div className="flex items-center">
                                    {item?.default_type===0?<Button onClick={() => setAddressDefault(item)} className="mr-3" size="small" type="dashed">Set Default</Button>:null}
                                    <Button className="mr-3" size="small" type="dashed" onClick={() => openAddressInMap(item)}>View In Map</Button>
                                    <p onClick={(e) => openAddressEditModal(e, item)} 
                                        className="mr-4 cursor-pointer"><EditOutlined /></p> 
                                    <Popconfirm
                                        title="Are you sure?"
                                        onConfirm={() => handleDelete(item.id)}
                                        okText={"Yes"}                                        
                                        cancelText={"No"}
                                    ><p className="cursor-pointer"><DeleteOutlined /></p>
                                    </Popconfirm>
                                </div>} 
                                bordered={true} size="small" className="mt-2 shadow">
                                {
                                    item?.line_2 === null ? (
                                        <p>{item.line_1}</p>
                                    ) : ( 
                                        <p>{item.line_1+", "+item?.line_2||''}</p>
                                    )
                                }
                                <p>{item.city+", "+item.state+" - "+item.zipcode}</p>
                                <p>{item.country}</p>
                            </Card>
                        ))
                    ) : (<p className="p-2">No Address Available</p>)
                }                     
            </div>
            : <Spin size="small" />}
            </Modal>
                 )
                } 
        </div>
    )
}

export default Organization
