import React, { useState, useEffect } from 'react'
import { Modal, Input, message, Form, Select, Button } from 'antd'
import apiInstance from '../../api'
import { useDataLayerValue } from '../../DataLayer'
import { injectToSitemap, validateEmail } from '../../utils/Helper'

const { Option } = Select;

function OrganizationModal({ modalTitle, okLabel, trigger, callTrigger = ct => ct, data=null, type }) {

    const [{ user, categories, allAddressTypes }] = useDataLayerValue();
    
    // general information
    const [id, setId] = useState(null);
    const [urlKey, setUrlKey] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');    
    const [description, setDescription] = useState('');
    const [url, setUrl] = useState('');
    const [phone, setPhone] = useState('');
    const [contactName, setContactName] = useState('');
    const [logo, setLogo] = useState('');
    const [orgType, setOrgType] = useState('type');
    const [userIp, setUserIp] = useState('');
    const [load, setLoad] = useState(false);

    // address Information
    const [line1, setLine1] = useState('');
    const [line2, setLine2] = useState('');
    const [city, setCity] = useState('');
    const [states, setStates] = useState('');
    const [country, setCountry] = useState('');
    const [zip, setZip] = useState('');
    const [location, setLocation] = useState('');
    
    const [errorText, setErrorText] = useState(null);

    const [oldName, setOldName] = useState('');
    const [oldUrlKey, setOldUrlKey] = useState('');
    const [oldEmail, setOldEmail] = useState('');    
    const [oldDescription, setOldDescription] = useState('');
    const [oldUrl, setOldUrl] = useState('');
    const [oldPhone, setOldPhone] = useState('');
    const [oldContactName, setOldContactName] = useState('');
    const [oldLogo, setOldLogo] = useState('');
    const [oldOrgType, setOldOrgType] = useState('');    

    const [emailErrorText, setEmailErrorText] = useState(null);
    const [addressType, setAddressType] = useState(null);

    useEffect(() => {
        let addressTypeId = allAddressTypes.filter(a => a.name === 'Organization')[0];
        setAddressType(addressTypeId?.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const handleCancel = () => {
        setName('');
        setDescription('');
        setUrl('');
        setEmail('');
        setPhone('');
        setContactName('');
        callTrigger('cancel');
    }

    useEffect(() => {
        if (email) {
            if (!validateEmail(email)) {
                setEmailErrorText("Invalid Email Address");
            } else setEmailErrorText(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[email]);

    const handleOk = () => {
        let payload = {
            name,
            description,
            url,
            email,
            phone,
            contactName,
            orgType,
            logo,
            url_key: urlKey,
            line1,
            line2,
            city,
            states,
            country,
            zip,
            location,
            addressType
        };
       
        if (email && validateEmail(email))  {  
            if (type === 'create') {
                setLoad(true);
                apiInstance.post("/organization/", payload)
                        .then(response => {
                            if(response.data.data) {
                                setLoad(false);
                                callTrigger(false);
                                message.success('Organization Created');
                                injectToSitemap(name, `https://assistance.org/organization/${urlKey}`,'organizations');
                            } else message.warning(response.data?.data || 'something went wrong');
                        }).catch(e => {
                            setLoad(false);
                            console.log(e);
                            if(e.response || e.response?.data) {                                    
                                if(e.response.status === 401) {
                                    alert('Your Session is expired!');
                                    sessionStorage.clear();                            
                                    window.location.replace("/signin");                                     
                                } else {
                                    if(typeof e.response?.data?.message === 'string') {
                                        message.error(e.response.data.message);                                      
                                    } else {
                                        message.warn("Something went wrong");
                                    }                        
                                }
                            } else message.error("Internal Server Error");  
                        });
            } else if (type === 'update') {
                setLoad(true);
                apiInstance.put(`/organization/${data?.id}`, payload)
                        .then(response => {
                            if(response.data.data) {
                                setLoad(false);
                                callTrigger(false);
                                message.success('Organization Updated');
                            } else message.warning(response.data?.data || 'something went wrong');
                        }).catch(e => {
                            setLoad(false);
                            console.log(e);
                            if(e.response || e.response?.data) {                                    
                                if(e.response.status === 401) {
                                    alert('Your Session is expired!');
                                    sessionStorage.clear();                            
                                    window.location.replace("/signin");                                     
                                } else {
                                    if(typeof e.response?.data?.message === 'string') {
                                        message.error(e.response.data.message);                                      
                                    } else {
                                        message.warn("Something went wrong");
                                    }                        
                                }
                            } else message.error("Internal Server Error");  
                        });
            }
        } else {
            message.warn("Invalid Email Address");
        }        
    }

    useEffect(() => {
        if(type === 'update') {            
            if(data) {
                setName(data?.name || '');
                setEmail(data?.email || '');
                setDescription(data?.description || '');
                setUrl(data?.url || '');
                setId(data?.id || null);
                setPhone(data?.phone || '');
                setContactName(data?.contact_name || '');
                setLogo(data?.logo || '');
                setOrgType(data?.orgtype || 'type');
                setUrlKey(data?.url_key||'');
                setUserIp(data?.ipOriginatedFrom || '');
                
                setOldName(data?.name || '');
                setOldEmail(data?.email || '');
                setOldUrlKey(data?.url_key||'');
                setOldDescription(data?.description || '');
                setOldUrl(data?.url || '');
                setOldPhone(data?.phone || '');
                setOldContactName(data?.contact_name || '');
                setOldLogo(data?.logo || '')
                setOldOrgType(data?.orgtype || 'type')
            }
        }             
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    },[data]);

    async function checkUrlKey() {
        apiInstance.get(`/organization/urlkey/check?key=${urlKey}&id=${id}`)
        .then(response => {
          if(response.data.status) {                                          
            if(!response.data.data) {
                setErrorText('url key not available');
            } else {
                setErrorText('');
            }
          } else {            
            // window.location.replace('/fallback');
            console.log(response.data?.data||response?.status);            
          }
        })
        .catch(e => {        
          console.log(e);
          if(e.response || e.response?.data) {                          
              // message.error(e.response.data.message);
              if(e.response.status === 401) {
                  sessionStorage.clear();
                  window.location.replace("/admin");
              }
          } else {
            // history.push('/');
          }
        });  
    }

    const handleTitleChange = (e) => { 
        let title = e.target.value;
        setName(title);
        let urlKeyGenerated = title.toLowerCase().replace(/ /g, '-');
        setUrlKey(urlKeyGenerated);
    }

    useEffect(() => {
        if (urlKey) {

            // debounce fx
            const timer = setTimeout(() =>{
                checkUrlKey();
            }, 2000);  
            return () => clearTimeout(timer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[urlKey]);
    
    return (
        <Modal      
            width={900}  
            style={{ top: 15 }}
            maskClosable={false}                     
            destroyOnClose={true}
            title={modalTitle}
            visible={trigger}
            onOk={handleOk} 
            onCancel={handleCancel}           
            okText={okLabel}
            okButtonProps={{
                loading: load,
                disabled: (
                    type==='update'?
                            ((oldName === name) && (oldEmail === email) && (oldDescription === description) && (oldUrl === url) && (oldPhone === phone) && (oldContactName === contactName) && (oldOrgType === orgType) && (oldLogo === logo) && (oldUrlKey === urlKey)):
                            type === 'create' ?(!name || !email || !url || !orgType || !urlKey || !line1 || !city || !states || !country || !zip || !addressType):false)                            
            }}
        >
            <div className="w-full flex flex-col items-start">
                <p className="w-full text-left mb-1 text-md text-gray-600">
                    General Information
                </p>
                <div className="w-full flex flex flex-row items-center justify-between">
                    <Form.Item    
                    required        
                    className="flex flex-col items-start"
                    label="Name">
                        <Input                           
                            style={{ width: 410 }}                                                        
                            onChange={e => handleTitleChange(e)}
                            value={name}                
                            placeholder="Name"
                            type="text"                        
                        />
                    </Form.Item>  
                <Form.Item
                    required
                    className="flex flex-col items-start"
                    label="Url Key"
                >
                        <Input
                        style={{ width: 410 }}                 
                        onChange={e => setUrlKey(e.target.value)}
                        value={urlKey}                
                        type="text"
                        placeholder="URL Key Here (Required)"
                        className={`${errorText ? 'mb-3' : ''}`}
                    />
                    {errorText && (<p className="my-2 text-xs text-red-500 text-center">{errorText}</p>)}
                    </Form.Item> 
                </div>
                <div className="w-full flex flex flex-row items-center justify-between">
                    <Form.Item
                    className="flex flex-col items-start mb-2"
                    label="Description">
                        <Input  
                            style={{ width: 410 }}                                                           
                            onChange={e => setDescription(e.target.value)}
                            value={description}                
                            placeholder="Description"
                            type="text"                        
                        />
                    </Form.Item> 
                {
                    user?.role === "Super User" && (
                        <Form.Item
                        className="flex flex-col items-start mb-2"
                        label="Logo">
                        <Input             
                            style={{ width: 410 }}                                                    
                            onChange={e => setLogo(e.target.value)}
                            value={logo}                
                            placeholder="Company Logo URL"
                            type="url"                    
                        /></Form.Item> 
                    )
                }   
                </div>
                <div className="w-full flex flex flex-row items-center justify-between">
                    <Form.Item 
                    required
                    validateStatus={emailErrorText?"error":"success"}
                    help={emailErrorText}
                    className="flex flex-col items-start mb-2"
                    label="Email">
                    <Input                 
                        style={{ width: 410 }}                        
                        onChange={e => setEmail(e.target.value)}
                        value={email}                
                        placeholder="Email Address"
                        type="email"                    
                    /></Form.Item>
                    <Form.Item 
                    className="flex flex-col items-start mb-2"
                    label="Url">
                    <Input             
                        style={{ width: 410 }}                            
                        onChange={e => setUrl(e.target.value)}
                        value={url}                
                        placeholder="Company URL"
                        type="url"                    
                    /></Form.Item>
                </div>
                <div className="w-full flex flex flex-row items-center justify-between">
                    <Form.Item
                    className="flex flex-col items-start mb-2"
                    label="Phone">
                    <Input           
                        style={{ width: 410 }}                                                  
                        onChange={e => setPhone(e.target.value)}
                        value={phone}                
                        placeholder="Phone"
                        type="number"                    
                    /></Form.Item>
                    <Form.Item
                    required
                    className="flex flex-col items-start mb-2"
                    label="Org Type">
                    <Select                    
                        value={orgType}
                        style={{ width: 410 }}
                        onChange={e => setOrgType(e)}
                    >
                        <Option value="type" disabled="true">Select Type</Option>
                        {
                            categories?.map((category, idx) => (
                                <Option key={idx} value={category?.id}>{category?.title}</Option>
                            ))
                        }
                    </Select>               
                    </Form.Item>
                </div>
                <div className="w-full flex flex flex-row items-center justify-between border-b pb-2">
                    <Form.Item 
                    className="flex flex-col items-start mb-2"
                    label="Contact">
                    <Input       
                        style={{ width: 410 }}                                                      
                        onChange={e => setContactName(e.target.value)}
                        value={contactName}                
                        placeholder="Contact Name"
                        type="text"
                        className="mb-3"
                    />          
                    </Form.Item> 
                    {userIp && (<p>User IP: {userIp}</p>)}
                </div>
                {
                    <div className={`w-full ${type==='create'?'flex':'hidden'} flex-col`}>
                <p className="w-full text-left my-1 text-md text-gray-600">
                    Address Information <span className="text-sm text-gray-600 font-italic px-2">(More address could be added after the creation)</span>
                </p>
                <div className="w-full flex flex flex-row items-center justify-between">
                    <Form.Item    
                        required        
                        className="flex flex-col items-start"
                        label="Line 1">
                            <Input                           
                            style={{ width: 410 }}                                                        
                            onChange={e => setLine1(e.target.value)}
                            value={line1}                
                            placeholder="Address Line 1"
                            type="text"                        
                        />
                    </Form.Item> 
                    <Form.Item            
                        className="flex flex-col items-start"
                        label="Line 2">
                            <Input                           
                            style={{ width: 410 }}                                                        
                            onChange={e => setLine2(e.target.value)}
                            value={line2}                
                            placeholder="Address Line 2"
                            type="text"                        
                        />
                    </Form.Item> 
                </div>
                <div className="w-full flex flex flex-row items-center justify-between">
                    <Form.Item   
                        required         
                        className="flex flex-col items-start"
                        label="City">
                            <Input                           
                            style={{ width: 410 }}                                                        
                            onChange={e => setCity(e.target.value)}
                            value={city}                
                            placeholder="City"
                            type="text"                        
                        />
                    </Form.Item> 
                    <Form.Item    
                        required        
                        className="flex flex-col items-start"
                        label="State">
                            <Input                           
                            style={{ width: 410 }}                                                        
                            onChange={e => setStates(e.target.value)}
                            value={states}                
                            placeholder="State"
                            type="text"                        
                        />
                    </Form.Item> 
                </div>
                <div className="w-full flex flex flex-row items-center justify-between">
                    <Form.Item  
                        required          
                        className="flex flex-col items-start"
                        label="Country">
                            <Input                           
                            style={{ width: 410 }}                                                        
                            onChange={e => setCountry(e.target.value)}
                            value={country}                
                            placeholder="Country"
                            type="text"                        
                        />
                    </Form.Item> 
                    <Form.Item  
                        required          
                        className="flex flex-col items-start"
                        label="Zip Code">
                            <Input                           
                            style={{ width: 410 }}                                                        
                            onChange={e => setZip(e.target.value)}
                            value={zip}                
                            placeholder="Zip Code"
                            type="text"                        
                        />
                    </Form.Item> 
                </div>
                <div className="w-full flex flex flex-row items-center justify-between">
                    <Button
                        disabled={!line1 || !city || !states || !country || !zip}
                        // onClick={() => openLocationHelper()}
                        type="primary"
                        size="small"
                    >Get Coordinates                    
                    </Button>
                    <Form.Item            
                        className="flex flex-col items-start"
                        label="Coordinates">
                            <Input                           
                            style={{ width: 410 }}                                                        
                            onChange={e => setLocation(e.target.value)}
                            value={location}                
                            placeholder="Latitude, Longitude"
                            type="text"                        
                        />
                    </Form.Item> 
                </div>
                </div>
                }
            </div>        
        </Modal>         
    )
}

export default OrganizationModal
