import React, { useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route  
} from "react-router-dom"
import Index from './pages/Index'
import Dashboard from './pages/Dashboard'
import Login from './pages/Login'
import Contact from './pages/Contact'
import About from './pages/About'
import Category from './pages/Category'
import Search from './pages/Search'
import NotFound from './pages/NotFound'
import Feed from './pages/Feed'
import apiInstance from "./api"
import { useDataLayerValue } from './DataLayer'
import DevBanner from './components/DevBanner'        
import FallBack from './pages/FallBack'
import OurMission from './pages/OurMission'
import Terms from './pages/Terms'
import Register from './pages/Register'
import Home from './pages/Home'
import ForgetPassword from './pages/ForgetPassword'
import GuestRoute from './pages/GuestRoute'
import Profile from './pages/Profile'
import GuestOrg from './pages/GuestOrg'
import FeedbackFloater from './pages/FeedbackFloater'
import OrganizationView from './pages/OrganizationView'
import SearchOrganization from './pages/SearchOrganization'
import SearchBlogs from './pages/SearchBlogs'
import RegisterFallback from './pages/RegisterFallback'
import EmailActivation from './pages/EmailActivation'
import CookiePolicy from './pages/CookiePolicy'
import CookieBanner from './components/User/CookieBanner'

function App() {
  
  const [{ categoriesStatus }, dispatch] = useDataLayerValue();  
    
  useEffect(() => {    
    async function getAllCategories() {
        apiInstance.get("/category/secure")
        .then(response => {
          if(response.data.status) {
            // SHOOT DATA TO THE DATA LAYER         
            dispatch({
              type: 'SET_CATEGORIES',
              categories: response.data.data
            });
          } else {
            // window.location.replace('/fallback');
            console.log(response.data?.data||response?.status);            
          }
        })
        .catch(e => {
          console.log(e);          
        });
    }
    getAllCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[categoriesStatus]);

  return (
    <div>
        {
          process.env.REACT_APP_ENV === 'staging' ?
          <DevBanner title={"DEVELOPMENT MODE"} /> : null
        }
        <FeedbackFloater />
        <CookieBanner />
        <Router>                   
          <Switch>

            <Route path="/dashboard"> <Dashboard /> </Route>
            <Route path="/get-started"> <Register /> </Route>
            <Route path="/signin"> <Login /> </Route>
            <Route path="/admin"> <Login /> </Route>
            <Route path="/contact"> <Contact /> </Route>
            <Route path="/reset-password"> <ForgetPassword /> </Route> 
            <Route path="/about"> <About /> </Route>
            <Route path="/mission"> <OurMission /> </Route>
            <Route path="/home"> <Home /> </Route>
            <Route path="/terms"> <Terms /> </Route>   
            <Route path="/cookie-policy"> <CookiePolicy /> </Route>            
            <Route path="/article/:id"> <Category /> </Route> 
            <Route path="/organization/:id"> <OrganizationView /> </Route>           
            <Route path="/password/reset/:userId/:token"> <GuestRoute /> </Route> 
            <Route path="/emailaddress/verification/:userId/:token"> <EmailActivation /> </Route>                
            <Route path="/search"> <Search />  </Route>
            <Route path="/fallback" exact> <FallBack /> </Route>
            <Route path="/oops"> <NotFound /> </Route>
            <Route path="/registration-complete"> <RegisterFallback /> </Route>
            <Route path="/search-organizations" exact> <SearchOrganization /> </Route>
            <Route path="/search-blogs" exact> <SearchBlogs /> </Route>
            <Route path="/submit-organization"> <GuestOrg/> </Route>
            <Route path="/@:username" exact> <Profile /> </Route>
            <Route path="/:feedkey" exact> <Feed /> </Route>
            <Route path="/" exact> <Index /> </Route>
            <Route path="*"> <NotFound />  </Route>            

          </Switch>                          
        </Router>
    </div>  
  )

}

export default App;

