import React, { useEffect, useState } from 'react';
import { CheckCircleTwoTone, SafetyCertificateTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import Header from '../components/User/Header';
import { Result, message, Spin } from 'antd';
import { Link, useParams } from 'react-router-dom';
import apiInstance from '../api'

function EmailActivation() {

    let { userId, token } = useParams();

        useEffect(() => {
            if (!userId && !token) window.location.replace("/");
            document.title = "Account Verification - Assistance.org";
            if (userId) getModifiedByForUserFromSpecialLine();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [userId]);

    const [label, setLabel] = useState('Activate Account');
    const [requestLabel, setRequestLabel] = useState('Request Activation Link');
    const [activate, setActivate] = useState(false);
    const [wrong, setWrong] = useState(false);
    const [title, setTitle] = useState('Activate your account here');
    const [rTitle, setRTitle] = useState('Account Verification Failed');
    const [load, setLoad] = useState(false);
    const [showRequest, setShowRequest] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [subTitle, setSubTitle] = useState('Hello, Click the button provided in order to activate your account now. For more information email us at contact@assistance.org');

    const handleActivation = () => {
        setWrong(false);
        setLabel('Please Wait...');
        let payload = {
            userId,
            token
        };
        apiInstance
            .post('/auth/emailaddress/verification', payload)
            .then(response => {
                if (response.data.status) {
                    setActivate(true);
                    setTitle("Account successfully verified");
                    setSubTitle("Sign in to access your account, For more information email us at contact@assistance.org");
                    sessionStorage.setItem('assistance_current_route_helper','/');
                                 
                } else {
                    setWrong(true);
                    message.error(response.data?.message || 'Something went wrong!');
                    if (typeof response.data?.message === 'string') {
                        setRTitle("Account Verification Failed")
                    }
                }
                setLabel('Activate Account');
            })
            .catch(e => {
                setWrong(true);
                message.error(e.response.data?.message || 'Something went wrong!');
                if (e.response.data?.message || e.response?.status === 400) {
                    setRTitle(e.response.data.message)
                }
                setLabel('Activate Account');
                console.log(e);
            });
    }

    async function requestEmailVerificationLink(payload) {
        setRequestLabel('Please Wait...');
        apiInstance
            .post("/auth/request/url/emailactivation", payload)
            .then(response => {
                setRequestLabel('Request Activation Link');
                setRTitle(response.data?.data || "Activation link is sent to your email address");
                setSubTitle("For more information email us at contact@assistance.org");
                sessionStorage.setItem('assistance_current_route_helper','/');
            }).catch(e => {
                setRTitle("Activation link requested failed");
                setRequestLabel('Request Activation Link');
                message.error(e.response.data?.message || 'Something went wrong!');
                console.log(e);
            });
    }

    const handleEmailVerificationRequest = () => {
        let payload = {
            userId 
        };
        requestEmailVerificationLink(payload);
    }

    async function getModifiedByForUserFromSpecialLine() {
        setLoad(true);
        apiInstance
            .get(`/person/activationcheck?id=${userId}`)
            .then(response => {
                setLoad(false);
                if (parseInt(response.data.data?.modified_by) === 1 && parseInt(response.data.data?.is_active) === 0) {
                    setWrong(true);
                    setRTitle("Your account has been deactivated by admin");
                    setShowRequest(false);
                } else {
                    if (parseInt(response.data.data?.is_active) === 1) {
                        setIsActive(false);
                        setTitle("Your account has been already activated")
                        setWrong(false);
                        setShowRequest(true);
                    } else {
                        setIsActive(true);
                        setWrong(false);
                        setShowRequest(true);
                    }
                }
            })
            .catch(e => {
                setLoad(false);
                setRTitle("Activation link requested failed");
                message.error(e.response.data?.message || 'Something went wrong!');
                console.log(e);
            });
    }

    return (
        <>
            <Header />
            {
                !load ? (
                userId && token && !load && (
                    !wrong ? (
                        <Result 
                            className="w-1/2 mx-auto"
                            icon={activate ? <CheckCircleTwoTone twoToneColor="#52c41a" /> :<SafetyCertificateTwoTone />}
                            title={title}
                            subTitle={<p className="text-md text-black text-center">{subTitle}</p>}
                            extra={
                                activate ? (
                                    [
                                            <Link
                                            className="primary-bg rounded-md text-white px-8 mx-2 py-2 hover:text-gray-400 my-2"
                                            to="/signin"
                                        >Sign In</Link>,
                                        <Link
                                            className="primary-bg rounded-md text-white px-8 mx-2 py-2 hover:text-gray-400 my-2"
                                            to="/"
                                        >Go to Home</Link>
                                    ]
                                ) : (
                                    [
                                        isActive && (
                                            <button
                                        onClick={() => handleActivation()}
                                        className="primary-bg rounded-md text-white px-8 mx-2 py-2 hover:text-gray-400 my-2"
                                        >{label}
                                        </button>
                                    )
                                    ]
                                )
                            }
                        />
                    ) : (
                        <Result 
                            className="w-1/2 mx-auto"
                            icon={<CloseCircleTwoTone twoToneColor="#eb2f96" />}
                            title={rTitle}
                            subTitle={<p className="text-md text-black text-center">{"For more information email us at contact@assistance.org"}</p>}
                            extra={[
                                <Link
                                    className="border border-blue-600 rounded-md text-blue-600 px-8 mx-2 py-2 hover:text-blue-500 my-2"
                                    to="/"
                                >Go to Home</Link>,
                                <button
                                    onClick={() => handleActivation()}
                                    className="primary-bg rounded-md text-white px-8 mx-2 py-2 hover:text-gray-400 my-2"
                                >Try Again
                                </button>,
                                showRequest && (
                                    <button
                                        onClick={() => handleEmailVerificationRequest()}
                                        className="primary-bg rounded-md text-white px-8 mx-2 py-2 hover:text-gray-400 my-2"
                                    >{requestLabel}
                                    </button>
                               )
                            ]}
                        />
                    )
                )
                ): (
                    <div className="w-full p-20 flex flex-col items-center justify-center">
                        <Spin
                            size="large"
                        />
                    </div>
                )
            }
        </>
    )
}

export default EmailActivation
